export default {
  form: {
    name: {
      label: 'Restaurant name',
      placeholder: 'Enter restaurant name',
    },
    google_place: {
      label: 'Selected google place: {{name}}',
    },
    sync_lieferando_brand: {
      label: 'Fetch brand details (logo, primary color) from Lieferando',
    },
    import_menu: {
      label: 'Import menu',
    },
    lieferando_slug: {
      label: 'Lieferando slug',
      placeholder: 'Last part of restaurant URL in Lieferando',
    },
  },
  buttons: {
    save: 'Save',
  },
}