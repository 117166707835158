import ApiResourceManager from '../ApiResourceManager';

export default class StatsManager extends ApiResourceManager {
  getStats = (params) => this.request({
    method: 'GET',
    url: '/stats',
    params,
  });

  getCompanyStats = (params) => this.request({
    method: 'GET',
    url: '/company-stats',
    params,
  });

  getLoyaltyStats = () => this.request({
    method: 'GET',
    url: '/loyalty-app-stats',
  });
}
