export default {
  labels: {
    google_place: 'Google-Platz',
    menu_import: 'Menü-Import',
  },
  buttons: {
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
  },
  delete_confirmation: 'Bist du sicher?',
};
