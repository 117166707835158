export default {
  title: 'Neue Franchise',
  tabs: {
    general: 'Allgemein',
    branding: 'Branding',
    scripts: 'Tracking & Skripte',
  },
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Franchise Name',
    },
    subdomain: {
      label: 'Subdomain',
      placeholder: 'Franchise subdomain',
    },
    lieferando_slug: {
      label: 'Lieferando slug',
      placeholder: 'Letzter Teil der Restaurant-URL in Lieferando',
    },
    primary_color: {
      label: 'Primäre Farbe',
    },
    logo: {
      label: 'Logo',
    },
    image: {
      label: 'Bild',
    },
    companies: {
      label: 'Restaurants',
    },
    noscripts: {
      description: 'Code sofort nach dem Öffnen des Bodys einfügen',
    },
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
    sync_from_lieferando: 'Sync von Lieferando',
  },
  toasts: {
    changes_saved_successfully: 'Die Änderungen wurden erfolgreich gespeichert!Die Änderungen wurden erfolgreich gespeichert!',
    failed_to_update_company_slug: 'Slug konnte nicht aktualisiert werden für {{name}}',
    failed_to_sync_lieferando: 'Details von Lieferando konnten nicht synchronisiert werden',
    successfully_synced_lieferando: 'Daten erfolgreich von Lieferando synchronisiert',
  },
};
