import React, { useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import CIcon from '@coreui/icons-react';
import collect from 'collect.js';

export default function GooglePlacesSearch({
  value,
  placeholder,
  options,
  onChange,
  onPlaceSelected,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Form:GooglePlacesSearch' });

  const [types, setTypes] = useState(options?.types ?? []);

  const resetPlaceTypes = () => setTypes([]);

  return (
    <Flex vertical gap="4px">
      <Flex className="items-center w-full px-2 py-1 border rounded-md">
        <CIcon
          name="cil-bank"
          className="tw-mr-small tw-my-auto"
        />
        <AutoComplete
          apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={onPlaceSelected}
          onChange={onChange}
          value={value}
          placeholder={placeholder ?? t('placeholder')}
          className="w-full overflow-ellipsis border-none"
          options={{
            ...(options ?? {}),
            types,
          }}
          key={collect(types).implode('-')}
        />
      </Flex>
      <span className="tw-pt-tiny">
        {t('labels.cant_find_place')}
        {' '}
        <span onClick={resetPlaceTypes} className="tw-text-brand-primary hover:tw-underline tw-cursor-pointer">{t('labels.reset_categories')}</span>
      </span>
    </Flex>
  );
}
