import * as React from 'react';

export default function CaretDownIcon({
  width, height, color, className,
}) {
  return (
    <svg
      className={className}
      width={width || '21'}
      height={height || '12'}
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.60391 0C1.74956 0 1.28865 1.00212 1.84465 1.65079L9.95614 11.1142C10.3552 11.5798 11.0756 11.5798 11.4747 11.1142L19.5862 1.65079C20.1422 1.00212 19.6812 0 18.8269 0L2.60391 0Z"
        fill={color || '#EB5757'}
      />
    </svg>
  );
}
