export default {
  title: 'Kaufe 1, Erhalte 1 Gratis',
  form: {
    active: {
      label: 'Ist aktiv',
    },
    start_date: {
      label: 'Startdatum',
      placeholder: 'Startdatum',
    },
    end_date: {
      label: 'Enddatum',
      placeholder: 'Enddatum',
    },
    weekly_spending_limit: {
      label: 'Wöchentliches Ausgabenlimit',
      placeholder: 'Wöchentliches Ausgabenlimit',
    },
    products: {
      label: 'Produkte (bis zu 10 Artikel)',
      placeholder: 'Produkte (bis zu 10 Artikel)',
    },
  },
  buttons: {
    save: 'Speichern',
  },
};
