import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  name: null,
  business_profile: {},
  franchise: {},
  merchant: {},
};

export default createSlice({
  name: 'currentCompany',
  initialState: {
    company: initialState,
  },
  reducers: {
    set: (state, action) => {
      if (!action.payload) {
        state.company = initialState;

        return;
      }

      state.company = action.payload;
    },
    update: (state, action) => {
      state.company = {
        ...state.company,
        ...action.payload,
      };
    },
    reset: (state) => {
      state.company = initialState;
    },
    setBusinessProfile: (state, action) => {
      state.company.business_profile = action.payload;
    },
    setFranchise: (state, action) => {
      state.company.franchise = action.payload;
    },
    updateMerchant: (state, action) => {
      state.company.merchant = {
        ...state.company.merchant,
        ...action.payload,
      };
    },
  },
});
