export default {
  form: {
    document_type: {
      label: 'Proof of business document',
      placeholder: 'Select document type',
      options: {
        vat_registration: 'VAT Registration',
        trade_registration: 'Trade registration',
        trade_license: 'Trade license',
        commercial_register_extract: 'Commercial register extract',
        financial_statements: 'Financial statements',
        other: 'Other',
      },
    },
    front: {
      label: 'Document front',
    },
    back: {
      label: 'Document back (optional for some documents)',
    },
  },
  buttons: {
    select_file: 'Select file',
    next: 'Next',
    back: 'Back',
  },
};
