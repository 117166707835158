import { useState } from 'react';
import { collect } from 'collect.js';

export default function useForm(initial) {
  const [data, setData] = useState(initial);

  const update = (field, value) => {
    if (typeof field === 'object') {
      return setData((current) => ({
        ...current,
        ...field,
      }));
    }

    const parts = field.split('.');
    const key = parts[0];

    if (parts.length === 1) {
      return setData((current) => ({
        ...current,
        [key]: value,
      }));
    }

    return setData((current) => ({
      ...current,
      [key]: {
        ...current[key],
        [parts[1]]: value,
      },
    }));
  };

  const reset = () => setData({ ...initial });

  return {
    data,
    setData,
    update,
    reset,
  };
}
