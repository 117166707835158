import { STRIPE_VERIFICATION_STATUS } from '../../../../../services/exports/Constants';

export default {
  title: 'Überblick über die Live-Schaltung',
  form: {
    name: {
      placeholder: 'Suche Restaurant...',
    },
  },
  filters: {
    live_from: {
      options: {
        all: 'Alle',
        live_only: 'Nur live',
        pending_only: 'Nur anhängig',
      },
    },
    setup_package_fulfilled: {
      options: {
        all: 'Alle',
        sent: 'Gesendet',
        not_sent: 'Nicht gesendet',
      },
    },
    flyers_ordered: {
      options: {
        all: 'Alle',
        ordered: 'Bestellt',
        not_ordered: 'Nicht bestellt',
      },
    },
  },
  fields: {
    name: 'Name',
    onboarding_status: 'Onboarding-Status',
    live_from: 'Live seit',
    setup_package_fulfilled_at: 'Setup-Paket',
    flyers_delivered_at: 'Lieferstatus der Flugblätter',
    has_storefront: 'Storefront',
  },
  labels: {
    stripe_status: 'Stripe status: {{status}}',
    subscription_status: 'Abonnement status: {{status}}',
    contract_status: 'Vertrag status: {{status}}',
    flyer_order_status: 'Flyer bestellen status: {{status}}',
    flyer_order_last_updated: 'Zuletzt aktualisiert: {{date}}',
    subscribed: 'Abonniert',
    not_subscribed: 'Nicht abonniert',
    signed: 'Unterzeichnet am {{date}}',
    not_signed: 'Nicht unterzeichnet',
    no_flyer_order: 'Nicht bestellt',
    domain_required: 'Domain muss gekauft werden',
    note: 'Hinweis',
  },
  stripe_statuses: {
    [STRIPE_VERIFICATION_STATUS.REJECTED]: 'Abgelehnt',
    [STRIPE_VERIFICATION_STATUS.APPROVED]: 'Geprüft',
    [STRIPE_VERIFICATION_STATUS.PENDING]: 'Anhängig',
    [null]: 'Keine',
  },
  buttons: {
    mark_as_granted: 'Als bewilligt markieren',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
