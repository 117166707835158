import { ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  empty_list: 'Noch keine Artikel. Füge ein neuen Artikel hinzu.',
  variants: 'Varianten',
  fields: {
    number: '#',
    image: 'Bild',
    name: 'Name',
    price: 'Preis',
    prices: 'Preise',
    free_eligible: 'Gratis einlösbar',
    promotable: 'Promotable',
    description: 'Beschreibung',
    tags: 'Tags',
  },
  labels: {
    visible: 'Sichtbar im Menü',
    hidden: 'Alle versteckt',
  },
  order_method_prices: {
    base: 'Basis',
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ORDER_METHODS.room_service]: 'Tisch/Zimmer-service',
  },
  buttons: {
    delete_products_one: 'Produkt löschen',
    delete_products_other: 'Produkte löschen',
    add_product: '+ Artikel hinzufügen',
    show_products: 'Kategorie aufzeigen',
    hide_products: 'Kategorie schließen',
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    duplicate: 'Duplizieren',
    delete: 'Löschen',
    delete_all: 'Alle Löschen',
  },
  modals: {
    delete_variants: {
      title: 'Sind Sie sicher?',
      description: 'Die folgenden Varianten werden gelöscht: {{items}}',
    },
  },
  toasts: {
    failed_to_duplicate_category: 'Kategorie konnte nicht dupliziert werden',
    category_duplicated_successfully: 'Kategorie erfolgreich dupliziert',
    failed_to_duplicate_product: 'Artikel konnte nicht dupliziert werden',
    product_duplicated_successfully: 'Artikel erfolgreich dupliziert',
    failed_to_delete_variant: 'Variante konnte nicht gelöscht werden',
    variant_got_deleted: 'Variante wurde gelöscht',
    something_went_wrong: 'Etwas ist schief gelaufen',
  },
};
