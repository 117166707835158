export default {
  title: 'Google business',
  sections: {
    google_business_info: 'Google Unternehmensinformationen',
    google_reviews: 'Google-Bewertungen',
    settings: 'Einstellungen',
  },
  form: {
    reviews_autopilot_enabled: {
      label: 'KI-Antworten automatisch aktivieren',
    },
    reviews_autopilot_daily_limit: {
      label: 'Tägliches Limit für KI-Antworten (min. 1, max. 15)',
    },
    reviews_autopilot_delay_hours: {
      label: 'Anzahl der Stunden, die nach der Bewertung vergehen sollen',
    },
    unanswered_checkbox: {
      show_all_reviews: 'Alle Bewertungen anzeigen',
      show_only_unanswered: 'Nur unbeantwortete anzeigen',
    },
    rating_filter: {
      label: 'Bewertungsfilter',
      stars_one: '{{count}} Stern',
      stars_other: '{{count}} Sterne',
    },
    sorting: {
      newest_to_oldest: 'Nach neuestem zu ältestem sortieren',
      oldest_to_newest: 'Nach ältestem zu neuestem sortieren',
      rating_highest_to_lowest: 'Nach höchster zu niedrigster Bewertung sortieren',
      rating_lowest_to_highest: 'Nach niedrigster zu höchster Bewertung sortieren',
    },
  },
  table: {
    pagination: {
      total_one: 'Insgesamt {{count}} Bewertung',
      total_other: 'Insgesamt {{count}} Bewertungen',
    },
  },
  modal_publish: {
    title: 'Bist du sicher, dass du bei Google veröffentlichen möchtest?',
    buttons: {
      yes: 'Ja',
      cancel: 'Abbrechen',
    },
  },
  popconfirm: {
    title: 'Bist du sicher?',
    description: 'Alle lokalen Änderungen werden überschrieben.',
  },
  buttons: {
    login_with_google: 'Anmeldung mit Google',
    sync_from_google: 'Hole die neueste Version',
    publish_to_google: 'Bei Google veröffentlichen',
    previous: 'Vorherige',
    next: 'Next',
    save_settings: 'Einstellungen speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    failed_to_fetch_reviews: 'Abrufen von Bewertungen fehlgeschlagen',
    failed_to_fetch_settings: 'Einstellungen konnten nicht abgerufen werden',
    failed_to_load_google_business: 'Google Business konnte nicht geladen werden',
    failed_to_publish_to_google_business: 'Veröffentlichung auf Google Business fehlgeschlagen',
    failed_to_fetch_info: 'Fehler beim Abrufen der neuesten Google-Business-Version',
    success_publish_to_google_business: 'Erfolgreich auf Google Business veröffentlicht',
    success_sync_from_google: 'Erfolgreich von Google Business synchronisiert',
    success_save_settings: 'Einstellungen erfolgreich gespeichert',
  },
};
