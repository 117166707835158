export default {
  title: 'New product',
  form: {
    categories: {
      label: 'Categories',
    },
    printer: {
      label: 'Printer',
    },
    name: {
      label: 'Name',
      placeholder: 'Cheeseburger',
    },
    description: {
      label: 'Description',
      placeholder:
        '180g beef paddy, salat, tomatos, cucumbers, jalapenos and yuppi sauce',
    },
    combo_discount: {
      label: 'Discount',
    },
    price: {
      label: 'Base price',
    },
    has_smart_pricing: {
      label: 'Special prices for pickup and delivery',
    },
    pickup_price: {
      label: 'Pickup price',
      placeholder: 'Not specified',
    },
    delivery_price: {
      label: 'Delivery price',
      placeholder: 'Not specified',
    },
    image: {
      label: 'Image (optional)',
    },
    tax_percentage: {
      label: 'Sales tax %',
    },
    has_combo_tax: {
      label: 'Has combo tax',
    },
    is_available: {
      label: 'In stock',
    },
    is_visible: {
      label: 'Visible',
    },
    is_promotable: {
      label: 'Promotable',
    },
    free_eligible: {
      label: 'Free Eligible',
    },
    modifiers: {
      label: 'Modifiers',
    },
    identifiers: {
      label: 'Identifiers',
    },
  },
  labels: {
    smart_pricing_update_warning: 'By updating, all existing smart pricing rules for this product will be rewritten.',
    smart_pricing_remove_warning: 'After saving, all existing smart pricing rules for this product will be removed.',
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    changes_got_saved: 'Changes have been successfully saved!',
  },
};
