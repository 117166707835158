export default {
  form: {
    address: {
      label: 'Address',
      placeholder: 'Street + number',
    },
    city: {
      label: 'City',
      placeholder: 'City',
    },
    state: {
      label: 'State',
      placeholder: 'Select state',
    },
    country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    zip_code: {
      label: 'Postal code',
      placeholder: 'Postal code',
    },
  },
};