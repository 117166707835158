import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  name: null,
  companies: [],
};

export default createSlice({
  name: 'currentFranchise',
  initialState: {
    franchise: initialState,
  },
  reducers: {
    set: (state, action) => {
      if (!action.payload) {
        state.franchise = initialState;

        return;
      }

      state.franchise = action.payload;
    },
    reset: (state) => {
      state.franchise = initialState;
    },
  },
});
