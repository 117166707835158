/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import {
  AndroidOutlined, AppleOutlined, MenuOutlined, ReloadOutlined,
} from '@ant-design/icons';
import PlayStoreModal from '../../modals/play-store/PlayStoreModal';
import NotificationsDropdown from './NotificationsDropdown';
import ProfileDropdown from './ProfileDropdown';
import LanguageSwitcher from '../intl/LanguageSwitcher';
import useScreenType from '../../hooks/context/useScreenType';
import useAppState from '../../hooks/context/useAppState';
import usePermissions from '../../hooks/auth/usePermissions';

function Header() {
  const { isDesktop, isMobile } = useScreenType();
  const { setShowSideBar } = useAppState();
  const { isMerchant } = usePermissions();

  const [showPlayStoreModal, setShowPlayStoreModal] = useState(false);

  const renderPlayStoreModal = useMemo(
    () => (
      <PlayStoreModal
        open={showPlayStoreModal}
        toggle={setShowPlayStoreModal}
      />
    ),
    [showPlayStoreModal],
  );

  if (!isDesktop) {
    return (
      <div className="tw-sticky tw-top-0  tw-px-small tw-h-[70px] tw-items-center tw-grid tw-grid-cols-3 border-b bg-white">
        <div>
          <Button className="tw-mr-small tw-px-mini" onClick={() => setShowSideBar(true)}>
            <MenuOutlined />
          </Button>
        </div>
        <Button className="tw-w-fit tw-mx-auto" onClick={() => window.location.reload()}>
          <ReloadOutlined />
        </Button>
        <div className="tw-flex tw-justify-end tw-items-center tw-gap-4">
          {!isMobile && <LanguageSwitcher />}
          <NotificationsDropdown />
          <ProfileDropdown />
        </div>
      </div>
    );
  }

  return (
    <div
      className="tw-sticky tw-top-0 tw-flex tw-justify-between tw-px-small tw-z-[100] tw-h-[70px] tw-items-center border-b bg-white"
    >
      <Button className="tw-px-mini" onClick={() => setShowSideBar((current) => !current)}>
        <MenuOutlined />
      </Button>
      <div className="tw-flex tw-items-center tw-gap-4">
        <Button onClick={() => setShowPlayStoreModal(true)}>
          <AndroidOutlined />
          <AppleOutlined />
        </Button>
        <div className="tw-flex tw-items-center tw-gap-4">
          <LanguageSwitcher />
          {isMerchant && <NotificationsDropdown />}
          <ProfileDropdown />
        </div>
      </div>
      {renderPlayStoreModal}
    </div>
  );
}

export default Header;
