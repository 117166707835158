export default {
  form: {
    tax_id: {
      label: 'Tax number',
      placeholder: 'Enter tax number',
    },
    has_vat: {
      label: 'Is your business VAT registered?',
    },
    vat_id: {
      label: 'VAT',
      placeholder: 'VAT',
      tooltip: 'A VAT number is a unique tax number that identifies your business',
    },
    tax_percentage: {
      label: 'Tax percentage',
      placeholder: 'Tax percentage',
    },
    has_dedicated_products_tax: {
      label: 'Tax % differs based on product',
    },
  },
  buttons: {
    next: 'Next',
    back: 'Back',
    skip: 'Skip',
  },
};
