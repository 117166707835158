export default {
  labels: {
    google_place: 'Google place',
    menu_import: 'Menu import',
  },
  buttons: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  delete_confirmation: 'Are you sure?',
};
