export default {
  form: {
    name: {
      label: 'Name des Restaurants',
      placeholder: 'Name des Restaurants eingeben',
    },
    google_place: {
      label: 'Ausgewählter Google-Platz: {{name}}',
    },
    sync_lieferando_brand: {
      label: 'Markendetails (Logo, Grundfarbe) von Lieferando abrufen',
    },
    import_menu: {
      label: 'Menü Importieren',
    },
    lieferando_slug: {
      label: 'Lieferando slug',
      placeholder: 'Letzter Teil der Restaurant-URL in Lieferando',
    },
  },
  buttons: {
    save: 'Speichern',
  },
};
