export default {
  title: 'Restaurant groups',
  form: {
    name: {
      placeholder: 'Search restaurant...',
    },
  },
  fields: {
    id: '#',
    name: 'Name',
    address: 'Address',
    subdomain: 'Subdomain',
    companies: 'Companies',
    slug: 'Slug',
    actions: '',
  },
  empty_list: 'No franchises yet',
  modals: {
    delete_franchise: {
      title: 'Are you sure you want to delete {{name}} franchise?',
    },
  },
  buttons: {
    new_franchise: '+ Add restaurant group',
    edit: 'Edit',
    delete: 'Delete',
    view_dashboard_as_owner: 'View dashboard as owner',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_delete_franchise: 'Failed to delete franchise',
    franchise_got_deleted: 'Franchise got deleted',
    failed_to_create_magic_link: 'Failed to create magic link',
  },
};
