import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import GeneralTab from '../../components/dashboard/GeneralTab';
import RestaurantDetailsTab from '../../components/dashboard/RestaurantDetailsTab';
import usePermissions from '../../hooks/auth/usePermissions';
import { PERMISSIONS } from '../../services/exports/Constants';
import ExternalDeliveryTab from '../../components/dashboard/ExternalDeliveryTab';
import DateRangePicker, {
  filter_this_month,
  filter_last_month,
  filter_all, variant_radio_group, variant_dropdown,
} from '../../components/filters/DateRangePicker';
import useHelpers from '../../hooks/context/useHelpers';
import useScreenType from '../../hooks/context/useScreenType';

export const tabs = {
  general: 'general',
  restaurant_details: 'restaurant_details',
  external_delivery: 'external_delivery',
};

const DEFAULT_DATE_RANGE_PERIOD = 'month';

export default function Dashboard() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Dashboard:Dashboard' });

  const { can } = usePermissions();
  const { isDesktop } = useScreenType();
  const { getActiveTab, setActiveTab } = useHelpers();

  const [dateRangeFilter, setDateRangeFilter] = useState({
    period: DEFAULT_DATE_RANGE_PERIOD,
  });

  const activeTab = getActiveTab('dashboard.section', tabs.general);
  const TabContent = ({
    [tabs.general]: <GeneralTab dateRangeFilter={dateRangeFilter} />,
    [tabs.restaurant_details]: <RestaurantDetailsTab dateRangeFilter={dateRangeFilter} />,
    [tabs.external_delivery]: <ExternalDeliveryTab dateRangeFilter={dateRangeFilter} />,
  })[activeTab] ?? null;

  const visibleTabs = collect([
    tabs.general,
  ]).when(
    can(PERMISSIONS.dashboard_view_restaurant_details_tab),
    (collection) => collection.merge([
      tabs.restaurant_details,
      tabs.external_delivery,
    ]),
  );

  const onChangeDateRangeFilter = (filter) => {
    if (filter.period) {
      setDateRangeFilter({ period: filter.period });

      return;
    }

    setDateRangeFilter({
      start_date: filter.start_date,
      end_date: filter.end_date,
    });
  };

  return (
    <>
      {visibleTabs.count() > 1 && (
        <Tabs
          activeKey={activeTab ?? tabs.general}
          onChange={(tab) => setActiveTab(tab, 'dashboard.section')}
          items={[
            {
              key: tabs.general,
              label: t(`tabs.${tabs.general}`),
            },
            {
              key: tabs.restaurant_details,
              label: t(`tabs.${tabs.restaurant_details}`),
            },
            {
              key: tabs.external_delivery,
              label: t(`tabs.${tabs.external_delivery}`),
            },
          ]}
        />
      )}
      <DateRangePicker
        variant={isDesktop ? variant_radio_group : variant_dropdown}
        excludeFilters={[filter_this_month, filter_last_month, filter_all]}
        onChange={onChangeDateRangeFilter}
        customFilters={[
          {
            id: 'filter_period_month',
            name: t('date_range.month'),
            period: 'month',
          },
          {
            id: 'filter_period_last_month',
            name: t('date_range.last_month'),
            period: 'last_month',
          },
          {
            id: 'filter_period_all',
            name: t('date_range.all'),
            start_date: null,
            end_date: null,
          },
        ]}
      />
      {TabContent}
    </>
  );
}
