import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import {
  Button, Drawer, Flex, Form, Input, Space, Switch,
} from 'antd';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import useScreenType from '../../hooks/context/useScreenType';
import useForm from '../../hooks/utility/useForm';
import { MENU_PROVIDERS } from '../../services/exports/Constants';
import ImportMenu from '../../components/menu/ImportMenu';
import GooglePlaceHelpers from '../../services/helpers/GooglePlaceHelpers';
import GooglePlacesSearch from '../../components/form/GooglePlacesSearch';
import AddressFields from '../../components/form/AddressFields';

function CompanyDetails({ data, onChange }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:Company:AddCompanyModal' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  useEffect(() => {
    data.menu_provider === MENU_PROVIDERS.lieferando
    && data.sync_lieferando_brand
    && !!data.lieferando_slug
    && data.lieferando_slug !== ''
    && onChange('menu_provider_id', data.lieferando_slug);
  }, [
    data.sync_lieferando_brand,
    data.lieferando_slug,
    data.menu_provider,
  ]);

  const providers = useMemo(() => [
    {
      is_supported: serviceProvider.regional_config.supported_menu_providers.includes(MENU_PROVIDERS.lieferando),
      renderSettings: () => (data.sync_lieferando_brand || data.sync_lieferando_menu) && (
        <div className="tw-mt-small">
          <Form.Item label={t('form.lieferando_slug.label')}>
            <Input
              value={data.lieferando_slug ?? ''}
              onChange={(e) => onChange('lieferando_slug', e.target.value)}
              placeholder={t('form.lieferando_slug.placeholder')}
            />
          </Form.Item>
        </div>
      ),
      renderToggles: () => (
        <Flex>
          <Switch
            checked={data.sync_lieferando_brand}
            onChange={(checked) => onChange('sync_lieferando_brand', checked)}
            id="sync-lieferando-brand"
          />
          <label
            htmlFor="sync-lieferando-brand"
            className="tw-ml-mini tw-my-auto"
          >
            {t('form.sync_lieferando_brand.label')}
          </label>
        </Flex>
      ),
    },
    {
      id: MENU_PROVIDERS.wolt,
      is_supported: true,
      renderSettings: () => data.import_menu && (
        <ImportMenu
          data={{
            provider: data.menu_provider,
            provider_id: data.menu_provider_id,
          }}
          update={(field, value) => onChange(`menu_${field}`, value)}
          nullable
          className="tw-mt-small"
        />
      ),
      renderToggles: () => (
        <Flex className="tw-mt-medium">
          <Switch
            checked={data.import_menu}
            onChange={(checked) => onChange('import_menu', checked)}
            id="import-menu"
          />
          <label
            htmlFor="import-menu"
            className="tw-ml-mini tw-my-auto"
          >
            {t('form.import_menu.label')}
          </label>
        </Flex>
      ),
    },
  ], [data, serviceProvider]);

  const onPlaceSelected = (place) => onChange({
    name: place.name.split(',')[0] ?? place.name,
    google_place: place,
    address: GooglePlaceHelpers.getAddress(place),
    city: GooglePlaceHelpers.getCity(place),
    state: GooglePlaceHelpers.getState(place),
    country: GooglePlaceHelpers.getCountry(place),
    zip_code: GooglePlaceHelpers.getZipCode(place),
  });

  return (
    <Form layout="vertical">
      <Form.Item
        label={t('form.name.label')}
        required
      >
        <GooglePlacesSearch
          value={data.name ?? ''}
          onPlaceSelected={onPlaceSelected}
          onChange={(e) => onChange('name', e.target.value)}
          placeholder={t('form.name.placeholder')}
          options={{
            fields: ['name', 'place_id', 'address_components'],
            types: ['bakery', 'bar', 'cafe', 'restaurant', 'food'],
          }}
        />
        {data.google_place && (
          <Space className="tw-mt-mini">
            <a href="#">
              {t('form.google_place.label', { name: data.google_place.name })}
            </a>
            <DeleteOutlined
              className="tw-my-auto cursor-pointer"
              onClick={() => onChange('google_place', null)}
            />
          </Space>
        )}
      </Form.Item>
      <AddressFields data={data} onChange={onChange} useGrid />
      {providers.map((item) => item.is_supported && (
        <React.Fragment key={`menu-provider-${item.id}`}>
          {item.renderToggles()}
          {item.renderSettings()}
        </React.Fragment>
      ))}
    </Form>
  );
}

export default function AddCompanyModal({ company, onSave, onClose }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:Company:AddCompanyModal' });

  const { isDesktop } = useScreenType();

  const { data, update } = useForm({
    google_place: company?.google_place ?? null,
    name: company?.name ?? null,
    address: company?.address ?? null,
    city: company?.city ?? null,
    state: company?.state ?? null,
    country: company?.country ?? null,
    zip_code: company?.zip_code ?? null,

    sync_lieferando_brand: company?.sync_lieferando_brand ?? false,
    lieferando_slug: company?.lieferando_slug ?? null,

    import_menu: company?.import_menu ?? false,
    menu_provider: company?.menu_provider ?? null,
    menu_provider_id: company?.menu_provider_id ?? null,
  });

  const isValid = useMemo(
    () => Boolean(data.name)
      && Boolean(data.address)
      && Boolean(data.city)
      && Boolean(data.state)
      && Boolean(data.country)
      && Boolean(data.zip_code),
    [data],
  );

  const _onSave = () => {
    const sanitized = {
      ...data,
      sync_lieferando_brand: data.sync_lieferando_brand && Boolean(data.lieferando_slug),
      import_menu: data.import_menu && Boolean(data.menu_provider) && Boolean(data.menu_provider_id),
    };

    onSave(sanitized);
    onClose();
  };

  return (
    <Drawer
      open
      width={window.innerWidth * (isDesktop ? 0.5 : 1)}
      onClose={onClose}
    >
      <CompanyDetails data={data} onChange={update} />
      <Flex justify="end" className="tw-mt-small">
        <Button
          type="primary"
          disabled={!isValid}
          onClick={_onSave}
        >
          {t('buttons.save')}
        </Button>
      </Flex>
    </Drawer>
  );
}
