import ApiResourceManager from '../ApiResourceManager';

export default class BogoCampaignManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/bogo-campaign',
  });

  put = (data) => this.request({
    method: 'PUT',
    url: '/bogo-campaign',
    data,
  });
}
