import React, {
  useCallback, useMemo, createContext, useState, memo,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Radio, Spin,
} from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { collect } from 'collect.js';
import useApiClient from '../hooks/api/useApiClient';
import useScreenType from '../hooks/context/useScreenType';
import useFeedbackLabel from '../hooks/ui/useFeedbackLabel';

export const BusinessProfileContext = createContext(null);

export const BusinessProfileUpdateStrategy = {
  All: 'all',
  Current: 'current',
};

function UpdateStrategyModal({
  companies,
  loading,
  onConfirm,
  onClose,
  renderFeedbackLabel,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Providers:BusinessProfileProvider' });

  const { company } = useSelector((store) => store.currentCompany);

  const { isDesktop } = useScreenType();

  const [selectedStrategy, setSelectedStrategy] = useState(null);

  return (
    <Drawer
      width={window.innerWidth * (isDesktop ? 0.5 : 1)}
      open
      title={t('modals.update_strategy.title')}
      onClose={onClose}
    >
      {renderFeedbackLabel}
      <Card
        title={(
          <Radio
            checked={selectedStrategy === BusinessProfileUpdateStrategy.All}
            onChange={(e) => e.target.checked && setSelectedStrategy(BusinessProfileUpdateStrategy.All)}
          >
            {t(`modals.update_strategy.form.update_strategy.options.${BusinessProfileUpdateStrategy.All}`)}
          </Radio>
        )}
        classNames={{ header: '!tw-px-mini' }}
      >
        {companies.map((item, index) => (
          <Card.Grid className="!tw-p-mini" hoverable={false} key={`location-${index}`}>
            <p>{item.name}</p>
            <p className="tw-text-gray-500 tw-text-sm tw-mt-mini">{item.address}</p>
          </Card.Grid>
        ))}
      </Card>
      <Divider />
      <Card
        title={(
          <Radio
            checked={selectedStrategy === BusinessProfileUpdateStrategy.Current}
            onChange={(e) => e.target.checked && setSelectedStrategy(BusinessProfileUpdateStrategy.Current)}
          >
            {t(`modals.update_strategy.form.update_strategy.options.${BusinessProfileUpdateStrategy.Current}`)}
          </Radio>
        )}
        className="tw-mt-small"
        classNames={{ header: '!tw-px-mini', body: '!tw-p-mini' }}
      >
        <p>{company.name}</p>
        <p className="tw-text-gray-500 tw-text-sm tw-mt-mini">{company.address}</p>
      </Card>

      <Flex justify="end">
        <Button
          type="primary"
          className="tw-mt-small"
          loading={loading}
          disabled={!selectedStrategy}
          onClick={() => onConfirm(selectedStrategy)}
        >
          {t('modals.update_strategy.buttons.confirm')}
        </Button>
      </Flex>
    </Drawer>
  );
}

export default function BusinessProfileProvider({ children }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Providers:BusinessProfileProvider' });

  const { BusinessProfilesManager, CompaniesManager } = useApiClient();

  const { company } = useSelector((store) => store.currentCompany);
  const { business_profile } = company;

  const [loadingCompany, setLoadingCompany] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [showUpdateStrategyModal, setShowUpdateStrategyModal] = useState(false);
  const [onUpdateConfirmation, setOnUpdateConfirmation] = useState(false);
  const { setErrorFeedback, resetFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const profile = useMemo(
    () => business_profile?.parent ?? business_profile,
    [business_profile],
  );

  const companies = useMemo(
    () => collect(profile?.companies)
      .merge(
        collect(profile?.children)
          .pluck('companies')
          .flatten(1)
          .toArray(),
      )
      .reject((item) => item.id === company.id)
      .sortBy('id')
      .when(
        Boolean(company.id),
        (collection) => collection.prepend({
          id: company.id,
          name: company.name,
          address: company.address,
        }),
      )
      .toArray(),
    [profile, company.id, company.name, company.address],
  );

  const toggleUpdateStrategyModal = (open = false, onConfirm = null) => {
    setShowUpdateStrategyModal(open);
    setOnUpdateConfirmation(() => onConfirm);
  };

  const loadCompany = useCallback(async (id) => {
    if (id === company.id) {
      return;
    }

    setLoadingCompany(id);
    const { success } = await CompaniesManager.show(id);
    setLoadingCompany(null);

    if (!success) {
      toast.error(t('toasts.error'));
    }
  }, [company.id, CompaniesManager]);

  const pushUpdates = useCallback(
    async ({
      data,
      updateStrategy = null,
      onSuccess = null,
      onError = null,
    }) => {
      const _onSuccess = (response) => {
        resetFeedback();
        setUpdating(false);
        toast.success(t('toasts.success'));
        toggleUpdateStrategyModal();

        return onSuccess ? onSuccess(response) : response;
      };

      const _onError = (response) => {
        setUpdating(false);
        toast.error(t('toasts.error'));
        setErrorFeedback(response.data);

        return onError ? onError(response) : response;
      };

      if (updateStrategy !== null) {
        setUpdating(true);

        return BusinessProfilesManager.update({
          ...data,
          update_strategy: updateStrategy,
        }).then((response) => (response.success ? _onSuccess(response) : _onError(response)));
      }

      if (companies.length < 2) {
        setUpdating(true);

        return BusinessProfilesManager.update({
          ...data,
          update_strategy: 'all',
        }).then((response) => (response.success ? _onSuccess(response) : _onError(response)));
      }

      return toggleUpdateStrategyModal(
        true,
        (selectedStrategy) => pushUpdates({
          data,
          updateStrategy: selectedStrategy,
          onSuccess,
          onError,
        }),
      );
    },
    [companies],
  );

  const CompanyCards = memo(
    ({ className }) => (
      <Flex gap={8} className={classnames('tw-overflow-x-auto', className)}>
        {companies.map((item) => (
          <Card
            classNames={{ body: '!tw-p-mini' }}
            className={classnames('tw-min-w-[55%] md:tw-min-w-[28%]', { 'tw-border-brand-primary': company.id === item.id })}
          >
            <Flex gap="8px">
              {loadingCompany === item.id ? (
                <Spin size="small" />
              ) : (
                <Radio
                  checked={company.id === item.id}
                  onClick={() => loadCompany(item.id)}
                  id={`company-${item.id}-switch`}
                  className="tw-mr-none"
                />
              )}
              <label htmlFor={`company-${item.id}-switch`} className="tw-mb-none tw-cursor-pointer">
                {item.name}
              </label>
            </Flex>
            <p className="tw-text-gray-500 tw-text-sm tw-mt-mini">{item.address}</p>
          </Card>
        ))}
      </Flex>
    ),
  );

  const context = useMemo(
    () => ({
      updating,
      business_profile: profile,
      companies,
      pushUpdates,
      CompanyCards,
    }),
    [
      updating,
      profile,
      companies,
      pushUpdates,
      CompanyCards,
    ],
  );

  return (
    <BusinessProfileContext.Provider value={context}>
      {children}
      {showUpdateStrategyModal && (
        <UpdateStrategyModal
          companies={companies}
          loading={updating}
          onConfirm={onUpdateConfirmation}
          onClose={() => toggleUpdateStrategyModal()}
          renderFeedbackLabel={renderFeedbackLabel}
        />
      )}
    </BusinessProfileContext.Provider>
  );
}
