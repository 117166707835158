export default {
  title: 'Buy One, Get One Free',
  form: {
    active: {
      label: 'Is active',
    },
    start_date: {
      label: 'Start Date',
      placeholder: 'Start Date',
    },
    end_date: {
      label: 'End Date',
      placeholder: 'End Date',
    },
    weekly_spending_limit: {
      label: 'Weekly Spending Limit',
      placeholder: 'Weekly Spending Limit',
    },
    products: {
      label: 'Products (up to 10 items)',
      placeholder: 'Products (up to 10 items)',
    },
  },
  buttons: {
    save: 'Save',
  },
};
