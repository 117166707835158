import React, { useMemo, useState } from 'react';
import FeedbackLabel from '../../components/label/FeedbackLabel';

export default function useFeedbackLabel({
  initialState = null,
  className = undefined,
} = {}) {
  const emptyState = {
    message: '',
    type: '',
    errors: null,
  };
  const _initialState = initialState ?? emptyState;

  const [feedback, setFeedback] = useState(initialState);

  const resetFeedback = () => setFeedback(emptyState);

  const setErrorFeedback = (response) => setFeedback({
    type: 'error',
    message: response?.message,
    errors: response?.errors,
  });

  const renderFeedbackLabel = useMemo(() => (feedback?.message && (
    <FeedbackLabel
      message={feedback?.message}
      type={feedback?.type}
      errors={feedback?.errors}
      className={className}
    />
  )), [feedback]);

  return {
    feedback,
    setFeedback,
    resetFeedback,
    setErrorFeedback,
    renderFeedbackLabel,
  };
}
