export default {
  sections: {
    address: 'Address',
    legal_address: 'Registration address',
    invoice_address: 'Invoice address',
  },
  form: {
    display_name: {
      label: 'Friendly name',
      placeholder: 'Enter friendly company name',
    },
    legal_name: {
      label: 'Legal name',
      placeholder: 'Enter legal name',
      tooltip: 'Registration name. Displayed on payout reports, contract, etc',
    },
    timezone: {
      label: 'Timezone',
    },
    has_dedicated_invoice_address: {
      label: 'Different billing info',
    },
  },
  buttons: {
    next: 'Next',
  },
};
