import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import { useSelector } from 'react-redux';
import LoadingScreen from '../loading/LoadingScreen';
import useApiClient from '../../hooks/api/useApiClient';
import useHelpers from '../../hooks/context/useHelpers';
import HelperMethods from '../../services/exports/HelperMethods';
import Metric from '../stats/Metric';
import MetricsContainer from '../stats/MetricsContainer';

const requiredMetrics = [
  'companies',
  'live_restaurants',
  'live_restaurants_percentage',
  'crossed_sales_level_this_month',
  'crossed_sales_level_total',
  'crossed_sales_level_percentage',
  'has_delivery',
  'has_only_pickup',
  'has_storefront',
  'has_storefront_percentage',
  'use_external_delivery',
  'crossed_monthly_sales_threshold_0',
  'crossed_monthly_sales_threshold_700',
  'crossed_monthly_sales_threshold_2500',
  'crossed_monthly_sales_threshold_10000',
];

const requiredReservationMetrics = [
  'companies_with_activated_reservations',
];

export default function RestaurantDetailsTab({ dateRangeFilter }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Dashboard:Dashboard' });

  const { StatsManager } = useApiClient();
  const { ReservationsManager } = useApiClient({
    baseUrl: import.meta.env.VITE_RESERVATION_API_BASE_URL,
    autoLogOut: false,
  });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { formatCurrency } = useHelpers();
  const percentageFormatter = (value) => HelperMethods.formatPercentage(value);

  useEffect(() => {
    requestStatistics();
  }, [serviceProvider?.id, dateRangeFilter]);

  async function requestStatistics() {
    setLoading(true);

    const { data } = await StatsManager.getStats({
      metrics: requiredMetrics,
      ...dateRangeFilter,
    });

    const { data: reservationsData } = await ReservationsManager.getStats({
      service_provider_id: serviceProvider?.id,
      metrics: requiredReservationMetrics,
      ...dateRangeFilter,
    });

    setLoading(false);

    setData(collect(data).mergeRecursive(reservationsData).all());
  }

  const metrics = data ? [
    <Metric
      key={t('metrics.restaurant_partners')}
      name={t('metrics.restaurant_partners')}
      items={[
        {
          name: t('metrics.registered_restaurants'),
          ...data.companies,
        },
        {
          name: t('metrics.live_restaurants'),
          ...data.live_restaurants,
        },
        {
          name: t('metrics.live_restaurants_percentage'),
          formatter: percentageFormatter,
          ...data.live_restaurants_percentage,
        },
      ]}
    />,
    <Metric
      key={t('metrics.use_reservations')}
      name={t('metrics.use_reservations')}
      items={[
        {
          name: t('metrics.number'),
          ...data.companies_with_activated_reservations,
        },
        {
          name: t('metrics.restaurants_percentage'),
          formatter: percentageFormatter,
          value: HelperMethods.percentagePart(
            data.companies_with_activated_reservations?.value,
            data.companies?.value,
          ),
          previous: HelperMethods.percentagePart(
            data.companies_with_activated_reservations?.previous,
            data.companies?.previous,
          ),
        },
      ]}
    />,
    <Metric
      key={t('metrics.crossed_sales_level', { amount: formatCurrency(700) })}
      name={t('metrics.crossed_sales_level', { amount: formatCurrency(700) })}
      items={[
        {
          name: t('metrics.crossed_sales_level_this_month'),
          ...data.crossed_sales_level_this_month,
        },
        {
          name: t('metrics.crossed_sales_level_total'),
          ...data.crossed_sales_level_total,
        },
        {
          name: t('metrics.restaurants_percentage'),
          formatter: percentageFormatter,
          ...data.crossed_sales_level_percentage,
        },
      ]}
    />,
    <Metric
      key={t('metrics.order_method')}
      name={t('metrics.order_method')}
      items={[
        {
          name: t('metrics.own_delivery'),
          ...data.has_delivery,
        },
        {
          name: t('metrics.only_pickup'),
          ...data.has_only_pickup,
        },
        {
          name: t('metrics.use_external_delivery'),
          ...data.use_external_delivery,
        },
      ]}
    />,
    <Metric
      key={t('metrics.storefront')}
      name={t('metrics.storefront')}
      items={[
        {
          name: t('metrics.has_storefront'),
          ...data.has_storefront,
        },
        {
          name: t('metrics.has_storefront_percentage'),
          formatter: percentageFormatter,
          ...data.has_storefront_percentage,
        },
      ]}
    />,
    <Metric
      key={t('metrics.sales_tiers')}
      name={t('metrics.sales_tiers')}
      items={[
        {
          name: `0-${formatCurrency(699)}`,
          ...data.crossed_monthly_sales_threshold_0,
        },
        {
          name: `+${formatCurrency(700)}`,
          ...data.crossed_monthly_sales_threshold_700,
        },
        {
          name: `+${formatCurrency(2500)}`,
          ...data.crossed_monthly_sales_threshold_2500,
        },
        {
          name: `+${formatCurrency(10000)}`,
          ...data.crossed_monthly_sales_threshold_10000,
        },
      ]}
    />,
  ] : [];

  return loading ? <LoadingScreen /> : <MetricsContainer metrics={metrics} />;
}
