import axios from 'axios';
import { useMemo } from 'react';
import { COOKIES } from '../../services/exports/Constants';
import useAppCookies from '../utility/useAppCookies';

export default function useSSO() {
  const { getCookie } = useAppCookies([COOKIES.AUTH_TOKEN]);

  const token = getCookie(COOKIES.AUTH_TOKEN);

  const config = useMemo(
    () => ({
      baseURL: import.meta.env.VITE_SSO_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }),
    [token],
  );

  const client = useMemo(
    () => axios.create(config),
    [config],
  );

  const login = async (data) => client.request({
    method: 'POST',
    url: '/login',
    data,
  });

  const refreshToken = async () => client.request({
    method: 'GET',
    url: '/refresh-token',
  });

  const logout = async () => client.request({
    method: 'GET',
    url: '/logout',
  });

  const initPasswordReset = async (data) => client.request({
    method: 'POST',
    url: '/password-reset-tokens',
    data,
  });

  const resetPassword = async (data) => client.request({
    method: 'POST',
    url: '/password-reset',
    data,
  });

  return {
    client,
    login,
    refreshToken,
    logout,
    initPasswordReset,
    resetPassword,
  };
}
