import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Card, Form, InputNumber, Radio, Switch,
} from 'antd';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { SettingOutlined } from '@ant-design/icons';
import { collect } from 'collect.js';
import useApiClient from '../../../hooks/api/useApiClient';
import LoadingScreen from '../../../components/loading/LoadingScreen';
import RewardLoyaltyProgram from '../../../components/loyalty-program/RewardLoyaltyProgram';
import CashbackLoyaltyProgram from '../../../components/loyalty-program/CashbackLoyaltyProgram';
import useHelpers from '../../../hooks/context/useHelpers';
import usePermissions from '../../../hooks/auth/usePermissions';
import { PERMISSIONS } from '../../../services/exports/Constants';

export const LoyaltyProgramMode = {
  reward: 'reward',
  cashback: 'cashback',
};

export default function LoyaltyProgramView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Marketing:LoyaltyProgram' });

  const { PromotionsManager, RewardsManager } = useApiClient();

  const { cant } = usePermissions();
  const { formatCurrency } = useHelpers();

  const [mode, setMode] = useState(LoyaltyProgramMode.reward);
  const [loyaltyProgram, setLoyaltyProgram] = useState(null);
  const [cashbackProgram, setCashbackProgram] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [loadingLoyaltyProgram, setLoadingLoyaltyProgram] = useState(true);
  const [loadingCashbackProgram, setLoadingCashbackProgram] = useState(true);
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [loyaltyProgramLoaded, setLoyaltyProgramLoaded] = useState(false);
  const [cashbackProgramLoaded, setCashbackProgramLoaded] = useState(false);
  const [showModeCard, setShowModeCard] = useState(false);
  const [convertExistingLoyaltyProgram, setConvertExistingLoyaltyProgram] = useState(false);
  const [conversionMultiplier, setConversionMultiplier] = useState(100);

  const loading = loadingLoyaltyProgram || loadingCashbackProgram;

  useEffect(() => {
    loadLoyaltyProgram().then(() => setLoyaltyProgramLoaded(true));
    loadCashbackProgram().then(() => setCashbackProgramLoaded(true));
    loadRewards();
  }, []);

  useEffect(() => {
    if (!loyaltyProgramLoaded || !cashbackProgramLoaded) {
      return;
    }

    if (
      loyaltyProgram
      && (
        !cashbackProgram
        || loyaltyProgram.end_date === null
        || moment(loyaltyProgram.end_date).isAfter(moment())
      )
    ) {
      return setMode(LoyaltyProgramMode.reward);
    }

    setMode(LoyaltyProgramMode.cashback);
  }, [loyaltyProgramLoaded, cashbackProgramLoaded, loyaltyProgram, cashbackProgram]);

  const loadLoyaltyProgram = async () => {
    setLoadingLoyaltyProgram(true);
    const { success, data } = await PromotionsManager.getLoyaltyProgram();
    setLoadingLoyaltyProgram(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    if (!data.id) {
      return;
    }

    setLoyaltyProgram(data);
  };

  const loadCashbackProgram = async () => {
    setLoadingCashbackProgram(true);
    const { success, data } = await PromotionsManager.getCashbackProgram();
    setLoadingCashbackProgram(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    if (!data.id) {
      return;
    }

    setCashbackProgram(data);
  };

  const loadRewards = async () => {
    setLoadingRewards(true);
    const { success, data } = await RewardsManager.get();
    setLoadingRewards(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setRewards(data);
  };

  const renderLoyaltyProgramModeCardToggler = () => {
    if (
      !loyaltyProgramLoaded
      || !cashbackProgramLoaded
      || cashbackProgram?.id
      || cant(PERMISSIONS.convert_loyalty_program_to_cashback)
    ) {
      return null;
    }

    return (
      <SettingOutlined
        onClick={() => setShowModeCard(true)}
        className="tw-ml-small tw-my-auto"
      />
    );
  };

  const renderLoyaltyProgramModeCard = () => {
    if (
      !loyaltyProgramLoaded
      || !cashbackProgramLoaded
      || !showModeCard
    ) {
      return null;
    }

    return (
      <Card
        title={t('sections.program_mode.title')}
        className="tw-mt-medium"
      >
        <Form layout="vertical">
          <Form.Item>
            <Radio.Group
              value={mode}
              onChange={(e) => setMode(e.target.value)}
            >
              <Radio value={LoyaltyProgramMode.reward}>{t(`sections.program_mode.form.mode.options.${LoyaltyProgramMode.reward}`)}</Radio>
              <Radio value={LoyaltyProgramMode.cashback}>{t(`sections.program_mode.form.mode.options.${LoyaltyProgramMode.cashback}`)}</Radio>
            </Radio.Group>
          </Form.Item>
          {loyaltyProgram?.id !== null && !cashbackProgram && mode === LoyaltyProgramMode.cashback && (
            <>
              <Form.Item>
                <Switch
                  checked={convertExistingLoyaltyProgram}
                  onChange={setConvertExistingLoyaltyProgram}
                  id="convert_existing_loyalty_program"
                />
                <label htmlFor="convert_existing_loyalty_program" className="tw-ml-mini tw-my-auto">{t('sections.program_mode.form.convert_existing_loyalty_program.label')}</label>
              </Form.Item>
              {convertExistingLoyaltyProgram && (
                <>
                  <Alert
                    message={(<h6>{t('conversion_banner.title')}</h6>)}
                    description={(
                      <>
                        <p>
                          <Trans t={t} values={{ value: formatCurrency(conversionMultiplier / 100) }}>conversion_banner.point_value_description</Trans>
                        </p>
                        {rewards.length > 0 && (
                        <p className="tw-mt-small">
                          <Trans
                            t={t}
                            values={{ value: formatCurrency(collect(rewards).max('cost') * (conversionMultiplier / 100)) }}
                          >
                            conversion_banner.max_reward_description
                          </Trans>
                        </p>
                        )}
                      </>
                  )}
                    className="tw-mt-small"
                  />
                  <Form.Item
                    required
                    label={t('sections.program_mode.form.conversion_multiplier.label')}
                    className="tw-mt-small"
                  >
                    <InputNumber
                      value={conversionMultiplier}
                      onChange={setConversionMultiplier}
                      step={0.1}
                    />
                  </Form.Item>
                </>
              )}
            </>
          )}
        </Form>
      </Card>
    );
  };

  return (
    <>
      <h2>
        {t('title')}
        {renderLoyaltyProgramModeCardToggler()}
      </h2>
      {loading && <LoadingScreen />}
      {renderLoyaltyProgramModeCard()}
      {mode === LoyaltyProgramMode.reward && (
        <RewardLoyaltyProgram
          loyaltyProgram={loyaltyProgram}
          rewards={rewards}
          setRewards={setRewards}
          loadingRewards={loadingRewards}
          key={loyaltyProgram?.id}
        />
      )}
      {mode === LoyaltyProgramMode.cashback && (
        <CashbackLoyaltyProgram
          cashbackProgram={cashbackProgram}
          setCashbackProgram={setCashbackProgram}
          convertExistingLoyaltyProgram={convertExistingLoyaltyProgram}
          conversionMultiplier={conversionMultiplier}
          setShowModeCard={setShowModeCard}
          key={cashbackProgram?.id}
        />
      )}
    </>
  );
}
