import React, { useMemo, useState } from 'react';
import {
  Button, Checkbox, Drawer, Flex,
} from 'antd';
import { useTranslation } from 'react-i18next';
import useApiClient from '../../hooks/api/useApiClient';
import { OperationalStatusErrors } from '../../services/exports/Constants';
import LiveOrderErrorRow from './LiveOrderErrorRow';
import './styles.css';

export default function LiveOrdersDrawer({
  show = false,
  toggleDrawer,
  company,
  onChange,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Modals:OperationalStatus' });
  const { CompaniesManager } = useApiClient();
  const [skippedChecks, setSkippedChecks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [confirmation, setConfirmation] = useState(false);

  const save = async () => {
    setLoading(true);
    const response = await CompaniesManager.updateOperationalStatus({
      has_live_orders: !company.has_live_orders,
      skipped_checks: skippedChecks,
    });
    setLoading(false);

    if (response.status === 400) {
      const { error_code, data } = response.data;

      switch (error_code) {
        case 'operational_status_check_failed':
          return operationalStatusCheckFailedHandler(data);
        default:
      }
    }

    onChange('has_live_orders', response.data.has_live_orders);
    toggleDrawer();
  };

  const operationalStatusCheckFailedHandler = (data) => {
    setErrors(getErrorsList.filter((item) => item.key in data));
  };

  const getErrorsList = useMemo(() => OperationalStatusErrors.map((error) => ({
    ...error,
    title: t(`errors.${error.key}.title`),
    description: t(`errors.${error.key}.description`),
  })), [t]);

  const confirmationCheckboxHandler = (e) => {
    setConfirmation(e.target.checked);
  };

  return (
    <Drawer
      title={company?.has_live_orders
        ? t('titles.disable')
        : t('titles.enable')}
      onClose={toggleDrawer}
      open={show}
      size="large"
    >
      {errors.length > 0 && (
      <>
        <div className="mb-3">{t('labels.successful_launch')}</div>
        {errors.map((error) => (
          <LiveOrderErrorRow
            error={error}
            key={error.key}
            setSkippedChecks={setSkippedChecks}
          />
        ))}
        <div className="mb-3">{t('labels.check_errors')}</div>
        <Checkbox checked={confirmation} onChange={confirmationCheckboxHandler} className="black-border-checkbox mb-4">
          {t('labels.checkbox_confirmation')}
        </Checkbox>
      </>
      )}
      <Flex gap="small" justify="flex-end" wrap>
        {errors.length > 0 ? (
          <>
            <Button
              disabled={!(confirmation && skippedChecks.length === errors.length)}
              loading={loading}
              onClick={save}
              type="primary"
            >
              {t('buttons.confirm_and_activate')}
            </Button>
            <Button onClick={toggleDrawer} type="primary" danger>
              {t('buttons.cancel')}
            </Button>
          </>
        ) : (
          <>
            <Button loading={loading} onClick={save} type="primary">
              {t('buttons.yes')}
            </Button>
            <Button onClick={toggleDrawer} type="primary" danger>
              {t('buttons.no')}
            </Button>
          </>
        )}
      </Flex>
    </Drawer>
  );
}
