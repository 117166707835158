import * as React from 'react';

export default function CaretUpIcon({
  width, height, color, className,
}) {
  return (
    <svg
      className={className}
      width={width || '20'}
      height={height || '12'}
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0836 12C18.9379 12 19.3989 10.9979 18.8428 10.3492L10.7314 0.8858C10.3323 0.420188 9.61194 0.420188 9.21284 0.885799L1.10135 10.3492C0.545342 10.9979 1.00625 12 1.8606 12L18.0836 12Z"
        fill={color || '#69CE8C'}
      />
    </svg>
  );
}
