import React from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CountryDropdown } from 'react-country-region-selector';
import {
  Button, Flex, Form, Input,
} from 'antd';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import useForm from '../../hooks/utility/useForm';
import useBusinessProfile from '../../hooks/context/useBusinessProfile';

export default function BankInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BankInfoStep' });
  const { buttonText, moveBackwards, moveForward } = props;

  const { business_profile } = useSelector((store) => store.currentCompany.company);

  const { updating, pushUpdates } = useBusinessProfile();
  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const { data, update } = useForm({
    bank_account_number: business_profile.bank_account_number,
    bank_routing_number: business_profile.bank_routing_number,
    bank_country: business_profile.bank_country ?? business_profile.business_country,
    bank_statement_descriptor: business_profile.bank_statement_descriptor,
  });

  const save = () => pushUpdates({
    data,
    onSuccess: () => moveForward && moveForward(),
    onError: ({ data }) => setErrorFeedback(data),
  });

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item label={t('form.bank_account_number.label')} required>
          <Input
            placeholder={t('form.bank_account_number.placeholder')}
            value={data.bank_account_number ?? ''}
            onChange={(e) => update('bank_account_number', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.bank_routing_number.label')}>
          <Input
            placeholder={t('form.bank_routing_number.placeholder')}
            value={data.bank_routing_number ?? ''}
            onChange={(e) => update('bank_routing_number', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.bank_country.label')} required>
          <CountryDropdown
            value={data.bank_country}
            valueType="short"
            onChange={(value) => update('bank_country', value)}
            classes="form-control"
            defaultOptionLabel={t('form.bank_country.placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('form.bank_statement_descriptor.label')}
          tooltip={t('form.bank_statement_descriptor.tooltip')}
          required
        >
          <Input
            placeholder={t('form.bank_statement_descriptor.placeholder')}
            value={data.bank_statement_descriptor ?? ''}
            onChange={(e) => update('bank_statement_descriptor', e.target.value)}
          />
        </Form.Item>
      </Form>
      <Flex justify="end">
        <Button
          onClick={moveBackwards}
          className="tw-mr-small"
        >
          {t('buttons.back')}
        </Button>
        <Button
          type="primary"
          onClick={save}
          loading={updating}
        >
          {buttonText ?? t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
