import React, { createContext } from 'react';
import { ThemeProvider as ThemeProviderWrapper } from 'styled-components';
import Theme from '../styles/Theme';
import GlobalStyles from '../styles/ThemeConfig';

export const ThemeContext = createContext(Theme);

function ThemeProvider({ children }) {
  return (
    <ThemeProviderWrapper theme={Theme}>
      <ThemeContext.Provider value={Theme}>
        <>
          <GlobalStyles theme={Theme} />
          {children}
        </>
      </ThemeContext.Provider>
    </ThemeProviderWrapper>
  );
}

export default ThemeProvider;
