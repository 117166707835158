import React from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, Divider, Flex, Form, Input, InputNumber, Switch,
} from 'antd';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import useForm from '../../hooks/utility/useForm';
import useBusinessProfile from '../../hooks/context/useBusinessProfile';

export default function TaxInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:TaxInfoStep' });

  const {
    buttonText,
    isSkippable,
    moveBackwards,
    moveForward,
    skip,
    skipping,
  } = props;

  const { company } = useSelector((state) => state.currentCompany);
  const { business_profile } = company;

  const { updating, pushUpdates } = useBusinessProfile();
  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const { data, update } = useForm({
    tax_id: business_profile.tax_id,
    vat_id: business_profile.vat_id,
    has_vat: Boolean(business_profile.vat_id),
    tax_percentage: company.tax_percentage ?? 20,
    has_dedicated_products_tax: company.has_dedicated_products_tax ?? false,
  });

  const save = () => pushUpdates({
    data: { ...data, vat_id: data.has_vat ? data.vat_id : null },
    onSuccess: () => moveForward && moveForward(),
    onError: ({ data }) => setErrorFeedback(data),
  });

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item label={t('form.tax_id.label')} required>
          <Input
            placeholder={t('form.tax_id.placeholder')}
            value={data.tax_id ?? ''}
            onChange={(e) => update('tax_id', e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Flex>
            <Switch
              checked={data.has_vat}
              onChange={(checked) => update('has_vat', checked)}
              id="has_vat"
            />
            <label
              htmlFor="has_vat"
              className="tw-ml-mini tw-my-auto"
            >
              {t('form.has_vat.label')}
            </label>
          </Flex>
        </Form.Item>
        {data.has_vat && (
          <Form.Item
            label={t('form.vat_id.label')}
            tooltip={t('form.vat_id.tooltip')}
            required
          >
            <Input
              placeholder={t('form.vat_id.placeholder')}
              value={data.vat_id ?? ''}
              onChange={(e) => update('vat_id', e.target.value)}
            />
          </Form.Item>
        )}
        <Divider />
        <Form.Item label={t('form.tax_percentage.label')} required>
          <InputNumber
            placeholder={t('form.tax_percentage.placeholder')}
            value={data.tax_percentage ?? ''}
            onChange={(value) => update('tax_percentage', value)}
            min={0}
            max={100}
          />
        </Form.Item>
        <Form.Item>
          <Flex>
            <Switch
              checked={data.has_dedicated_products_tax}
              onChange={(checked) => update('has_dedicated_products_tax', checked)}
              id="has_dedicated_products_tax"
            />
            <label
              htmlFor="has_dedicated_products_tax"
              className="tw-ml-mini tw-my-auto"
            >
              {t('form.has_dedicated_products_tax.label')}
            </label>
          </Flex>
        </Form.Item>
      </Form>
      <Flex justify="space-between">
        {isSkippable ? (
          <Button
            color="default"
            variant="solid"
            onClick={skip}
            loading={skipping}
          >
            {t('buttons.skip')}
          </Button>
        ) : <span />}
        <Flex justify="end">
          {moveBackwards && (
            <Button
              onClick={moveBackwards}
              className="tw-mr-small"
            >
              {t('buttons.back')}
            </Button>
          )}
          <Button
            type="primary"
            onClick={save}
            loading={updating}
          >
            {buttonText ?? t('buttons.next')}
          </Button>
        </Flex>
      </Flex>
    </CFade>
  );
}
