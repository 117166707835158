export default {
  reservations: 'Reservierungen',
  activated: 'Aktiviert',
  deactivated: 'Deaktiviert',
  reservations_deactivated: 'Reservierungen sind deaktiviert.',
  tabs: {
    general: 'Allgemein',
    information: 'Informationen',
  },
  google: {
    label: 'Reservierungen auf Google anzeigen',
  },
  storefront: {
    label: 'Reservierungen auf Storefront anzeigen',
  },
  auto_confirm: {
    label: 'Reservierungen automatisch bestätigen',
  },
  email: {
    label: 'E-Mail für eingehende Reservierungen',
    add: 'Email Hinzufügen',
    placeholder: 'Email eingeben',
  },
  has_whatsapp_phone_number: {
    label: 'Benachrichtigungen über WhatsApp senden',
  },
  whatsapp_phone_number: {
    label: 'WhatsApp-Nummer',
    add: 'WhatsApp-Nummer Hinzufügen',
  },
  guest_limit: {
    label: 'Gastlimit für Reservierungen',
  },
  min_guests: {
    label: 'Minimum Gästeanzahl für Reservierungen',
  },
  time_slot: {
    label: 'Zeitfenster für Reservierungen in Minuten',
  },
  reservation_gap: {
    label: 'Letzte mögliche Reservierung vor Schließung in Minuten',
  },
  reservation_notice_period: {
    label: 'Frühestmögliche Buchungsfrist in Minuten',
  },
  opening_times: {
    label: 'Öffnungszeiten',
  },
  total_seats: {
    label: 'Gesamtanzahl der Sitze',
  },
  note: {
    label: 'Notiz',
  },
  grace_period: {
    label: 'Karenzzeit',
    placeholder: 'Karenzzeit eingeben',
  },
  reservation_duration: {
    label: 'Reservierungsdauer',
    minutes: '{{ count }} Minuten',
    hour_one: '{{ count }} Stunde',
    hour_other: '{{ count }} Stunden',
  },
  information: {
    rules: {
      label: 'Ihr Tisch wird reserviert für:',
      add: 'Hinzufügen',
    },
  },
  buttons: {
    save: 'Speichern',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
  },
  success_messages: {
    updated: 'Aktualisierung erfolgreich',
    saving_successful:
      'Aktualisierung erfolgreich. Bitte beachten Sie, dass Google bis zu 24 Stunden benötigen kann, um die Änderungen zu übernehmen.',
  },
  error_messages: {
    invalid_phone_number: 'Ungültige Telefonnummer',
    saving_unsuccessful:
      'Fehler beim Speichern der Änderungen. Bitte versuchen Sie es erneut.',
    enter_correct_email: 'Bitte gebe eine richtige Email ein.',
  },
};
