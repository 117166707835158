import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex, Form, InputNumber, Radio, Space, Switch,
} from 'antd';
import { useSelector } from 'react-redux';
import useHelpers from '../../hooks/context/useHelpers';
import usePermissions from '../../hooks/auth/usePermissions';

export default function PriceInfo(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Contract:PriceInfo' });

  const {
    data,
    disabled,
    onChange,
    children,
    className,
  } = props;

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { formatCurrency } = useHelpers();
  const { isReseller } = usePermissions();

  const priceOptions = [
    { label: formatCurrency(300), value: 300 },
    { label: t('form.price.options.0'), value: 0 },
  ];

  return (
    <div className={className}>
      <h5 className="tw-mb-small">
        {t('sections.price')}
        {data.netto_single_store_setup_fee > 0 && data.store_quantity > 0 && (
          <>
            {' - '}
            {t('labels.total', { amount: formatCurrency(data.netto_single_store_setup_fee * data.store_quantity) })}
          </>
        )}
      </h5>
      <Form layout="vertical" initialValues={data}>
        <Form.Item
          label={t('form.store_quantity.label')}
          name="store_quantity"
          required
          rules={[
            { required: true, message: t('form.store_quantity.validation.required') },
            {
              validator: (_, value) => (value >= 1
                ? Promise.resolve()
                : Promise.reject(new Error(t('form.store_quantity.validation.min', { value: 1 })))),
            },
          ]}
        >
          <InputNumber
            value={data.store_quantity}
            disabled={disabled}
            placeholder={t('form.store_quantity.placeholder')}
            onChange={(value) => onChange('store_quantity', value)}
          />
        </Form.Item>
        {serviceProvider?.is_master && (
          <Form.Item label={t('form.price.label')}>
            <Radio.Group
              value={data.netto_single_store_setup_fee}
              onChange={(e) => onChange('netto_single_store_setup_fee', e.target.value)}
            >
              <Space direction="vertical">
                {priceOptions.map((item, index) => (
                  <Radio
                    disabled={disabled}
                    value={item.value}
                    key={`setup-fee-price-option-${index}`}
                  >
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        {data.netto_single_store_setup_fee > 0 && (
          <Form.Item>
            <Flex>
              <Switch
                checked={data.has_trial}
                disabled={disabled}
                onChange={(value) => onChange('has_trial', value)}
                id="has_trial"
              />
              <label htmlFor="has_trial" className="tw-ml-mini tw-my-auto">
                {t('form.has_trial.label')}
              </label>
            </Flex>
          </Form.Item>
        )}
        {data.has_trial && data.netto_single_store_setup_fee > 0 && (
          <Form.Item
            label={t('form.trial_days.label')}
            name="trial_days"
            required
            rules={[
              { required: true, message: t('form.trial_days.validation.required') },
              {
                validator: (_, value) => (value >= 1
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('form.trial_days.validation.min', { value: 1 })))),
              },
              {
                validator: (_, value) => (isReseller || value <= 42
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('form.trial_days.validation.max', { value: 42 })))),
              },
            ]}
          >
            <InputNumber
              value={data.trial_days}
              disabled={disabled}
              placeholder={t('form.trial_days.placeholder')}
              onChange={(value) => onChange('trial_days', value)}
            />
          </Form.Item>
        )}
      </Form>
      {children}
    </div>
  );
}
