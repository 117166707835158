import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLabel } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { PROMO_CODE_DISCOUNT_TYPES } from '../../services/exports/Constants';
import CurrencyInput from '../form/CurrencyInput';
import PercentageInput from '../form/PercentageInput';

export default function PromoCodeComponent({
  discountType,
  data,
  update,
  remove,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:PromoCode:PromoCodeComponent' });

  const renderDiscount = () => {
    if (discountType === PROMO_CODE_DISCOUNT_TYPES.percentage) {
      return (
        <PercentageInput
          value={data.discount}
          onChange={(value) => update('discount', value)}
        />
      );
    }

    return (
      <CurrencyInput
        value={data.discount}
        onChange={(value) => update('discount', value)}
      />
    );
  };

  return (
    <div className={classnames('tw-relative border-t tw-py-small', className)}>
      <Button
        danger
        icon={<DeleteOutlined />}
        className="tw-absolute tw-top-0 tw-right-0 -tw-translate-y-1/2"
        onClick={remove}
      />
      <div className="tw-p-small">
        <div className="tw-flex">
          <CLabel className="my-auto tw-w-full">{t('fields.discount.label')}</CLabel>
          {renderDiscount()}
        </div>
        <div className="tw-mt-medium tw-flex">
          <CLabel className="my-auto tw-w-full">{t('fields.usage_threshold.label')}</CLabel>
          <CurrencyInput
            value={data.usage_threshold}
            onChange={(value) => update('usage_threshold', value)}
          />
        </div>
        {discountType === PROMO_CODE_DISCOUNT_TYPES.percentage && (
          <div className="tw-mt-medium tw-flex">
            <CLabel className="my-auto tw-w-full">{t('fields.max_discount.label')}</CLabel>
            <CurrencyInput
              value={data.max_discount}
              onChange={(value) => update('max_discount', value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
