import collect from 'collect.js';
import { Stringable } from './Stringable';

export default class AddressHelpers {
  static country;

  static setCountry(country) {
    AddressHelpers.country = country;

    return new AddressHelpers();
  }

  format(address) {
    return AddressHelpers.format(address);
  }

  static format({
    street_name, street_number, city, state, country, zip_code, note,
  }) {
    const address = {
      US: this.implode([this.implode([street_number, street_name], ' '), note, city, state, zip_code]),
    }[country ?? AddressHelpers.country]
      ?? this.implode([this.implode([street_name, street_number], ' '), this.implode([zip_code, city], ' ')]);

    return new Stringable(address).squish().toString();
  }

  static implode(fields, glue = ', ') {
    return collect(fields)
      .filter((item) => item && item.length > 0)
      .implode(glue)
      .trim();
  }
}
