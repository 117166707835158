import React, { createContext, useMemo, useState } from 'react';
import useScreenType from '../hooks/context/useScreenType';

export const AppStateContext = createContext(null);

export default function AppStateProvider({ children }) {
  const { isDesktop } = useScreenType();

  const [showSideBar, setShowSideBar] = useState(isDesktop);

  const context = useMemo(
    () => ({
      showSideBar,
      setShowSideBar,
    }),
    [showSideBar],
  );

  return (
    <AppStateContext.Provider value={context}>
      {children}
    </AppStateContext.Provider>
  );
}
