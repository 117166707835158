import { actionCreators } from '../../../store/actions';
import ReduxHooks from '../../../store/ReduxHooks';
import ApiResourceManager from '../ApiResourceManager';

export default class MerchantProfileManager extends ApiResourceManager {
  update = async (data) => {
    const request = await this.request({
      method: 'PUT',
      url: '/merchant/profile',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.updateMerchant(responseData));
    }

    return request;
  };

  updateNotificationPreferences = async (data) => {
    const request = await this.request({
      method: 'PUT',
      url: '/merchant/notification-preferences',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.updateMerchant(responseData));
    }

    return request;
  };

  skipVerificationStep = async (data) => {
    const request = await this.request({
      method: 'POST',
      url: '/merchant/skipped-verification-steps',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.set(responseData));
    }

    return request;
  };
}
