import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { collect } from 'collect.js';
import LoadingScreen from '../components/loading/LoadingScreen';
import useApiClient from '../hooks/api/useApiClient';
import useAuth from '../hooks/auth/useAuth';
import {
  COOKIES, RESERVATION_STATUS_TYPE, ROLES, SCREENS, WEBVIEW_COMMUNICATOR_COMMANDS,
} from '../services/exports/Constants';
import useAppCookies from '../hooks/utility/useAppCookies';
import ReduxHooks from '../store/ReduxHooks';
import { actionCreators } from '../store/actions';
import usePermissions from '../hooks/auth/usePermissions';
import WebViewCommunicator from '../services/helpers/WebViewCommunicator';

export const InitialDataContext = createContext(null);

export default function InitialDataProvider({ children }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Providers:InitialDataProvider' });

  const { setCookie, removeCookies } = useAppCookies();

  const { initialData } = useSelector((state) => state);
  const { company } = useSelector((state) => state.currentCompany);
  const { franchise } = useSelector((state) => state.currentFranchise);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { isLoggedIn, logOut } = useAuth();
  const { isRole } = usePermissions();
  const {
    InitialDataManager, FranchisesManager, CurrentCompanyManager, CompaniesManager,
  } = useApiClient();
  const { ReservationsManager } = useApiClient({ baseUrl: import.meta.env.VITE_RESERVATION_API_BASE_URL, autoLogOut: false });

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [loading, setLoading] = useState(isLoggedIn);
  const [preselectingCompany, setPreselectingCompany] = useState(false);

  useEffect(() => {
    isLoggedIn && loadInitialData();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!company?.id) {
      setLoading(true);
      setTimeout(() => setLoading(false), 300);
    }

    company?.id && initialDataLoaded && !preselectingCompany && loadCompanyData();
  }, [initialDataLoaded, preselectingCompany, company?.id]);

  useEffect(
    () => preselectCompany(),
    [serviceProvider?.id],
  );

  useEffect(() => {
    WebViewCommunicator.sendMessage({
      type: WEBVIEW_COMMUNICATOR_COMMANDS.REQUEST_ON_BEHALF_OF,
      payload: {
        company_id: company?.id,
        service_provider_id: serviceProvider?.id,
      },
    });
  }, [company?.id, serviceProvider?.id]);

  useEffect(() => {
    if (typeof company?.storefront_store_id === 'number' && isLoggedIn) {
      setCookie(COOKIES.ON_BEHALF_OF_STORE, company?.storefront_store_id, {
        expires: moment()
          .add(399, 'days')
          .toDate(),
      });
    } else {
      removeCookies([COOKIES.ON_BEHALF_OF_STORE]);
    }
  }, [company?.storefront_store_id, isLoggedIn]);

  useEffect(
    () => moment.tz.setDefault(company?.timezone ?? serviceProvider?.regional_config?.timezone),
    [company?.timezone, serviceProvider?.regional_config?.timezone],
  );

  const refresh = async () => {
    const response = await InitialDataManager.get();
    await loadReservations(response.data);
    await preselectFranchise(response.data);

    if (!response.success && response.status === 403) {
      toast.error(t('toasts.verification_pending'));

      return logOut();
    }

    return response;
  };

  const loadInitialData = async () => {
    await refresh();
    setInitialDataLoaded(true);

    if (!company?.id) {
      setLoading(false);
    }
  };

  const loadCompanyData = async () => {
    setLoading(true);
    await CurrentCompanyManager.get();
    await loadReservations(initialData);

    setTimeout(() => setLoading(false), 500);
  };

  const loadReservations = async (initialDataResponse) => {
    if (
      initialDataResponse.profile.roles !== null
      && !company.id
    ) {
      return;
    }

    const { success, data } = await ReservationsManager.getReservations({
      franchise_id: company.id ? company.franchise_id : franchise?.id,
      service_provider_id: serviceProvider?.id,
      status: [
        RESERVATION_STATUS_TYPE.PENDING,
        RESERVATION_STATUS_TYPE.UPDATE_PENDING,
      ],
    });

    success && ReduxHooks.dispatch(actionCreators.sideBar.setBadge({
      [SCREENS.RESERVATIONS_OVERVIEW]: data.total,
    }));
  };

  const preloadSameCompany = async () => {
    const { success } = await CompaniesManager.show(company?.id);

    if (!success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.reset());

      return false;
    }

    return true;
  };

  const preselectFranchise = async (initialDataResponse) => {
    if (
      initialDataResponse.profile.roles !== null
      || initialDataResponse.franchise_acl.length >= 2
    ) {
      return;
    }

    const { success, data } = await FranchisesManager.get();

    if (!success) {
      return toast.error('Failed to load data');
    }

    const franchise = collect(data.data).first();

    ReduxHooks.dispatch(
      actionCreators.currentFranchise.set(franchise),
    );

    if (franchise.companies.length < 2) {
      await CompaniesManager.show(collect(franchise.companies).first()?.id);
    }
  };

  const preselectCompany = async () => {
    if (!company?.id && !isRole(ROLES.content_manager)) {
      return;
    }

    setPreselectingCompany(true);

    if (company?.id && company?.service_provider_id === serviceProvider?.id && await preloadSameCompany()) {
      return setPreselectingCompany(false);
    }

    const { success, data } = await CompaniesManager.get({
      page: 1,
      limit: 1,
    });

    if (!success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.reset());

      return setPreselectingCompany(false);
    }

    const { success: detailsSuccess } = await CompaniesManager.show(data.data.id);

    if (!detailsSuccess) {
      ReduxHooks.dispatch(actionCreators.currentCompany.reset());
    }

    setPreselectingCompany(false);
  };

  const context = useMemo(
    () => ({
      data: initialData,
      refresh,
      loading,
      setLoading,
    }),
    [loading, initialData],
  );

  return (
    <InitialDataContext.Provider value={context}>
      {loading || preselectingCompany ? <LoadingScreen /> : children}
    </InitialDataContext.Provider>
  );
}
