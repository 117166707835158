export default {
  form: {
    bank_account_number: {
      label: 'Bankkonto (IBAN)',
      placeholder: 'DE01101234567891234567',
    },
    bank_routing_number: {
      label: 'Routing-Nummer',
      placeholder: '110000000',
    },
    bank_country: {
      label: 'Land',
      placeholder: 'Germany',
    },
    bank_statement_descriptor: {
      label: 'Überweisung Beschreibung',
      placeholder: 'Name des Restaurants',
      tooltip: 'Dieser Name wird auf den Kontoauszügen der Kunden angezeigt.',
    },
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
  },
};
