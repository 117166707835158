export default {
  title: 'Restaurant-Gruppen',
  form: {
    name: {
      placeholder: 'Suche Restaurant...',
    },
  },
  fields: {
    id: '#',
    name: 'Name',
    address: 'Adresse',
    subdomain: 'Subdomain',
    companies: 'Unternehmen',
    slug: 'Slug',
    actions: '',
  },
  empty_list: 'Noch keine Franchises',
  modals: {
    delete_franchise: {
      title: 'Sicher, dass du löschen willst {{name}} franchise?',
    },
  },
  buttons: {
    new_franchise: '+ Restaurant-Gruppe hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    view_dashboard_as_owner: 'Dashboard als Eigentümer anzeigen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_delete_franchise: 'Franchise konnte nicht gelöscht werden',
    franchise_got_deleted: 'Franchise wurde gelöscht',
    failed_to_create_magic_link: 'Magischer Link konnte nicht erstellt werden',
  },
};
