import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export default function PaymentInfo({ className }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Billing:PaymentInfo' });

  const { business_profile } = useSelector((store) => store.currentCompany.company);

  function Field({ label, value }) {
    return (
      <p>
        <b>
          {label}
          :
        </b>
        {' '}
        {value}
        <Tooltip
          placement="top"
          title={t('tooltip', { field: label })}
          trigger="click"
        >
          <Button
            icon={<CopyOutlined />}
            type="text"
            onClick={() => navigator.clipboard.writeText(value)}
          />
        </Tooltip>
      </p>
    );
  }

  return (
    <div className={className}>
      <h4>{t('title')}</h4>
      <Field label={t('labels.account_number')} value={business_profile.bank_account_number} />
      {business_profile.bank_routing_number ? (
        <Field label={t('labels.routing_number')} value={business_profile.bank_routing_number} />
      ) : null}

      <h5 className="tw-mt-small">{t('sections.personal_info')}</h5>
      <Field label={t('labels.email')} value={business_profile.owner_email} />
      <Field label={t('labels.full_name')} value={business_profile.owner_full_name} />

      <h5 className="tw-mt-small">{t('sections.address')}</h5>
      <Field label={t('labels.street')} value={business_profile.legal_address} />
      <Field label={t('labels.zip_code')} value={business_profile.legal_zip_code} />
      <Field label={t('labels.city')} value={business_profile.legal_city} />
    </div>
  );
}
