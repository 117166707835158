export default {
  form: {
    header: {
      label: 'Kopfzeile',
      placeholder: 'Kopfzeile',
    },
    sub_header: {
      label: 'Unter Kopfzeile',
      placeholder: 'Unter Kopfzeile',
    },
    description: {
      label: 'Beschreibung',
      placeholder: 'Beschreibung',
    },
    qr_code_header: {
      label: 'UI-Box-Kopfzeile',
      placeholder: 'UI-Box-Kopfzeile',
    },
    qr_code_description: {
      label: 'UI box Beschreibung',
      placeholder: 'UI box Beschreibung',
    },
    tag_text: {
      label: 'Tag-Text',
      placeholder: 'NEU!',
    },
    footer: {
      label: 'Text in der Fußzeile',
      placeholder: 'Fußzeilentext eingeben',
    },
    url_type: {
      label: 'URL',
      placeholder: 'URL auswählen',
      values: {
        custom: 'Benutzerdefiniert',
      },
    },
    url: {
      placeholder: 'www.example.com',
    },
    primary_color: {
      label: 'Primäre Farbe',
    },
    background_color: {
      label: 'Hintergrundfarbe',
    },
    logo: {
      label: 'Logo',
    },
    logo_scale: {
      label: 'Logo Skalierung',
      placeholder: 'Logo Skalierung eingeben',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    changes_got_saved: 'Änderungen wurden gespeichert',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
  loyalty_points: 'LOYALTY PUNKTE',
  special_deals: 'EXKLUSIVE DEALS',
  save_time: 'SPARE ZEIT',
  scan_qrcode: 'QR-Code scannen oder Seite besuchen',
  enter_code: 'Code am Ende des Bestellvorgangs eingeben',
};
