export default {
  form: {
    owner_phone_number: {
      label: 'Phone number of contact person',
      placeholder: '1785080120',
    },
    owner_email: {
      label: 'Email of contact person',
      placeholder: 'Enter Email',
    },
    owner_first_name: {
      label: 'First Name',
      placeholder: 'Enter first name',
    },
    owner_last_name: {
      label: 'Last Name',
      placeholder: 'Enter last name',
    },
    owner_dob: {
      label: 'Date of birth',
      placeholder: 'Date',
    },
    owner_id_number: {
      label: 'ID number (e.g. Social security number in US)',
      placeholder: '123456789',
    },
  },
  buttons: {
    back: 'Back',
    next: 'Next',
  },
};
