import React, { useEffect } from 'react';
import {
  Button, Flex, Form, Switch, Tag,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { useTranslation } from 'react-i18next';
import useHelpers from '../../hooks/context/useHelpers';
import CurrencyInput from '../form/CurrencyInput';
import { TAX_BEHAVIOUR } from '../../services/exports/Constants';

export default function PriceEditor({
  data,
  update,
  selectedPrice,
  disableTiers,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Subscription:PriceEditor' });

  const { formatCurrency } = useHelpers();

  const addTier = (item) => update('tiers', [...data.tiers, item ?? {}]);
  const removeTier = (index) => update('tiers', collect(data.tiers).forget(index).toArray());
  const updateTier = (index, field, value) => update(
    'tiers',
    collect(data.tiers)
      .put(index, {
        ...data.tiers[index],
        [field]: value,
      })
      .toArray(),
  );

  useEffect(() => {
    if (!data.has_pricing_tiers) {
      return;
    }

    data.tiers.length === 0 && update('tiers', [
      {
        threshold: 0,
        amount: 35,
      },
      {
        threshold: 700,
        amount: data.amount,
      },
    ]);

    data.tiers.length !== 0 && updateTier(data.tiers.length - 1, 'amount', data.amount);
  }, [data.has_pricing_tiers, data.amount]);

  return (
    <div className={className}>
      <Form layout="vertical" initialValues={data}>
        <Form.Item
          required
          name="amount"
          label={t('form.amount.label')}
          rules={[
            { required: true, message: t('form.amount.validation.required') },
            {
              validator: (_, value) => (value >= 0
                ? Promise.resolve()
                : Promise.reject(new Error(t('form.amount.validation.min', { value: 0 })))),
            },
          ]}
        >
          <CurrencyInput
            value={data.amount}
            onChange={(value) => update('amount', value)}
            disabled={selectedPrice}
            className="!tw-w-fit"
          />
        </Form.Item>
      </Form>
      <Form.Item>
        <Flex>
          <Switch
            checked={data.tax_behaviour === TAX_BEHAVIOUR.inclusive}
            disabled={selectedPrice}
            onChange={(value) => update('tax_behaviour', value ? TAX_BEHAVIOUR.inclusive : TAX_BEHAVIOUR.exclusive)}
            id="tax_included"
          />
          <label htmlFor="tax_included" className="tw-ml-mini">
            {t('form.tax_included.label')}
          </label>
        </Flex>
      </Form.Item>
      {!disableTiers && (
        <>
          <Form.Item>
            <Flex>
              <Switch
                checked={data.has_pricing_tiers}
                disabled={selectedPrice}
                onChange={(value) => update('has_pricing_tiers', value)}
                id="has_pricing_tiers"
              />
              <label htmlFor="has_pricing_tiers" className="tw-ml-mini">
                {t('form.has_pricing_tiers.label')}
              </label>
            </Flex>
          </Form.Item>
          {data.has_pricing_tiers && (
            <div>
              <hr className="tw-my-small" />
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <h6 className="mb-0">{t('form.threshold.label')}</h6>
                <h6 className="mb-0">{t('form.amount.label')}</h6>
                {data.tiers.map((item, index) => (
                  <React.Fragment key={`pricing-tier-${index}`}>
                    <div className="tw-flex">
                      {index === data.tiers.length - 1 && (
                        <Tag className="tw-mr-mini my-auto" color="success">{'>='}</Tag>
                      )}
                      <CurrencyInput
                        id={`tier-${index}-threshold`}
                        value={item.threshold ?? ''}
                        disabled={index === 0}
                        onChange={(value) => updateTier(index, 'threshold', value)}
                      />
                      {index !== data.tiers.length - 1 && (
                        <Tag
                          className="tw-ml-mini tw-mr-0 tw-my-auto tw-text-mini"
                          color={index !== 0 ? 'warning' : 'red'}
                        >
                          {` - ${formatCurrency(data.tiers[index + 1].threshold)}`}
                        </Tag>
                      )}
                    </div>
                    <div className="tw-flex">
                      <CurrencyInput
                        id={`tier-${index}-amount`}
                        value={item.amount ?? ''}
                        disabled={index === data.tiers.length - 1}
                        onChange={(value) => updateTier(index, 'amount', value)}
                      />
                      <Button
                        danger
                        disabled={index === 0 || index === data.tiers.length - 1}
                        onClick={() => removeTier(index)}
                        className="tw-ml-mini tw-my-auto tw-aspect-square tw-flex p-0"
                      >
                        <DeleteOutlined className="tw-m-auto" />
                      </Button>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className="tw-mt-mini tw-flex tw-justify-end">
                <Button onClick={() => addTier(data.tiers[data.tiers.length - 1])}>
                  {t('buttons.add_tier')}
                </Button>
              </div>
              <hr className="tw-my-small" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
