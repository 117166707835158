export default {
  form: {
    owner_phone_number: {
      label: 'Telefonnummer Kontaktperson',
      placeholder: '1785080120',
    },
    owner_email: {
      label: 'E-mail Kontaktperson',
      placeholder: 'E-Mail eingeben',
    },
    owner_first_name: {
      label: 'Vorname',
      placeholder: 'Vornamen eingeben',
    },
    owner_last_name: {
      label: 'Nachname',
      placeholder: 'Nachname eingeben',
    },
    owner_dob: {
      label: 'Geburtsdatum',
      placeholder: 'Datum',
    },
    owner_id_number: {
      label: 'ID-Nummer (z. B. Sozialversicherungsnummer in den USA)',
      placeholder: '123456789',
    },
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
  },
};
