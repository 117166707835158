import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'activeTab',
  initialState: {
    paths: {},
  },
  reducers: {
    set: (state, action) => {
      const { path, tab } = action.payload;

      state.paths = {
        ...(state.paths ?? {}),
        [path]: tab,
      };
    },
  },
});

export const activeTab = (state) => state;
