export default {
  sections: {
    address: 'Adresse',
    legal_address: 'Adresse der Anmeldung',
    invoice_address: 'Adresse der Rechnung',
  },
  form: {
    display_name: {
      label: 'Freundlicher Name',
      placeholder: 'Freundlichen Firmennamen eingeben',
    },
    legal_name: {
      label: 'Rechtlicher Name',
      placeholder: 'Rechtlicher Name eingeben',
      tooltip: 'Name der Registrierung. Wird in Auszahlungsberichten, Verträgen usw. angezeigt',
    },
    timezone: {
      label: 'Zeitzone',
    },
    has_dedicated_invoice_address: {
      label: 'Abweichende Rechnunginfo',
    },
  },
  buttons: {
    next: 'Weiter',
  },
};
