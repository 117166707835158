import React, { createContext, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import HelperMethods from '../services/exports/HelperMethods';
import ReduxHooks from '../store/ReduxHooks';
import { actionCreators } from '../store/actions';

export const HelpersContext = createContext(null);

export default function HelpersProvider({ children }) {
  const { company } = useSelector((store) => store.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { paths } = useSelector((store) => store.activeTab);

  dayjs.extend(dayjsUtcPlugin);

  const formatCurrency = useCallback(
    (amount, currency = null, locale = null) => HelperMethods.formatCurrency(
      amount,
      currency ?? company?.currency ?? serviceProvider?.currency,
      locale,
    ),
    [company?.currency, serviceProvider?.currency],
  );

  const formatDate = useCallback(
    (datetime, format = undefined) => {
      const country = company?.country ?? serviceProvider?.country;
      const _format = format ?? (country === 'US' ? 'MM-DD-YYYY' : 'DD.MM.YYYY');

      return moment(datetime).format(_format);
    },
    [company?.country, serviceProvider?.country],
  );

  const formatTime = useCallback(
    (datetime, format = undefined) => {
      const country = company?.country ?? serviceProvider?.country;
      const _format = format ?? (country === 'US' ? 'h:mm a' : 'HH:mm');

      return moment(datetime).format(_format);
    },
    [company?.country, serviceProvider?.country],
  );

  const formatDateTime = useCallback(
    (datetime, {
      format = undefined,
      dateFormat = undefined,
      timeFormat = undefined,
    } = {}) => {
      const country = company?.country ?? serviceProvider?.country;
      const _dateFormat = dateFormat ?? (country === 'US' ? 'MM-DD-YYYY' : 'DD.MM.YYYY');
      const _timeFormat = timeFormat ?? (country === 'US' ? 'h:mm a' : 'HH:mm');
      const _format = format ?? `${_dateFormat} ${_timeFormat}`.trim();

      return moment(datetime).format(_format);
    },
    [company?.country, serviceProvider?.country],
  );

  const getActiveTab = useCallback(
    (path, defaultTab) => {
      try {
        return paths[path ?? window.location.pathname] ?? defaultTab;
      } catch {
        return defaultTab;
      }
    },
    [paths],
  );

  const setActiveTab = useCallback(
    (tab, path) => ReduxHooks.dispatch(actionCreators.activeTab.set({ path: path ?? window.location.pathname, tab })),
    [paths],
  );

  const context = useMemo(
    () => ({
      formatCurrency,
      formatDate,
      formatTime,
      formatDateTime,
      getActiveTab,
      setActiveTab,
    }),
    [formatCurrency, getActiveTab, getActiveTab],
  );

  return (
    <HelpersContext.Provider value={context}>
      {children}
    </HelpersContext.Provider>
  );
}
