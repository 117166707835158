import ApiResourceManager from '../ApiResourceManager';

export default class ManagerProfilesManager extends ApiResourceManager {
  update = (id, data) => this.request({
    method: 'PUT',
    url: '/managers',
    data: {
      id,
      ...data,
    },
  });

  getAccessControlList = async () => this.request({
    method: 'GET',
    url: '/managers/company-acl',
  });

  updateAccessControlList = async (data) => this.request({
    method: 'PUT',
    url: '/managers/company-acl',
    data,
  });
}
