import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import LoadingScreen from '../loading/LoadingScreen';
import useApiClient from '../../hooks/api/useApiClient';
import HelperMethods from '../../services/exports/HelperMethods';
import Metric from '../stats/Metric';
import MetricsContainer from '../stats/MetricsContainer';
import MathHelpers from '../../services/helpers/MathHelpers';

const requiredMetrics = [
  'delivery_quotes_count',
  'deliveries_count',
  'delivery_conversion',
  'delivery_quotes_provider_breakdown',
  'deliveries_provider_breakdown',
];

export default function ExternalDeliveryTab({ dateRangeFilter }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Dashboard:Dashboard' });

  const { StatsManager } = useApiClient();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const percentageFormatter = (value) => HelperMethods.formatPercentage(value);

  useEffect(() => {
    requestStatistics();
  }, [serviceProvider?.id, dateRangeFilter]);

  async function requestStatistics() {
    setLoading(true);

    const { data: responseData } = await StatsManager.getStats({
      metrics: requiredMetrics,
      ...dateRangeFilter,
    });

    setLoading(false);

    setData(responseData);
  }

  const metrics = data ? [
    <Metric
      key={t('metrics.delivery_conversion')}
      name={t('metrics.delivery_conversion')}
      items={[
        {
          name: t('metrics.delivery_quotes_count'),
          ...data.delivery_quotes_count,
        },
        {
          name: t('metrics.deliveries_count'),
          ...data.deliveries_count,
        },
        {
          name: t('metrics.delivery_conversion'),
          formatter: percentageFormatter,
          ...data.delivery_conversion,
        },
      ]}
    />,
    <Metric
      key={t('metrics.delivery_quotes_provider_breakdown')}
      name={t('metrics.delivery_quotes_provider_breakdown')}
      items={
        collect(data.delivery_quotes_provider_breakdown?.value).map((item) => {
          const previous = collect(data.delivery_quotes_provider_breakdown.previous).firstWhere('provider', item.provider)?.value ?? 0;

          return {
            name: t(`delivery_providers.${item.provider}`, { defaultValue: item.provider }),
            value: `${item.value} - ${MathHelpers.percentagePart(item.value, data.delivery_quotes_count.value)}%`,
            previous: previous === 0 ? 0 : `${previous} - ${MathHelpers.percentagePart(previous, data.delivery_quotes_count.previous)}%`,
            change: MathHelpers.percentageDiff(item.value, previous),
          };
        }).toArray()
      }
    />,
    <Metric
      key={t('metrics.deliveries_provider_breakdown')}
      name={t('metrics.deliveries_provider_breakdown')}
      items={
        collect(data.deliveries_provider_breakdown?.value).map((item) => {
          const previous = collect(data.deliveries_provider_breakdown.previous).firstWhere('provider', item.provider)?.value ?? 0;

          return {
            name: t(`delivery_providers.${item.provider}`, { defaultValue: item.provider }),
            value: `${item.value} - ${MathHelpers.percentagePart(item.value, data.deliveries_count.value)}%`,
            previous: previous === 0 ? 0 : `${previous} - ${MathHelpers.percentagePart(previous, data.deliveries_count.previous)}%`,
            change: MathHelpers.percentageDiff(item.value, previous),
          };
        }).toArray()
      }
    />,
  ] : [];

  return loading ? <LoadingScreen /> : <MetricsContainer metrics={metrics} />;
}
