export default {
  form: {
    tax_id: {
      label: 'Steuernummer',
      placeholder: 'Steuernummer eingeben',
    },
    has_vat: {
      label: 'Was ist die Umsatzsteuer Identifikationsnummer (USt-ID)?',
    },
    vat_id: {
      label: 'USt. Identifikationsnummer (VAT)',
      placeholder: 'USt. Identifikationsnummer (VAT)',
      tooltip: 'Die Umsatzsteuer Identifikationsnummer (USt-ID) ist eine eigenständige Nummer, die Unternehmer zusätzlich zur ihrer Steuernummer oder Steuer-ID beantragen können.',
    },
    tax_percentage: {
      label: 'Steuerprozentsatz',
      placeholder: 'Steuerprozentsatz',
    },
    has_dedicated_products_tax: {
      label: 'Der Steuerprozentsatz variiert je nach Produkt',
    },
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
    skip: 'Überspringen',
  },
};
