import React from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, Flex, Form, Input, Switch,
} from 'antd';
import classnames from 'classnames';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import useForm from '../../hooks/utility/useForm';
import useBusinessProfile from '../../hooks/context/useBusinessProfile';
import AddressFields from '../form/AddressFields';

export default function BusinessInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BusinessInfoStep' });
  const { buttonText, moveForward } = props;

  const { company } = useSelector((store) => store.currentCompany);
  const { business_profile } = company;

  const { updating, pushUpdates } = useBusinessProfile();
  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const { data, update } = useForm({
    display_name: business_profile.display_name,
    legal_name: business_profile.legal_name,
    legal_country: business_profile.legal_country,
    legal_state: business_profile.legal_state,
    legal_city: business_profile.legal_city,
    legal_address: business_profile.legal_address,
    legal_zip_code: business_profile.legal_zip_code,

    has_dedicated_invoice_address: business_profile.has_dedicated_invoice_address,
    invoice_country: business_profile.invoice_country,
    invoice_state: business_profile.invoice_state,
    invoice_city: business_profile.invoice_city,
    invoice_address: business_profile.invoice_address,
    invoice_zip_code: business_profile.invoice_zip_code,
  });

  const save = () => pushUpdates({
    data,
    onSuccess: () => moveForward && moveForward(),
    onError: ({ data }) => setErrorFeedback(data),
  });

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item
          label={t('form.display_name.label')}
          required
        >
          <Input
            placeholder={t('form.display_name.placeholder')}
            value={data.display_name ?? ''}
            onChange={(e) => update('display_name', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t('form.legal_name.label')}
          tooltip={t('form.legal_name.tooltip')}
          required
        >
          <Input
            placeholder={t('form.legal_name.placeholder')}
            value={data.legal_name ?? ''}
            onChange={(e) => update('legal_name', e.target.value)}
          />
        </Form.Item>
        <hr />
        <Flex justify="space-between" gap={16}>
          <h6 className="tw-my-auto">{t('sections.address')}</h6>
          <Flex>
            <label htmlFor="has_dedicated_invoice_address" className="tw-mr-small tw-my-auto">
              {t('form.has_dedicated_invoice_address.label')}
            </label>
            <Switch
              checked={data.has_dedicated_invoice_address}
              onChange={(checked) => update('has_dedicated_invoice_address', checked)}
              className="tw-my-auto"
              id="has_dedicated_invoice_address"
            />
          </Flex>
        </Flex>
        <div className="tw-grid tw-grid-cols-2 md:tw-gap-10 tw-relative">
          <div className={classnames({ 'tw-col-span-2 tw-mt-small': !data.has_dedicated_invoice_address })}>
            <AddressFields
              title={data.has_dedicated_invoice_address ? t('sections.legal_address') : null}
              prefix="legal"
              data={data}
              onChange={update}
              useGrid={!data.has_dedicated_invoice_address}
            />
          </div>
          {data.has_dedicated_invoice_address && (
            <>
              <div className="md:tw-block tw-hidden tw-absolute tw-h-[90%] tw-border-[1px] tw-border-r-none tw-border-[#0E0E0E33] tw-border-solid tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
              <AddressFields
                title={t('sections.invoice_address')}
                prefix="invoice"
                data={data}
                onChange={update}
                useGrid={false}
              />
            </>
          )}
        </div>
      </Form>
      <Flex justify="end">
        <Button
          type="primary"
          onClick={save}
          loading={updating}
        >
          {buttonText ?? t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
