import { tabs } from '../../../../../../views/marketing/flyers';

export default {
  tabs: {
    [tabs.edit]: 'Flugblatt bearbeiten/vorschauen',
    [tabs.order]: 'Flugblätter bestellen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
