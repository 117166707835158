export default {
  title: 'Neue Kategorie',
  form: {
    menus: {
      label: 'Menüs',
    },
    printer: {
      label: 'Drucker',
    },
    type: {
      label: 'Typ',
    },
    name: {
      label: 'Name',
      placeholder: 'Kategorie Name',
    },
    description: {
      label: 'Beschreibung',
      placeholder: 'Beschreibung eingeben',
    },
    tax_percentage: {
      label: 'Mehrwertsteuer %',
    },
    has_combo_tax: {
      label: 'Hat Combo-Steuer',
    },
    modifier_groups: {
      label: 'Modifizierergruppe',
    },
    image: {
      label: 'Bild',
    },
    identifiers: {
      label: 'Kennzeichnungen',
    },
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  feedback: {
    missing_info: 'Fehlende Informationen',
  },
  toasts: {
    category_got_created: 'Kategorie wurde erstellt',
    category_got_updated: 'Kategorie wurde aktualisiert',
  },
};
