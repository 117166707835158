import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import ManagersTab from './ManagersTab';
import AccessControlListTab from './AccessControlListTab';

export const managers_tab = 'managers';
export const access_control_list_tab = 'access_control_list';

export default function ManagersView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Team:Managers:ManagersView' });

  const items = [
    {
      key: managers_tab,
      label: t(`tabs.${managers_tab}`),
      children: <ManagersTab />,
    },
    {
      key: access_control_list_tab,
      label: t(`tabs.${access_control_list_tab}`),
      children: <AccessControlListTab />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey={managers_tab}
      items={items}
    />
  );
}
