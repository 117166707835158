export default {
  title: 'New category',
  form: {
    menus: {
      label: 'Menus',
    },
    printer: {
      label: 'Printer',
    },
    type: {
      label: 'Type',
    },
    name: {
      label: 'Name',
      placeholder: 'Enter category name',
    },
    description: {
      label: 'Description',
      placeholder: 'Enter description',
    },
    tax_percentage: {
      label: 'Sales tax %',
    },
    has_combo_tax: {
      label: 'Has combo tax',
    },
    modifier_groups: {
      label: 'Modifier groups',
    },
    image: {
      label: 'Image',
    },
    identifiers: {
      label: 'Identifiers',
    },
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  feedback: {
    missing_info: 'Missing information',
  },
  toasts: {
    category_got_created: 'Category got created',
    category_got_updated: 'Category got updated',
  },
};
