export default {
  tabs: {
    access: 'Access',
    notification_preferences: 'Notification preferences',
  },
  sections: {
    notification_preferences: {
      title: 'Notification preferences',
      form: {
        has_voice_call_reminders: {
          label: 'Receive automated calls about pending orders',
        },
        voice_reminders_phone_number: {
          label: 'Phone number to receive calls',
        },
        has_whatsapp_notifications: {
          label: 'Send notifications (e.g. orders paused) via WhatsApp',
        },
        whatsapp_phone_number: {
          label: 'WhatsApp phone number',
        },
      },
    },
    voice_calls: {
      title: 'Voice calls',
    },
    whats_app_notifications: {
      title: 'WhatsApp notifications',
    },
    access_control: {
      title: 'Access control',
      form: {
        company_ids: {
          label: 'Can access restaurants',
          tooltip: 'Provides access to following restaurants (additionally to restaurants that are in the same franchise)',
        },
      },
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
