export default {
  form: {
    document_type: {
      label: 'Proof of identity document',
      placeholder: 'Select document type',
      options: {
        passport: 'Passport',
        driver_license: 'Driver\'s license',
        id_card: 'Identity card',
        residence_permit: 'Residence permit',
      },
    },
    front: {
      label: 'Document front',
    },
    back: {
      label: 'Document back (optional for some documents)',
    },
  },
  buttons: {
    select_file: 'Select file',
    next: 'Next',
    back: 'Back',
  },
};
