import { ROLES } from '../../../../../../services/exports/Constants';

export default {
  form: {
    title: 'Add manager',
    first_name: {
      label: 'First name',
      placeholder: 'Walter',
    },
    last_name: {
      label: 'Last name',
      placeholder: 'White',
    },
    city: {
      label: 'City',
      placeholder: 'Bonn',
    },
    email: {
      label: 'Email',
      placeholder: 'thedanger@gmail.com',
    },
    roles: {
      label: 'Role',
      placeholder: 'Select role',
      options: {
        [ROLES.reseller]: 'Reseller admin',
        [ROLES.sales_manager]: 'Sales manager',
        [ROLES.content_manager]: 'Content manager',
        [ROLES.onboarding_manager]: 'Onboarding manager',
      },
    },
  },
  fields: {
    email: 'Email',
    name: 'Name',
    roles: 'Role',
    created_at: 'Created',
    actions: 'Actions',
  },
  buttons: {
    add_manager: 'Add manager',
    actions: 'Actions',
    view_dashboard: 'View dashboard as this manager',
    delete: 'Delete this manager',
  },
  modals: {
    delete_manager: {
      title: 'Are you sure to delete {{email}} manager?',
    },
  },
  toasts: {
    failed_to_load_data: 'Failed to load data, please refresh the page',
    failed_to_create_manager: 'Failed to create manager',
    failed_to_create_magic_link: 'Failed to create magic link',
    failed_to_delete_manager: 'Failed to delete manager',
    manager_got_deleted: 'Manager got deleted',
  },
};
