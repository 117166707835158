import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button, Flex, Result, Steps, Upload,
} from 'antd';
import {
  CloseCircleOutlined,
  FileOutlined, InboxOutlined, SmileOutlined, SolutionOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { collect } from 'collect.js';
import classnames from 'classnames';
import { SCREENS } from '../../../../services/exports/Constants';
import useApiClient from '../../../../hooks/api/useApiClient';
import { Utils } from '../../../../services/helpers/Utils';

function UploadStep({ setFile, onComplete }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Marketing:Followers:Import:UploadStep' });

  const { CustomersManager } = useApiClient();

  const uploadFile = async (e) => {
    const { file } = e;
    setFile(file);

    const { success, data } = await CustomersManager.import(Utils.createFormData({
      is_dry_run: true,
      file,
    }));

    if (!success) {
      return toast.error(t('toasts.error'));
    }

    return onComplete(data);
  };

  return (
    <div>
      <Upload.Dragger
        name="file"
        accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        customRequest={uploadFile}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('title')}</p>
        <p className="ant-upload-hint">
          {t('description')}
        </p>
      </Upload.Dragger>
      <Flex justify="end" className="tw-mt-small">
        <a href="/docs/customer_import_template.csv" target="_blank" rel="noreferrer">{t('buttons.download_template')}</a>
      </Flex>
    </div>
  );
}

export const ValidationStatus = {
  Valid: 'valid',
  HasInvalidContent: 'has_invalid_content',
  Invalid: 'invalid',
};

function ValidationStep({
  file, validation, onSuccess, onCancel,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Marketing:Followers:Import:ValidationStep' });

  const { CustomersManager } = useApiClient();

  const isValid = validation.heading.valid;
  const isContentValid = validation.content.valid;
  const validationStatus = useMemo(
    () => {
      if (!isValid) {
        return ValidationStatus.Invalid;
      }

      return isContentValid
        ? ValidationStatus.Valid
        : ValidationStatus.HasInvalidContent;
    },
    [isValid, isContentValid],
  );

  const [loading, setLoading] = useState(false);
  const [invalidRowsCount, setInvalidRowsCount] = useState(0);
  const groupedRowErrors = useMemo(
    () => collect(Object.keys(validation.content.rules))
      .mapToGroups((path) => {
        const [row, field] = path.split('.');

        field === 'email' && setInvalidRowsCount((current) => current + 1);

        return [
          row,
          { field, rules: validation.content.rules[path] },
        ];
      }),
    [validation.content.rules],
  );

  const onProceed = async () => {
    setLoading(true);
    const { success } = await CustomersManager.import(Utils.createFormData({
      is_dry_run: false,
      file,
    }));
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.error'));
    }

    return onSuccess(validation.content.rows - invalidRowsCount);
  };

  const resultStatus = ({
    [ValidationStatus.Valid]: 'success',
    [ValidationStatus.Invalid]: 'warning',
    [ValidationStatus.HasInvalidContent]: 'info',
  })[validationStatus];

  return (
    <Result
      status={resultStatus}
      title={t(`status.${validationStatus}.title`)}
      subTitle={t(`status.${validationStatus}.description`)}
      extra={(
        <>
          {isValid && (
            <>
              <p>
                {t('sections.content_validation.labels.imported_rows', { count: validation.content.rows - invalidRowsCount })}
              </p>
              {invalidRowsCount > 0 && (
                <p>
                  {t('sections.content_validation.labels.ignored_rows', { count: invalidRowsCount })}
                </p>
              )}
            </>
          )}
          <Flex justify="center" gap={8} className={classnames({ 'tw-mt-small': isValid })}>
            <Button onClick={onCancel}>{t('buttons.back_to_upload')}</Button>
            {isValid && (
              <Button type="primary" onClick={onProceed} loading={loading}>{t('buttons.proceed')}</Button>
            )}
          </Flex>
        </>
      )}
    >
      {!isValid && (
        <div>
          <h6 className="tw-mb-mini">{t('sections.heading_validation.title')}</h6>
          {Object.keys(validation.heading.rules).map((column) => {
            const rules = validation.heading.rules[column];

            return Object.keys(rules).map((rule) => (
              <p key={`heading-validation-column-${column}-rule-${rule}`}>
                <CloseCircleOutlined className="tw-text-brand-danger" />
                {' '}
                <Trans
                  t={t}
                  values={{
                    column: +column + 1, expected: validation.heading.rules[column][rule][0],
                  }}
                >
                  {`sections.heading_validation.rules.${rule}`}
                </Trans>
              </p>
            ));
          })}
        </div>
      )}
      {isValid && !isContentValid && (
        <div>
          <h6 className="tw-mb-mini">{t('sections.content_validation.title')}</h6>
          {Object.keys(groupedRowErrors.all()).map((row) => {
            const errors = collect(groupedRowErrors.get(row));

            return (
              <React.Fragment key={`row-${row}-errors`}>
                <p>
                  <Trans
                    t={t}
                    values={{ row: +row + 2 }}
                  >
                    sections.content_validation.labels.row
                  </Trans>
                  <ul>
                    {errors.map((item) => (
                      <li key={`row-${row}-field-${item.field}`}>
                        {t(`sections.content_validation.rules.${Object.keys(item.rules)[0]}`, {
                          field: t(`sections.content_validation.fields.${item.field}`, { defaultValue: item.field }),
                        })}
                      </li>
                    ))}
                  </ul>
                </p>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </Result>
  );
}

function FinishStep({ importedRowsCount }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Marketing:Followers:Import:FinishStep' });

  const history = useHistory();

  return (
    <Result
      status="success"
      title={t('title')}
      subTitle={t('description', { count: importedRowsCount })}
      extra={(
        <Button onClick={() => history.push(SCREENS.MARKETING_FOLLOWERS)}>{t('buttons.open_followers_list')}</Button>
      )}
    />
  );
}

export default function FollowersImportView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Marketing:Followers:Import' });

  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState(null);
  const [validation, setValidation] = useState(null);
  const [importedRowsCount, setImportedRowsCount] = useState(0);

  const onUploadComplete = (response) => {
    setValidation(response);
    setCurrentStep(1);
  };

  const onImportComplete = (rows) => {
    setImportedRowsCount(rows);
    setCurrentStep(2);
  };

  const steps = [
    {
      title: t('steps.upload'),
      icon: <FileOutlined />,
      content: <UploadStep setFile={setFile} onComplete={onUploadComplete} />,
    },
    {
      title: t('steps.validate'),
      icon: <SolutionOutlined />,
      content: <ValidationStep file={file} validation={validation} onSuccess={onImportComplete} onCancel={() => setCurrentStep(0)} />,
    },
    {
      title: t('steps.finish'),
      icon: <SmileOutlined />,
      content: <FinishStep importedRowsCount={importedRowsCount} />,
    },
  ];

  return (
    <>
      <h2>
        <Link to={`${SCREENS.MARKETING_FOLLOWERS}`}>
          {t('title')}
        </Link>
        {` / ${t('labels.import')}`}
      </h2>
      <Steps
        current={currentStep}
        className="tw-mt-medium"
        items={steps}
      />
      <div className="tw-mt-large">
        {steps[currentStep].content}
      </div>
    </>
  );
}
