export default {
  title:
    'Click on the button below to review and confirm your payment information.',
  description:
    'We are working with Stripe to handle payments. Stripe is the most secure payment platform used by the biggest companies worldwide.',
  form: {
    terms_acceptance: {
      label: 'By proceeding, you agree to our <FoodAmigosTerms>Services Agreement</FoodAmigosTerms> and the <StripeTerms>Stripe Connected Account Agreement.</StripeTerms>',
    },
  },
  buttons: {
    proceed: 'Confirm my payment info',
  },
  toasts: {
    something_went_wrong: 'Something went wrong, please contact technical support',
  },
};
