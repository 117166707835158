import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row,
} from 'antd';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import React, { useMemo } from 'react';
import { SUPPORTED_COUNTRIES } from '../../services/exports/Constants';

export default function AddressFields({
  title,
  prefix,
  data,
  onChange,
  labels,
  useGrid,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Form:AddressFields' });

  const constructField = (field) => (prefix ? `${prefix}_${field}` : field);
  const fields = useMemo(
    () => ({
      address: constructField('address'),
      city: constructField('city'),
      state: constructField('state'),
      country: constructField('country'),
      zip_code: constructField('zip_code'),
    }),
    [prefix],
  );

  return (
    <div className={className}>
      {Boolean(title) && <h6 className="tw-underline tw-my-small">{title}</h6>}
      <Form.Item label={labels?.address ?? t('form.address.label')} required>
        <Input
          placeholder={t('form.address.placeholder')}
          value={data[fields.address] ?? ''}
          onChange={(e) => onChange(fields.address, e.target.value)}
        />
      </Form.Item>
      <Row gutter={12}>
        <Col span={useGrid ? 12 : 24}>
          <Form.Item label={labels?.city ?? t('form.city.label')} required>
            <Input
              placeholder={t('form.city.placeholder')}
              value={data[fields.city] ?? ''}
              onChange={(e) => onChange(fields.city, e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={useGrid ? 12 : 24}>
          <Form.Item label={labels?.zip_code ?? t('form.zip_code.label')} required>
            <Input
              placeholder={t('form.zip_code.placeholder')}
              value={data[fields.zip_code] ?? ''}
              onChange={(e) => onChange(fields.zip_code, e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={useGrid ? 12 : 24}>
          <Form.Item label={labels?.country ?? t('form.country.label')} required>
            <CountryDropdown
              value={data[fields.country]}
              whitelist={SUPPORTED_COUNTRIES}
              valueType="short"
              onChange={(value) => onChange(fields.country, value)}
              defaultOptionLabel={t('form.country.placeholder')}
              classes="form-control"
            />
          </Form.Item>
        </Col>
        <Col span={useGrid ? 12 : 24}>
          <Form.Item label={labels?.state ?? t('form.state.label')} required>
            <RegionDropdown
              country={data[fields.country]}
              value={data[fields.state]}
              countryValueType="short"
              onChange={(value) => onChange(fields.state, value)}
              defaultOptionLabel={t('form.state.placeholder')}
              classes="form-control"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
