import { tabs } from '../../../../../views/dashboard/Dashboard';

export default {
  tabs: {
    [tabs.general]: 'General',
    [tabs.restaurant_details]: 'Restaurant details',
    [tabs.external_delivery]: 'External delivery',
  },
  metrics: {
    gmv: 'GMV',
    revenue: 'Revenue',
    revenue_subscription: 'Subscription',
    revenue_service_fee: 'Service fee',
    revenue_service_charges: 'Other services',
    processing_fees: 'Processing fees',
    processing_fees_stripe: 'Stripe',
    processing_fees_paypal: 'Paypal',
    rejected_orders: 'Cancelled orders',
    rejected_orders_amount: 'Sales',
    rejected_orders_count: 'Quantity',
    rejected_orders_rate: '% All orders',
    rejected_orders_rate_breakdown: '% Auto/Manual',
    average_order_value: 'Average order value',
    tips: 'Tips',
    reservations: 'Reservations',
    total_reservations: 'Total',
    website_reservations: 'Website',
    google_reservations: 'Google',
    google_reviews: 'New Google reviews',
    loyalty_sign_ups: 'Loyalty sign ups',
    users: 'Users',
    companies: 'Restaurants',
    customers: 'Customers',
    total_customers: 'Total customers',
    customers_auth_state: 'Guest / Signed up',
    new_customers: 'New customers',
    returning_customers: 'Returning customers',
    orders: 'Orders',
    total_orders: 'Total orders',
    pickup_orders_rate: 'Pickup orders',
    delivery_orders_rate: 'Delivery orders',
    other_orders_rate: 'Other orders',
    users_breakdown: 'Users breakdown',
    web_app_users: 'Web Orders Users',
    loyalty_app_users: 'Loyalty Tablet',
    restaurant_partners: 'Restaurant partners',
    registered_restaurants: 'Registered restaurants',
    live_restaurants: 'Live restaurants',
    live_restaurants_percentage: '% Live restaurants',
    use_reservations: 'Use reservations',
    number: 'Number',
    restaurants_percentage: '% Restaurants',
    crossed_sales_level: 'Crossed {{amount}} sales',
    crossed_sales_level_this_month: 'This month',
    crossed_sales_level_total: 'Total',
    order_method: 'Order method',
    own_delivery: 'Own delivery',
    only_pickup: 'Only pickup',
    use_external_delivery: 'Delivery via Foodamigos',
    storefront: 'Storefront',
    has_storefront: 'Restaurants using storefront',
    has_storefront_percentage: '% live Restaurants',
    delivery_conversion: 'Delivery conversion',
    delivery_quotes_count: 'Quotes requested',
    deliveries_count: 'Deliveries requested',
    delivery_quotes_provider_breakdown: 'Delivery quotes by provider',
    deliveries_provider_breakdown: 'Deliveries by provider',
    sales_tiers: 'Sales tiers',
    sales_breakdown: {
      name: 'Name',
      sales: 'Sales',
    },
  },
  delivery_providers: {
    first_delivery: 'Uber',
    wolt_drive: 'Wolt',
  },
  date_range: {
    month: 'This month',
    last_month: 'Last month',
    all: 'All',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
