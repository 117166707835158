import Login from './Login';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import CreateNewPassword from './CreateNewPassword';

export default {
  Login,
  ResetPassword,
  ForgotPassword,
  CreateNewPassword,
};
