import ApiResourceManager from '../ApiResourceManager';

export default class FranchisesManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/franchises',
    params,
  });

  show = (id) => this.request({
    method: 'GET',
    url: `/franchises/${id}`,
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/franchises',
    data,
  });

  update = (id, data) => this.request({
    method: 'POST',
    url: `/franchises/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/franchises/${id}`,
  });

  syncLieferandoInfo = (id, data) => this.request({
    method: 'PUT',
    url: `/franchises/${id}/lieferando-brand`,
    data,
  });
}
