export default {
  title:
    'Klicke auf die Schaltfläche unten, um deine Zahlungsinformationen zu überprüfen und zu bestätigen.',
  description:
    'Wir arbeiten mit Stripe zusammen, um Zahlungen abzuwickeln. Stripe ist die sicherste Zahlungsplattform, die von den größten Unternehmen weltweit genutzt wird.',
  form: {
    terms_acceptance: {
      label: 'Indem Sie fortfahren, erklären Sie sich mit unserer <FoodAmigosTerms>Dienstleistungsvereinbarung</FoodAmigosTerms> und der <StripeTerms>Stripe Connected Account-Vereinbarung</StripeTerms> einverstanden.',
    },
  },
  buttons: {
    proceed: 'Bestätige meine Zahlungsinformationen',
  },
  toasts: {
    something_went_wrong: 'Etwas ist schief gelaufen, bitte kontaktieren Sie den technischen Support',
  },
};
