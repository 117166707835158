export default {
  title: 'Öffnungszeiten',
  headers: {
    edit_time: 'Zeit bearbeiten',
    create_time: 'Zeit hinzufügen',
  },
  labels: {
    select_all: 'Alles auswählen',
    timezone: 'Zeitzone',
  },
  fields: {
    selected_days: {
      label: 'Ausgewählte Tage',
    },
    opening_time: {
      label: 'Öffnungszeit',
    },
    closing_time: {
      label: 'Schließzeit',
    },
  },
  columns: {
    days: 'Tage',
    time: 'Uhrzeit',
  },
  buttons: {
    add_opening_time: 'Öffnungszeit hinzufügen',
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
    save: 'Speichern',
    close: 'Schließen',
    delete: 'Löschen',
    sync_from_online_shop: 'Synchronisieren aus dem Online-Shop',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservierungen',
    storefront: 'Website',
  },
  feedback: {
    missing_info: 'Fehlende Informationen',
  },
  toasts: {
    saving_success: 'Erfolgreich gespeichert',
    saving_error:
      'Fehler beim Speichern. Bitte überprüfen Sie Ihre Informationen und versuchen Sie es erneut.',
    sync_success: 'Erfolgreich synchronisiert',
    sync_error:
      'Fehler beim Synchronisieren der Öffnungszeiten aus dem Online-Shop. Bitte versuchen Sie es erneut.',
    hours_required: 'Öffnungszeiten sind erforderlich. Stattdessen bestehende Öffnungszeiten bearbeiten',
  },
  modals: {
    timezone: {
      form: {
        timezone: {
          label: 'Zeitzone',
        },
      },
      toasts: {
        error: 'Zeitzone konnte nicht aktualisiert werden',
      },
    },
  },
};
