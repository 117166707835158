import {
  Button, Card, Flex, Popconfirm,
} from 'antd';
import {
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressHelpers from '../../services/helpers/AddressHelpers';

export default function CompanyCard({
  company, isDraft, onEdit, onDelete,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Company:CompanyCard' });

  return (
    <Card
      title={company.name}
      extra={(
        <Flex gap="8px">
          {Boolean(onEdit) && (
            <Button
              onClick={onEdit}
              type="text"
              icon={<EditOutlined />}
            />
          )}
          {Boolean(onDelete) && (
            <Popconfirm
              title={t('delete_confirmation')}
              onConfirm={onDelete}
              okText={t('buttons.confirm')}
              cancelText={t('buttons.cancel')}
              okButtonProps={{ danger: true }}
            >
              <Button
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Flex>
      )}
      classNames={{ body: '!tw-py-small' }}
    >
      <p>{AddressHelpers.format({ ...company, street_name: company.address })}</p>
      {isDraft && (
        <>
          <p className="tw-mt-mini">
            {company.google_place ? <CheckOutlined /> : <CloseOutlined />}
            {' '}
            {t('labels.google_place')}
          </p>
          <p className="tw-mt-mini">
            {company.import_menu ? <CheckOutlined /> : <CloseOutlined />}
            {' '}
            {t('labels.menu_import')}
            {' '}
            {company.import_menu && Boolean(company.menu_provider) ? `(${company.menu_provider})` : null}
          </p>
        </>
      )}
    </Card>
  );
}
