import {
  CompanyCreationModes,
  CompanyCreationSteps,
  BusinessProfileOption,
  FranchiseOption,
} from '../../../../../views/companies';
import { BusinessType } from '../../../../../services/exports/Constants';

export default {
  title: 'Neues Restaurant',
  sections: {
    new_company: {
      titles: {
        [CompanyCreationModes.demo]: 'Neue Demo',
        [CompanyCreationModes.full]: 'Neue Restaurant',
      },
      steps: {
        [CompanyCreationSteps.CompanyInfo]: 'Restaurant info',
        [CompanyCreationSteps.BusinessInfo]: 'Business info',
      },
    },
    recently_created: 'Kürzlich erstellt',
  },
  form: {
    franchise: {
      label: 'Restaurant-Gruppe',
      options: {
        [FranchiseOption.CreateFranchise]: 'Neue Restaurantgruppe erstellen / Eigenständiges Restaurant erstellen',
        [FranchiseOption.UseExistingFranchise]: 'Restaurant zu einer bestehenden Gruppe hinzufügen',
      },
    },
    restaurants: {
      label: 'Restaurants',
    },

    business_profile: {
      label: 'Geschäftsprofil',
      options: {
        [BusinessProfileOption.CreateBusinessProfile]: 'Neues Unternehmensprofil erstellen',
        [BusinessProfileOption.UseExistingBusinessProfile]: 'Vorhandenes Geschäftsprofil verwenden',
      },
    },
    business_type: {
      label: 'Art der Tätigkeit',
      placeholder: 'Geschäftstyp auswählen',
      options: {
        [BusinessType.Company]: 'Gastronomischer Betrieb',
        [BusinessType.Individual]: 'Einzelunternehmer',
      },
    },
    display_name: {
      label: 'Restaurant Name',
      placeholder: 'Freundlicher Name',
    },
    legal_name: {
      label: 'Juristischer Name',
      placeholder: 'Juristischer Name',
    },
    owner_phone_number: {
      label: 'Telefonnummer Kontaktperson',
      placeholder: '1785080120',
    },
    owner_email: {
      label: 'E-mail Kontaktperson',
      placeholder: 'E-Mail eingeben',
    },
  },
  labels: {
    create_demo: 'Demo erstellen',
    create_company: 'Restaurant erstellen',
    business_type: 'Business type',
    display_name: 'Friendly name',
    legal_name: 'Legal name',
    owner_name: 'Owner name',
    address: 'Address',
  },
  fields: {
    name: 'Name',
    created_at: 'Erstellt',
    actions: 'Aktionen',
  },

  buttons: {
    add_restaurant: '+ Add restaurant',
    continue: 'Weiter',
    back: 'Zurück',
    create: 'Restaurant erstellen',
    delete: 'Löschen',
  },
  popups: {
    delete_demo: {
      title: 'Sicher beim Löschen?',
    },
  },
  toasts: {
    company_created: 'Restaurant erfolgreich erstellt',
    failed_to_create_company: 'Restaurant kann nicht erstellt werden',
    failed_to_delete_demo: 'Demo konnte nicht gelöscht werden',
    demo_got_deleted: 'Demo erfolgreich gelöscht',
    failed_to_load_recently_created_companies: 'Kürzlich erstellte Restaurants konnten nicht geladen werden',
  },
};
