import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class MerchantsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/merchants',
    params,
    paramsSerializer: qs.stringify,
  });
}
