export default class MathHelpers {
  static percentageDiff = (a, b) => {
    if (a === b) {
      return 0;
    }

    if (a === 0 || b === 0) {
      return 0;
    }

    return Math.round(
      (((-1) * (1 - a / b) * 100) + Number.EPSILON) * 100,
    ) / 100;
  };

  static percentagePart = (a, b) => {
    if (a === b && a !== 0) {
      return 100;
    }

    if (b === 0) {
      return 0;
    }

    return Math.round(
      (a / b) * 100 * 100,
    ) / 100;
  };
}
