export default {
  form: {
    shipping_company_name: {
      label: 'Company',
      placeholder: 'Enter company name',
    },
    shipping_phone_number: {
      label: 'Phone number',
      placeholder: 'Enter phone number',
    },
    shipping_salutation: {
      label: 'Salutation',
      options: {
        mr: 'Mr',
        mrs: 'Mrs',
      },
    },
    shipping_first_name: {
      label: 'First name',
      placeholder: 'Enter first name',
    },
    shipping_last_name: {
      label: 'Last name',
      placeholder: 'Enter last name',
    },
  },
  buttons: {
    back: 'Back',
    next: 'Next',
  },
};
