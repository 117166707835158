export default {
  form: {
    document_type: {
      label: 'Nachweis von Geschäftsdokumentent',
      placeholder: 'Dokumenttyp auswählen',
      options: {
        vat_registration: 'USt-Registrierung',
        trade_registration: 'Gewerbeanmeldung',
        trade_license: 'Gewerbeschein',
        commercial_register_extract: 'Handelsregisterauszug',
        financial_statements: 'Jahresabschluss',
        other: 'Andere',
      },
    },
    front: {
      label: 'Dokumentenvorderseite',
    },
    back: {
      label: 'Rückseite des Dokuments (optional für einige Dokumente)',
    },
  },
  buttons: {
    select_file: 'Dokument auswählen',
    next: 'Weiter',
    back: 'Zurück',
  },
};
