import { BusinessProfileUpdateStrategy } from '../../../../providers/BusinessProfileProvider';

export default {
  modals: {
    update_strategy: {
      title: 'What locations should be updated?',
      form: {
        update_strategy: {
          options: {
            [BusinessProfileUpdateStrategy.All]: 'All my locations',
            [BusinessProfileUpdateStrategy.Current]: 'Currently selected location only',
          },
        },
      },
      buttons: {
        confirm: 'Confirm',
      },
    },
  },
  toasts: {
    error: 'Something went wrong, please try again or contact technical support',
    success: 'Changes have been successfully saved!',
  },
};
