import ApiResourceManager from '../ApiResourceManager';

export default class ManagersManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/managers',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/managers',
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/managers/${id}`,
  });

  getDefaultSalesCommission = () => this.request({
    method: 'GET',
    url: '/managers/default-sales-commission',
  });

  getGlobalAccessControlList = (params) => this.request({
    method: 'GET',
    url: '/global-access-control-list',
    params,
  });

  updateGlobalAccessControlList = (data) => this.request({
    method: 'PUT',
    url: '/global-access-control-list',
    data,
  });
}
