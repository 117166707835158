import { useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { collect } from 'collect.js';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { BusinessType, ONBOARDING_STEPS } from '../../services/exports/Constants';
import InfoSubmissionStep from '../../components/verification/InfoSubmissionStep';
import OwnerDocumentStep from '../../components/verification/OwnerDocumentStep';
import BusinessDocumentStep from '../../components/verification/BusinessDocumentStep';
import TaxInfoStep from '../../components/verification/TaxInfoStep';
import BankInfoStep from '../../components/verification/BankInfoStep';
import PersonalInfoStep from '../../components/verification/PersonalInfoStep';
import BusinessInfoStep from '../../components/verification/BusinessInfoStep';
import PaymentInfoStep from '../../components/verification/PaymentInfoStep';
import ContractStep from '../../components/verification/ContractStep';
import SubscriptionStep from '../../components/verification/SubscriptionStep';
import useVerificationStatus from './useVerificationStatus';
import ShippingInfoStep from '../../components/verification/ShippingInfoStep';
import useApiClient from '../api/useApiClient';
import PromoCodeStep from '../../components/verification/PromoCodeStep';

export default function useVerification() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Hooks:useVerification' });

  const { MerchantProfileManager, PromoCodesManager } = useApiClient();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((store) => store.currentCompany);
  const business_profile = company?.business_profile ?? {};
  const merchant = company?.merchant ?? {};
  const {
    payment_options,
    service_contract,
    subscriptions_count,
    onboarding_complete_at,
  } = merchant;

  const [promoCodes, setPromoCodes] = useState([]);

  const { isPending: stripeVerificationPending, isComplete: stripeVerificationComplete } = useVerificationStatus();
  const hasPaymentOption = payment_options?.length > 0;
  const hasSignedContract = !serviceProvider?.has_merchant_contracts || (service_contract && service_contract?.signed_at !== null);
  const hasActiveSubscription = subscriptions_count > 0;
  const hasPromoCode = promoCodes?.length > 0 || collect(merchant?.skipped_verification_steps).contains(ONBOARDING_STEPS.PROMO_CODE);

  const isCompany = business_profile.business_type === BusinessType.Company;
  const isIndividual = business_profile.business_type === BusinessType.Individual;

  const requiredPersonalFields = useMemo(() => collect([
    'owner_first_name',
    'owner_last_name',
    'owner_dob',
    'owner_address',
    'owner_city',
    'owner_state',
    'owner_country',
    'owner_zip_code',
  ]).when(
    business_profile.legal_country === 'US',
    (collection) => collection.push('owner_id_number'),
  ), [business_profile.legal_country]);

  useEffect(() => {
    company?.id && requestPromoCodes();
  }, []);

  async function requestPromoCodes() {
    const { success, data } = await PromoCodesManager.get(company.id);

    if (!success) {
      return;
    }

    return setPromoCodes([
      ...data.active,
      ...data.expired,
    ]);
  }

  const steps = useMemo(() => [
    {
      index: 1,
      id: ONBOARDING_STEPS.BUSINESS_INFO,
      is_required: true,
      is_finished: checkBusinessInfo(),
      name: t(ONBOARDING_STEPS.BUSINESS_INFO),
      render: (props) => <BusinessInfoStep {...props} />,
    },
    {
      index: 2,
      id: ONBOARDING_STEPS.PERSONAL_INFO,
      is_required: true,
      is_finished: checkPersonalInfo(),
      name: t(ONBOARDING_STEPS.PERSONAL_INFO),
      render: (props) => {
        const filtered = {
          ...props,
          moveBackwards: isCompany ? props.moveBackwards : null,
        };

        return <PersonalInfoStep {...filtered} />;
      },
    },
    {
      index: 3,
      id: ONBOARDING_STEPS.SHIPPING_INFO,
      is_required: true,
      is_finished: checkShippingInfo(),
      name: t(ONBOARDING_STEPS.SHIPPING_INFO),
      render: (props) => <ShippingInfoStep {...props} />,
    },
    {
      index: 4,
      id: ONBOARDING_STEPS.BANK_INFO,
      is_required: true,
      is_finished: checkBankDetails(),
      name: t(ONBOARDING_STEPS.BANK_INFO),
      render: (props) => <BankInfoStep {...props} />,
    },
    {
      index: 5,
      id: ONBOARDING_STEPS.TAX_INFO,
      is_required: true,
      is_skippable: true,
      is_finished: checkTaxDetails(),
      name: t(ONBOARDING_STEPS.TAX_INFO),
      render: (props) => (
        <TaxInfoStep
          isSkippable
          {...props}
        />
      ),
    },
    {
      index: 6,
      id: ONBOARDING_STEPS.BUSINESS_DOC,
      is_required: isCompany,
      is_finished: merchant.business_document_uploaded,
      name: t(ONBOARDING_STEPS.BUSINESS_DOC),
      render: (props) => <BusinessDocumentStep {...props} />,
    },
    {
      index: 7,
      id: ONBOARDING_STEPS.PERSONAL_DOC,
      is_required: true,
      is_finished: merchant.owner_document_uploaded,
      name: t(ONBOARDING_STEPS.PERSONAL_DOC),
      render: (props) => <OwnerDocumentStep {...props} />,
    },
    {
      index: 8,
      id: ONBOARDING_STEPS.CONFIRM_INFO,
      is_required: true,
      is_finished: stripeVerificationPending || stripeVerificationComplete,
      name: t(ONBOARDING_STEPS.CONFIRM_INFO),
      render: (props) => <InfoSubmissionStep {...props} />,
    },
    {
      index: 9,
      id: ONBOARDING_STEPS.PAYMENT_INFO,
      is_required: true,
      is_finished: hasPaymentOption,
      name: t(ONBOARDING_STEPS.PAYMENT_INFO),
      render: (props) => <PaymentInfoStep {...props} />,
    },
    {
      index: 10,
      id: ONBOARDING_STEPS.SUBSCRIPTION,
      is_required: true,
      is_finished: hasActiveSubscription,
      name: t(ONBOARDING_STEPS.SUBSCRIPTION),
      render: (props) => <SubscriptionStep {...props} />,
    },
    {
      index: 11,
      id: ONBOARDING_STEPS.CONTRACT,
      is_required: serviceProvider?.has_merchant_contracts,
      is_finished: hasSignedContract,
      name: t(ONBOARDING_STEPS.CONTRACT),
      render: (props) => <ContractStep {...props} />,
    },
    {
      index: 12,
      id: ONBOARDING_STEPS.PROMO_CODE,
      is_required: true,
      is_skippable: true,
      is_finished: hasPromoCode,
      name: t(ONBOARDING_STEPS.PROMO_CODE),
      render: (props) => (
        <PromoCodeStep
          isSkippable
          {...props}
        />
      ),
    },
  ], [
    business_profile,
    company,
    merchant,
    serviceProvider?.has_merchant_contracts,
    stripeVerificationPending,
    stripeVerificationComplete,
    hasPaymentOption,
    hasSignedContract,
    hasActiveSubscription,
    hasPromoCode,
    isCompany,
  ]);

  const isFinished = useCallback(
    (step) => step.is_finished
      || (step?.is_skippable && collect(merchant?.skipped_verification_steps).contains(step.id)),
    [merchant?.skipped_verification_steps],
  );

  const isComplete = useMemo(
    () => !!onboarding_complete_at || collect(steps)
      .where('is_required', true)
      .every((item) => isFinished(item)),
    [steps, isFinished, onboarding_complete_at],
  );

  const markVerificationComplete = async () => MerchantProfileManager.update({
    onboarding_complete_at: moment().format(),
  });

  useEffect(() => {
    isComplete && !onboarding_complete_at && markVerificationComplete();
  }, [isComplete]);

  function findLastActiveStep() {
    return collect(steps).first(
      (item) => item.is_required && !isFinished(item),
    );
  }

  function getRequiredSteps() {
    return collect(steps).where('is_required').toArray();
  }

  function getRemainingSteps() {
    return collect(steps).filter(
      (item) => item.is_required && !isFinished(item),
    ).count();
  }

  function getPrevStep(step) {
    return collect(steps).where('index', '<', step.index)
      .where('is_required')
      .last();
  }

  function getNextStep(step) {
    return collect(steps).where('index', '>', step.index)
      .where('is_required')
      .filter((item) => !isFinished(item))
      .first();
  }

  function getStep(index) {
    return collect(steps).firstWhere('index', index);
  }

  function getProgress() {
    return {
      total: getRequiredSteps().length,
      value: collect(steps).search((item) => item.id === findLastActiveStep()?.id),
    };
  }

  function checkBusinessInfo() {
    return Boolean(business_profile.legal_name)
      && Boolean(business_profile.legal_country)
      && Boolean(business_profile.legal_state)
      && Boolean(business_profile.legal_city)
      && Boolean(business_profile.legal_address)
      && Boolean(business_profile.legal_zip_code);
  }

  function checkPersonalInfo() {
    return requiredPersonalFields.reduce(
      (result, field) => result && business_profile[field],
      true,
    );
  }

  function checkShippingInfo() {
    return business_profile.shipping_company_name
      && business_profile.shipping_salutation
      && business_profile.shipping_first_name
      && business_profile.shipping_last_name
      && business_profile.shipping_address
      && business_profile.shipping_zip_code
      && business_profile.shipping_city
      && business_profile.shipping_phone_number;
  }

  function checkBankDetails() {
    return Boolean(business_profile.bank_account_number) && Boolean(merchant.stripe_bank_account_id);
  }

  function checkTaxDetails() {
    return Boolean(business_profile.tax_id);
  }

  return {
    steps: collect(steps),
    isComplete,
    isCompany,
    isIndividual,
    requiredPersonalFields,
    findLastActiveStep,
    getRequiredSteps,
    getRemainingSteps,
    getPrevStep,
    getNextStep,
    getStep,
    getProgress,
  };
}
