import { tabs } from '../../../../../views/dashboard/Dashboard';

export default {
  tabs: {
    [tabs.general]: 'Allgemein',
    [tabs.restaurant_details]: 'Restaurant Details',
    [tabs.external_delivery]: 'Externe Lieferung',
  },
  metrics: {
    gmv: 'GMV',
    revenue: 'Umsatz',
    revenue_subscription: 'Abonnement',
    revenue_service_fee: 'Servicegebühr',
    revenue_service_charges: 'Andere Services',
    processing_fees: 'Zahlungsgebühr',
    processing_fees_stripe: 'Stripe',
    processing_fees_paypal: 'Paypal',
    rejected_orders: 'Stornierungen',
    rejected_orders_amount: 'Umsatz',
    rejected_orders_count: 'Anzahl',
    rejected_orders_rate: '% Total orders',
    rejected_orders_rate_breakdown: '% Auto/Manuell',
    average_order_value: 'Durchschnittlicher Bestellwert',
    tips: 'Trinkgeld',
    reservations: 'Reservierungen',
    total_reservations: 'Gesamt',
    website_reservations: 'Website',
    google_reservations: 'Google',
    google_reviews: 'Neue Google Bewertungen',
    loyalty_sign_ups: 'Anmeldungen Loyalty Programm',
    users: 'Nutzer',
    companies: 'Partner Restaurant',
    customers: 'Endkunden',
    total_customers: 'Kunden gesamt',
    customers_auth_state: 'Gast / Angemeldet',
    new_customers: 'Neue Kunden',
    returning_customers: 'Wiederkehrende Kunden',
    orders: 'Bestellungen',
    total_orders: 'Total Bestellungen',
    pickup_orders_rate: 'Abholaufträge',
    delivery_orders_rate: 'Lieferaufträge',
    other_orders_rate: 'Andere Aufträge',
    users_breakdown: 'Aufgliederung der Nutzer',
    web_app_users: 'Web-Bestellungen Nutzer',
    loyalty_app_users: 'Loyalty Tablet',
    restaurant_partners: 'Restaurant Partner',
    registered_restaurants: 'Registrierte Restaurants',
    live_restaurants: 'Live Restaurants',
    live_restaurants_percentage: '% Live Restaurants',
    use_reservations: 'Nutzen Resevierungen',
    number: 'Anzahl',
    restaurants_percentage: '% Restaurants',
    crossed_sales_level: 'Über {{amount}} Umsatz',
    crossed_sales_level_this_month: 'Diesen Monat',
    crossed_sales_level_total: 'Insgesamt',
    order_method: 'Methode bestellen',
    own_delivery: 'Eigene Lieferung',
    only_pickup: 'Nur Abholung',
    use_external_delivery: 'Lieferung via Foodamigos',
    storefront: 'Website',
    has_storefront: 'Restaurants nutzen Website',
    has_storefront_percentage: '% Live Restaurants',
    delivery_conversion: 'Umstellung der Lieferung',
    delivery_quotes_count: 'Angeforderte Zitate',
    delivery_quotes_provider_breakdown: 'Lieferangebote nach Anbieter',
    deliveries_provider_breakdown: 'Lieferungen nach Anbieter',
    deliveries_count: 'Erbetene Lieferungen',
    sales_tiers: 'Umsatzstufen',
    sales_breakdown: {
      name: 'Name',
      sales: 'Vertrieb',
    },
  },
  delivery_providers: {
    first_delivery: 'Uber',
    wolt_drive: 'Wolt',
  },
  date_range: {
    month: 'Diesen Monat',
    last_month: 'Letzter Monat',
    all: 'Alle',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
