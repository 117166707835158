export default {
  toasts: {
    failed_to_create_website: 'Failed to create website',
  },
  steps: {
    information: 'Information',
    template: 'Template',
  },
  buttons: {
    next: 'Next',
    previous: 'Previous',
    create_website: 'Create Website',
  },
  error_messages: {
    missing_fields: 'Some required fields are missing. Please fill in all necessary information before proceeding.',
  },
};
