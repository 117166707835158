import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, Space } from 'antd';
import { DownOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { collect } from 'collect.js';
import useAuth from '../../hooks/auth/useAuth';
import { SCREENS } from '../../services/exports/Constants';
import usePermissions from '../../hooks/auth/usePermissions';
import useScreenType from '../../hooks/context/useScreenType';

export const profile_item = 'profile';
export const language_item = 'language';
export const support_item = 'support';
export const logout_item = 'logout';

export default function ProfileDropdown() {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: 'Components:Layout:ProfileDropdown' });

  const { company } = useSelector((state) => state.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { business_profile } = company;

  const { logOut } = useAuth();
  const { isMerchant } = usePermissions();
  const history = useHistory();

  const { isDesktop } = useScreenType();

  const onClick = (e) => {
    const action = ({
      [profile_item]: () => history.push(SCREENS.PROFILE),
      [language_item]: () => i18n.changeLanguage(e.key.split('-')[1]),
      [logout_item]: () => logOut(),
    })[collect(e.keyPath).last()] ?? (() => null);

    return action();
  };

  const items = useMemo(
    () => [
      {
        key: profile_item,
        label: t(`items.${profile_item}`),
        'should-render': !!company?.id,
      },
      {
        key: language_item,
        label: t(`items.${language_item}`),
        'should-render': serviceProvider?.regional_config.locales.length > 1,
        children: (serviceProvider?.regional_config.locales ?? []).map(
          (item) => ({
            key: `locale-${item}`,
            label: t(`languages.${item}`),
          }),
        ),
      },
      {
        key: support_item,
        label: t(`items.${support_item}`),
        'should-render': isMerchant,
        children: [
          {
            key: 'email',
            label: <a href={`mailto:${serviceProvider?.support_email}`}>{serviceProvider?.support_email}</a>,
            icon: <MailOutlined />,
          },
          {
            key: 'phone',
            label: <a href={`tel:${serviceProvider?.support_phone_number}`}>{serviceProvider?.support_phone_number}</a>,
            icon: <PhoneOutlined />,
          },
        ],
      },
      {
        type: 'divider',
        'should-render': true,
      },
      {
        key: logout_item,
        label: t(`items.${logout_item}`),
        'should-render': true,
      },
    ],
    [
      isMerchant,
      serviceProvider?.regional_config.locales,
      serviceProvider?.support_email,
      serviceProvider?.support_phone_number,
    ],
  );

  return company && (
    <Dropdown menu={{ items: collect(items).where('should-render', true).toArray(), onClick }}>
      <a onClick={(e) => e.preventDefault()} className="!tw-text-brand-text-default cursor-pointer">
        <Space size={12} wrap>
          {isDesktop && (company?.id ? business_profile?.owner?.name : serviceProvider?.name)}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
}
