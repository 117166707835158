import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import {
  DeleteOutlined,
  LoginOutlined, MoreOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col, Dropdown, Flex, Form, Input, Modal, Row, Select, Table,
} from 'antd';
import CollapsibleForm from '../../../components/form/CollapsibleForm';
import useForm from '../../../hooks/utility/useForm';
import useFeedbackLabel from '../../../hooks/ui/useFeedbackLabel';
import { ROLES } from '../../../services/exports/Constants';
import useApiClient from '../../../hooks/api/useApiClient';
import useHelpers from '../../../hooks/context/useHelpers';
import SalesCommissionEditor from '../../../components/team/SalesCommissionEditor';

export default function ManagersTab() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Team:Managers:ManagersTab' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { ManagersManager, MagicLinksManager } = useApiClient();

  const { formatDateTime } = useHelpers();

  const { data, update, reset: resetData } = useForm({
    first_name: null,
    last_name: null,
    email: null,
    city: null,
    role: null,
    sales_commission: null,
    sales_commission_tiers: null,
  });

  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [showDeleteManagerModal, setShowDeleteManagerModal] = useState(false);
  const [runningActionFor, setRunningActionForFor] = useState(null);
  const { setFeedback, renderFeedbackLabel, resetFeedback } = useFeedbackLabel();

  useEffect(() => {
    loadManagers();
  }, [serviceProvider?.id]);

  const loadManagers = async () => {
    setLoading(true);
    const { success, data: responseData } = await ManagersManager.get();
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setManagers(responseData);
  };

  const createManager = async () => {
    setCreating(true);
    const { success, data: responseData } = await ManagersManager.create({
      ...data,
      roles: [data.role],
    });
    setCreating(false);

    if (!success) {
      setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });

      return toast.error(t('toasts.failed_to_create_manager'));
    }

    resetData();
    resetFeedback();

    return loadManagers();
  };

  const logInAs = async (manager) => {
    setRunningActionForFor(manager.id);
    const { success, data } = await MagicLinksManager.create({
      app: 'dashboard',
      manager_id: manager.id,
    });
    setRunningActionForFor(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_create_magic_link'));
    }

    return window.location.replace(data.url);
  };

  const deleteManager = async (manager, requiresConfirmation = true) => {
    if (requiresConfirmation) {
      setSelectedManager(manager);

      return setShowDeleteManagerModal(true);
    }

    setRunningActionForFor(manager.id);
    const { success } = await ManagersManager.delete(manager.id);
    setRunningActionForFor(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete_manager'));
    }

    toast.success(t('toasts.manager_got_deleted'));
    setShowDeleteManagerModal(false);
    setSelectedManager(null);

    return setManagers((current) => collect(current).where('id', '!=', manager.id).toArray());
  };

  const columns = [
    {
      title: t('fields.email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('fields.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('fields.roles'),
      key: 'roles',
      render: (_, item) => collect(item.roles).implode(', '),
    },
    {
      title: t('fields.created_at'),
      key: 'created_at',
      render: (_, item) => formatDateTime(item.created_at),
    },
    {
      title: t('fields.actions'),
      key: 'actions',
      render: (_, item) => !item.roles.includes(ROLES.admin) && (
        <Dropdown
          menu={{
            items: [
              {
                key: 'view_dashboard',
                label: t('buttons.view_dashboard'),
                loading: runningActionFor === item.id,
                icon: <LoginOutlined />,
                onClick: () => logInAs(item),
              },
              {
                key: 'delete',
                label: t('buttons.delete'),
                danger: true,
                icon: <DeleteOutlined />,
                onClick: () => deleteManager(item),
              },
            ],
          }}
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <CollapsibleForm
        title={t('form.title')}
        className="tw-mt-small"
      >
        <Form layout="vertical" className="tw-mt-small">
          {renderFeedbackLabel}
          <Row gutter={8}>
            <Col xs={24} md={12}>
              <Form.Item label={t('form.first_name.label')}>
                <Input
                  value={data.first_name ?? ''}
                  onChange={(e) => update('first_name', e.target.value)}
                  placeholder={t('form.first_name.placeholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('form.last_name.label')}>
                <Input
                  value={data.last_name ?? ''}
                  onChange={(e) => update('last_name', e.target.value)}
                  placeholder={t('form.last_name.placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={t('form.city.label')}>
            <Input
              value={data.city ?? ''}
              onChange={(e) => update('city', e.target.value)}
              placeholder={t('form.city.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.email.label')}>
            <Input
              value={data.email ?? ''}
              onChange={(e) => update('email', e.target.value)}
              placeholder={t('form.email.placeholder')}
              type="email"
            />
          </Form.Item>
          <Form.Item label={t('form.roles.label')}>
            <Select
              value={data.role}
              onChange={(value) => update('role', value)}
              options={[
                { label: t(`form.roles.options.${ROLES.reseller}`), value: ROLES.reseller },
                { label: t(`form.roles.options.${ROLES.sales_manager}`), value: ROLES.sales_manager },
                { label: t(`form.roles.options.${ROLES.content_manager}`), value: ROLES.content_manager },
                { label: t(`form.roles.options.${ROLES.onboarding_manager}`), value: ROLES.onboarding_manager },
              ]}
              placeholder={t('form.roles.placeholder')}
            />
          </Form.Item>
        </Form>
        {data.role === ROLES.sales_manager && (
          <SalesCommissionEditor
            salesCommission={data.sales_commission}
            tiers={data.sales_commission_tiers}
            update={update}
          />
        )}
        <Flex justify="end" className="tw-mt-small">
          <Button
            type="primary"
            loading={creating}
            onClick={createManager}
          >
            {t('buttons.add_manager')}
          </Button>
        </Flex>
      </CollapsibleForm>
      <Table
        columns={columns}
        dataSource={managers}
        loading={loading}
        rowKey={(item) => item.id}
        pagination={false}
        className="rounded-none table-responsive position-initial"
      />
      {showDeleteManagerModal && (
        <Modal
          open
          title={t('modals.delete_manager.title', { email: selectedManager.email })}
          okButtonProps={{
            danger: true,
            loading: runningActionFor === selectedManager?.id,
          }}
          onOk={() => deleteManager(selectedManager, false)}
          onCancel={() => {
            setShowDeleteManagerModal(false);
            setSelectedManager(null);
          }}
        />
      )}
    </>
  );
}
