import {
  CCollapse,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button, Col, Flex, Form, Input, Pagination, Popover, Row, Select, Table,
} from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import useForm from '../../../hooks/utility/useForm';
import { FLYER_URL_TYPES, HELLOPRINT_SERVICE_LEVELS } from '../../../services/exports/Constants';
import useApiClient from '../../../hooks/api/useApiClient';
import useFeedbackLabel from '../../../hooks/ui/useFeedbackLabel';
import HelperMethods from '../../../services/exports/HelperMethods';
import ConfirmCancelModal from '../../../modals/ConfirmCancelModal';
import CardWrapper from '../../cards/CardWrapper';
import AddressFields from '../../form/AddressFields';

export default function FlyerOrdersTab({ flyer }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Marketing:Flyer:FlyerOrdersTab' });

  const { FlyersManager } = useApiClient();
  const { company } = useSelector((store) => store.currentCompany);
  const { business_profile } = company;

  const [showOrderForm, setShowOrderForm] = useState(false);
  const [showOrderConfirmationPopup, setShowOrderConfirmationPopup] = useState(false);
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const toggleForm = () => setShowOrderForm((current) => !current);
  const toggleOrderConfirmationPopup = () => setShowOrderConfirmationPopup((current) => !current);
  const toggleAttentionPopup = () => setShowAttentionPopup((current) => !current);
  const { setFeedback, renderFeedbackLabel, resetFeedback } = useFeedbackLabel();

  const { data, update } = useForm({
    quantity: 1000,
    service_level: HELLOPRINT_SERVICE_LEVELS.SAVER,
    shipping_phone_number: business_profile?.shipping_phone_number ?? business_profile?.owner_phone_number,
    shipping_company_name: business_profile?.shipping_company_name ?? company?.name,
    shipping_salutation: business_profile?.shipping_salutation ?? t('form.shipping_salutation.options.mr'),
    shipping_first_name: business_profile?.shipping_first_name ?? business_profile?.first_name,
    shipping_last_name: business_profile?.shipping_last_name ?? business_profile?.owner_last_name,
    shipping_address: business_profile?.shipping_address ?? business_profile?.legal_address,
    shipping_city: business_profile?.shipping_city ?? business_profile?.legal_city,
    shipping_state: business_profile?.shipping_state ?? business_profile?.legal_state,
    shipping_country: business_profile?.shipping_country ?? business_profile?.legal_country,
    shipping_zip_code: business_profile?.shipping_zip_code ?? business_profile?.legal_zip_code,
  });
  const [loading, setLoading] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  const [orderHistory, setOrderHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(
    () => setFeedback({
      message: flyer?.pdf_url ? '' : t('feedback.flyer_required'),
      type: flyer?.pdf_url ? '' : 'error',
    }),
    [flyer],
  );

  useEffect(() => {
    loadOrderHistory(currentPage);
  }, [currentPage]);

  const validateAndCreateOrder = () => {
    if (flyer.url_type === FLYER_URL_TYPES.ONLINE_SHOP) {
      return toggleAttentionPopup();
    }
    createOrder();
  };

  const createOrder = async (shouldConfirm = true) => {
    if (shouldConfirm) {
      return toggleOrderConfirmationPopup();
    }

    resetFeedback();
    setCreatingOrder(true);
    const { success, data: responseData } = await FlyersManager.order(data);
    setCreatingOrder(false);

    if (!success) {
      setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });

      return toast.error(t('toasts.failed_to_create_order'));
    }

    setOrderHistory([
      responseData,
      ...orderHistory,
    ]);

    toggleOrderConfirmationPopup();

    return toast.success(t('toasts.order_got_created'));
  };

  async function loadOrderHistory(page) {
    resetFeedback();
    setLoading(true);
    const { success, data: responseData } = await FlyersManager.getOrders(page);
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setCurrentPage(responseData?.current_page);
    setTotal(responseData?.total);

    return setOrderHistory(responseData.data);
  }

  const renderOrderConfirmationPopup = () => showOrderConfirmationPopup && (
    <ConfirmCancelModal
      show={showOrderConfirmationPopup}
      title={t('modals.confirmation.title')}
      primaryButtonTitle={t('modals.confirmation.buttons.create_order')}
      secondaryButtonTitle={t('modals.confirmation.buttons.cancel')}
      loading={creatingOrder}
      onConfirm={() => createOrder(false)}
      toggleModal={toggleOrderConfirmationPopup}
    />
  );

  const renderAttentionPopup = () => showAttentionPopup && (
    <ConfirmCancelModal
      show={showAttentionPopup}
      title={t('modals.attention.title', {
        url: flyer.url,
      })}
      onConfirm={() => {
        toggleAttentionPopup();
        createOrder(true);
      }}
      primaryButtonTitle={t('modals.attention.buttons.yes')}
      secondaryButtonTitle={t('modals.attention.buttons.no')}
      toggleModal={toggleAttentionPopup}
    />
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('table.fields.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('table.fields.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('table.fields.service_level'),
      dataIndex: 'service_level',
      key: 'service_level',
    },
    {
      title: t('table.fields.shipping'),
      dataIndex: 'shipping',
      key: 'shipping',
      render: (_, item) => (
        <Popover content={(
          <ul>
            <ul className="!p-inherit">
              <li>
                {t('labels.shipping_company')}
                :
                {' '}
                {item.shipping_company_name}
              </li>
              <li>
                {t('labels.shipping_salutation')}
                :
                {' '}
                {item.shipping_salutation}
              </li>
              <li>
                {t('labels.shipping_first_name')}
                :
                {' '}
                {item.shipping_first_name}
              </li>
              <li>
                {t('labels.shipping_last_name')}
                :
                {' '}
                {item.shipping_last_name}
              </li>
              <li>
                {t('labels.shipping_address')}
                :
                {' '}
                {item.shipping_address}
              </li>
              <li>
                {t('labels.shipping_zip_code')}
                :
                {' '}
                {item.shipping_zip_code}
              </li>
              <li>
                {t('labels.shipping_city')}
                :
                {' '}
                {item.shipping_city}
              </li>
              <li>
                {t('labels.shipping_phone_number')}
                :
                {' '}
                {item.shipping_phone_number}
              </li>
            </ul>
          </ul>
        )}
        >
          <a href="#">{t('labels.show_shipping_details')}</a>
        </Popover>
      ),
    },
    {
      title: t('table.fields.tracking_url'),
      dataIndex: 'tracking_url',
      key: 'tracking_url',
      render: (text) => (text ? (
        <a href={text} target="_blank" rel="noopener noreferrer">{t('labels.track_order')}</a>
      ) : t('labels.pending_from_provider')),
    },
    {
      title: t('table.fields.created'),
      dataIndex: 'created_at',
      key: 'created',
      render: (text) => HelperMethods.formatDate(text),
    },
    {
      title: t('table.fields.updated'),
      dataIndex: 'updated_at',
      key: 'updated',
      render: (text) => HelperMethods.formatDate(text),
    },
  ];

  return (
    <div className="mt-4">
      <h2 className="my-auto">{t('title')}</h2>
      <CardWrapper className="mt-3">
        <div className="d-flex justify-content-between">
          <h6 className="my-auto underline">{t('form.title')}</h6>
          <Button
            onClick={toggleForm}
            className={classnames('cursor-pointer hover:bg-gray-200 p-2 rounded-md', { 'rotate-90': !showOrderForm })}
          >
            <ArrowDownOutlined />
          </Button>
        </div>
        <CCollapse show={showOrderForm}>
          <Form layout="vertical">
            <div className="tw-my-small">
              {renderFeedbackLabel}
            </div>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item label={t('form.quantity.label')}>
                  <Select
                    value={data.quantity}
                    placeholder={t('form.quantity.placeholder')}
                    onChange={(value) => update('quantity', value)}
                    options={[25, 50, 75, 100, 150, 200, 250, 500, 1000, 2500].map((item) => (
                      { label: item, value: item }
                    ))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={t('form.service_level.label')} className="!tw-mb-none">
                  <Select
                    value={data.service_level}
                    placeholder={t('form.service_level.placeholder')}
                    onChange={(value) => update('service_level', value)}
                    options={Object.values(HELLOPRINT_SERVICE_LEVELS).map((item) => (
                      { label: t(`form.service_level.options.${item}`), value: item }
                    ))}
                    className="tw-mb-small"
                  />
                </Form.Item>
              </Col>
            </Row>
            <h5 className="tw-mb-small underline w-full">{t('form.sections.shipping_details')}</h5>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item label={t('form.shipping_company_name.label')}>
                  <Input
                    value={data.shipping_company_name}
                    placeholder={t('form.shipping_company_name.placeholder')}
                    onChange={(e) => update('shipping_company_name', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={t('form.shipping_phone_number.label')}>
                  <Input
                    value={data.shipping_phone_number}
                    placeholder={t('form.shipping_phone_number.placeholder')}
                    onChange={(e) => update('shipping_phone_number', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={t('form.shipping_salutation.label')}>
                  <Select
                    value={data.shipping_salutation}
                    placeholder={t('form.shipping_salutation.placeholder')}
                    onChange={(value) => update('shipping_salutation', value)}
                    options={[
                      t('form.shipping_salutation.options.mr'),
                      t('form.shipping_salutation.options.mrs'),
                    ].map((item) => (
                      { label: item, value: item }
                    ))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={t('form.shipping_first_name.label')}>
                  <Input
                    value={data.shipping_first_name}
                    placeholder={t('form.shipping_first_name.placeholder')}
                    onChange={(e) => update('shipping_first_name', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={t('form.shipping_last_name.label')}>
                  <Input
                    value={data.shipping_last_name}
                    placeholder={t('form.shipping_last_name.placeholder')}
                    onChange={(e) => update('shipping_last_name', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <AddressFields
              prefix="shipping"
              useGrid
              data={data}
              onChange={update}
            />
            <Flex justify="end" className="tw-mt-small">
              <Button
                type="primary"
                disabled={!flyer?.pdf_url}
                loading={creatingOrder}
                onClick={validateAndCreateOrder}
              >
                {t('buttons.order')}
              </Button>
            </Flex>
          </Form>
        </CCollapse>
      </CardWrapper>
      <div className="mt-4">
        <h2 className="mb-3">{t('table.title')}</h2>
        <Table
          columns={columns}
          className="table-responsive position-initial tw-mb-small"
          dataSource={orderHistory}
          rowKey="id"
          pagination={false}
          loading={loading}
        />
        <Pagination
          current={currentPage}
          defaultCurrent={currentPage}
          total={total}
          onChange={setCurrentPage}
        />
      </div>
      {renderAttentionPopup()}
      {renderOrderConfirmationPopup()}
    </div>
  );
}
