import Status from './status';
import BankInfoStep from './BankInfoStep';
import BusinessDocumentStep from './BusinessDocumentStep';
import BusinessInfoStep from './BusinessInfoStep';
import CompleteOnboardingExplainer from './CompleteOnboardingExplainer';
import ContractStep from './ContractStep';
import InfoSubmissionStep from './InfoSubmissionStep';
import OwnerDocumentStep from './OwnerDocumentStep';
import PaymentInfoStep from './PaymentInfoStep';
import PersonalInfoStep from './PersonalInfoStep';
import PromoCodeStep from './PromoCodeStep';
import ShippingInfoStep from './ShippingInfoStep';
import SubscriptionStep from './SubscriptionStep';
import TaxInfoStep from './TaxInfoStep';

export default {
  Status,
  BankInfoStep,
  BusinessDocumentStep,
  BusinessInfoStep,
  CompleteOnboardingExplainer,
  ContractStep,
  InfoSubmissionStep,
  OwnerDocumentStep,
  PaymentInfoStep,
  PersonalInfoStep,
  PromoCodeStep,
  ShippingInfoStep,
  SubscriptionStep,
  TaxInfoStep,
};
