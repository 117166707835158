import React from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, DatePicker, Flex, Form, Input,
} from 'antd';
import dayjs from 'dayjs';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import useForm from '../../hooks/utility/useForm';
import useVerification from '../../hooks/verification/useVerification';
import useBusinessProfile from '../../hooks/context/useBusinessProfile';
import AddressFields from '../form/AddressFields';
import PhoneNumberInput from '../form/PhoneNumberInput';

export default function PersonalInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:PersonalInfoStep' });
  const { buttonText, moveBackwards, moveForward } = props;

  const { business_profile } = useSelector((store) => store.currentCompany.company);

  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();
  const { requiredPersonalFields } = useVerification();
  const { updating, pushUpdates } = useBusinessProfile();

  const { data, update } = useForm({
    owner_phone_number: business_profile.owner_phone_number,
    owner_email: business_profile.owner_email,
    owner_first_name: business_profile.owner_first_name,
    owner_last_name: business_profile.owner_last_name,
    owner_dob: business_profile.owner_dob,
    owner_id_number: business_profile.owner_id_number,
    owner_address: business_profile.owner_address,
    owner_city: business_profile.owner_city,
    owner_state: business_profile.owner_state,
    owner_country: business_profile.owner_country,
    owner_zip_code: business_profile.owner_zip_code,
  });

  const save = () => pushUpdates({
    data,
    onSuccess: () => moveForward && moveForward(),
    onError: ({ data }) => setErrorFeedback(data),
  });

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item label={t('form.owner_phone_number.label')} required>
          <PhoneNumberInput
            defaultCountry={business_profile.legal_country}
            value={data.owner_phone_number ?? ''}
            onChange={(value) => update('owner_phone_number', value)}
            international
            className="w-full font-sm relative"
            countryCallingCodeEditable={false}
          />
        </Form.Item>
        <Form.Item label={t('form.owner_email.label')} required>
          <Input
            value={data.owner_email}
            autocomplete={null}
            onChange={(e) => update('owner_email', e.target.value)}
            placeholder={t('form.owner_email.placeholder')}
            type="email"
          />
        </Form.Item>
        <Form.Item label={t('form.owner_first_name.label')} required>
          <Input
            placeholder={t('form.owner_first_name.placeholder')}
            value={data.owner_first_name ?? ''}
            onChange={(e) => update('owner_first_name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.owner_last_name.label')} required>
          <Input
            placeholder={t('form.owner_last_name.placeholder')}
            value={data.owner_last_name ?? ''}
            onChange={(e) => update('owner_last_name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.owner_dob.label')} required>
          <DatePicker
            format={['DD.MM.YYYY', 'DD-MM-YYYY', 'DD/MM/YYYY']}
            value={data.owner_dob ? dayjs(data.owner_dob) : null}
            placeholder={t('form.owner_dob.placeholder')}
            onChange={(value) => update('owner_dob', value.utc(true).toISOString())}
          />
        </Form.Item>
        {requiredPersonalFields.contains('owner_id_number') && (
          <Form.Item label={t('form.owner_id_number.label')} required>
            <Input
              value={data.owner_id_number}
              placeholder={t('form.owner_id_number.placeholder')}
              onChange={(e) => update('owner_id_number', e.target.value)}
            />
          </Form.Item>
        )}
        <AddressFields
          prefix="owner"
          useGrid
          data={data}
          onChange={update}
        />
      </Form>
      <Flex justify="end">
        {moveBackwards && (
          <Button
            onClick={moveBackwards}
            className="tw-mr-small"
          >
            {t('buttons.back')}
          </Button>
        )}
        <Button
          type="primary"
          onClick={save}
          loading={updating}
        >
          {buttonText ?? t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
