export default {
  form: {
    address: {
      label: 'Adresse',
      placeholder: 'Straße + Nummer',
    },
    city: {
      label: 'Stadt',
      placeholder: 'Stadt eingeben',
    },
    state: {
      label: 'Bundesland',
      placeholder: 'Bundesland auswählen',
    },
    country: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    zip_code: {
      label: 'Postleitzahl des Lokals',
      placeholder: 'PLZ eingeben',
    },
  },
};
