import React from 'react';
import collect from 'collect.js';
import MetricItem from './MetricItem';
import CardWrapper from '../cards/CardWrapper';

export default function Metric({
  name,
  items,
  formatter = null,
  isStatic = false,
}) {
  const renderItems = () => {
    if (Array.isArray(items)) {
      return items.map((item, index) => (
        <MetricItem
          key={`metric-${name}-item-${item.name}`}
          data={item}
          formatter={item.formatter ?? formatter}
          inline
          isStatic={isStatic}
          isLast={collect(items).count() - 1 === index}
        />
      ));
    }

    return (
      <MetricItem
        data={items}
        formatter={formatter}
        isStatic={isStatic}
      />
    );
  };

  return (
    <CardWrapper title={name} className="tw-grid tw-grid-cols-1 tw-gap-2" style={{ minHeight: 40 }}>
      {renderItems()}
    </CardWrapper>
  );
}
