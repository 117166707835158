import { BusinessProfileUpdateStrategy } from '../../../../providers/BusinessProfileProvider';

export default {
  modals: {
    update_strategy: {
      title: 'Welche Standorte sollten aktualisiert werden?',
      form: {
        update_strategy: {
          options: {
            [BusinessProfileUpdateStrategy.All]: 'Alle meine Standorte',
            [BusinessProfileUpdateStrategy.Current]: 'Nur der aktuell ausgewählte Standort',
          },
        },
      },
      buttons: {
        confirm: 'Bestätigen',
      },
    },
  },
  toasts: {
    error: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut oder kontaktieren Sie den technischen Support',
    success: 'Die Änderungen wurden erfolgreich gespeichert!',
  },
};
