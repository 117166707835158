import AddressFields from './AddressFields';
import BusinessProfileSelect from './BusinessProfileSelect';
import CompanySelect from './CompanySelect';
import FranchiseSelect from './FranchiseSelect';
import GooglePlacesSearch from './GooglePlacesSearch';

export default {
  AddressFields,
  BusinessProfileSelect,
  CompanySelect,
  FranchiseSelect,
  GooglePlacesSearch,
};
