import React from 'react';
import { abs } from 'mathjs';
import { collect } from 'collect.js';
import classNames from 'classnames';
import CaretUpIcon from '../../resources/assets/icons/CaretUpIcon';
import CaretDownIcon from '../../resources/assets/icons/CaretDownIcon';

export default function MetricItem({
  data,
  formatter = null,
  inline = false,
  isStatic = false,
  isLast = false,
}) {
  const formatValue = (value) => (formatter ? formatter(value) : value);
  const formatChange = (value) => `${abs(value ?? 0).toFixed(2)}%`;

  const renderValue = (field) => {
    if (!data) {
      return;
    }

    if (Array.isArray(data[field])) {
      return collect(data[field]).reduce(
        (result, item) => (result !== '' ? `${result} / ${formatValue(item)}` : formatValue(item)),
        '',
      );
    }

    return formatValue(data[field]);
  };

  const renderChange = () => {
    if (!data) {
      return;
    }

    if (Array.isArray(data.change)) {
      return collect(data.change).reduce(
        (result, item) => (result !== '' ? `${result} / ${formatChange(item)}` : formatChange(item)),
        '',
      );
    }

    return formatChange(data.change);
  };

  const showChange = !isStatic
    && !!data?.change
    && !Array.isArray(data.change)
    && data?.change !== 0;

  return inline ? (
    <div>
      <div className={classNames('tw-flex tw-flex-col', {
        'border-b tw-pb-mini': !isLast,
      })}
      >
        <div className="tw-mb-tiny tw-flex tw-justify-between tw-gap-2">
          <h6 className="mini semibold">{data?.name}</h6>
          {showChange && (
            <div className="tw-flex">
              {data?.change > 0 ? <CaretUpIcon className="my-auto mr-1" /> : <CaretDownIcon className="my-auto mr-1" />}
              {renderChange()}
            </div>
          )}
        </div>
        <div className="tw-flex tw-gap-2 tw-justify-between">
          <p className="mini medium">{renderValue('value')}</p>
          {!isStatic && !!data?.previous && data?.previous !== 0 && (!Array.isArray(data?.previous) || collect(data.previous).every((item) => !!item)) && (
            <>
              <p className="mini medium">vs.</p>
              <p className="mini medium">
                {renderValue('previous')}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="tw-flex tw-gap-2 tw-justify-between tw-items-center">
        <h6 className="mb-0 medium">
          {renderValue('value')}
        </h6>
        {!isStatic && !!data?.previous && data?.previous !== 0 && (!Array.isArray(data?.previous) || collect(data.previous).every((item) => !!item)) && (
          <>
            <h6 className="medium mb-0 ">
              vs.
            </h6>
            <h6 className="medium mb-0 ">
              {renderValue('previous')}
            </h6>
          </>
        )}
      </div>
      <span>
        {showChange && (
          <>
            {data?.change > 0 ? <CaretUpIcon className="my-auto mr-1" /> : <CaretDownIcon className="my-auto mr-1" />}
            {renderChange()}
          </>
        )}
      </span>
    </>
  );
}
