import { STRIPE_VERIFICATION_STATUS } from '../../../../../services/exports/Constants';

export default {
  title: 'Going live overview',
  form: {
    name: {
      placeholder: 'Search restaurant...',
    },
  },
  filters: {
    live_from: {
      options: {
        all: 'All',
        live_only: 'Live only',
        pending_only: 'Pending only',
      },
    },
    setup_package_fulfilled: {
      options: {
        all: 'All',
        sent: 'Sent',
        not_sent: 'Not sent',
      },
    },
    flyers_ordered: {
      options: {
        all: 'All',
        ordered: 'Ordered',
        not_ordered: 'Not ordered',
      },
    },
  },
  fields: {
    name: 'Name',
    onboarding_status: 'Onboarding status',
    live_from: 'Live from',
    setup_package_fulfilled_at: 'Setup package',
    flyers_delivered_at: 'Flyers delivery status',
    has_storefront: 'Storefront',
  },
  labels: {
    stripe_status: 'Stripe status: {{status}}',
    subscription_status: 'Subscription status: {{status}}',
    contract_status: 'Contract status: {{status}}',
    flyer_order_status: 'Flyer order status: {{status}}',
    flyer_order_last_updated: 'Last updated: {{date}}',
    subscribed: 'Subscribed',
    not_subscribed: 'Not subscribed',
    signed: 'Signed on {{date}}',
    not_signed: 'Not signed',
    no_flyer_order: 'Not ordered',
    domain_required: 'Domain has to be purchased',
    note: 'Note',
  },
  stripe_statuses: {
    [STRIPE_VERIFICATION_STATUS.REJECTED]: 'Rejected',
    [STRIPE_VERIFICATION_STATUS.APPROVED]: 'Verified',
    [STRIPE_VERIFICATION_STATUS.PENDING]: 'Pending',
    [null]: 'None',
  },
  buttons: {
    mark_as_granted: 'Mark as granted',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_save_changes: 'Failed to save changed',
    changes_got_saved: 'Changes got saved',
  },
};
