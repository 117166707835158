import {
  Alert,
  Button,
  Flex, Form, Input, Modal, Popover, Select, Switch, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { collect } from 'collect.js';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/utility/useForm';
import {
  ORDER_METHODS,
  SMART_PRICING_MODIFIER_TYPE,
  SMART_PRICING_RESOURCE_TYPE,
} from '../../services/exports/Constants';
import HelperMethods from '../../services/exports/HelperMethods';
import PercentageInput from '../../components/form/PercentageInput';
import CurrencyInput from '../../components/form/CurrencyInput';
import useApiClient from '../../hooks/api/useApiClient';
import useHelpers from '../../hooks/context/useHelpers';

export default function SmartPricingRuleModal({
  open,
  resource,
  data,
  onSuccess,
  onCancel,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:SmartPricing:SmartPricingRuleModal' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const {
    SmartPricingRulesManager,
    MenusManager,
    CategoriesManager,
    ModifierGroupsManager,
  } = useApiClient();

  const { formatCurrency } = useHelpers();

  const [resources, setResources] = useState([]);
  const [loadingResources, setLoadingResources] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    loadResources();
  }, []);

  const { data: formData, update } = useForm({
    resource,
    order_methods: data?.order_methods ?? [],
    modifier_type: data?.modifier_type ?? SMART_PRICING_MODIFIER_TYPE.percentage,
    modifier_value: data?.modifier_value ?? -10,
    round: data ? data.round : true,
    name: data?.name ?? t('form.name.default', {
      value: HelperMethods.formatPercentage(10),
      resource: resource.name ?? '',
      lng: serviceProvider?.regional_config?.locales[0] ?? LocalizationManager.getLanguage(),
    }),
    is_visible: data?.is_visible ?? false,
  });

  useEffect(() => {
    update(
      'round',
      data ? data.round : formData.modifier_type === SMART_PRICING_MODIFIER_TYPE.percentage,
    );
  }, [data?.round, formData.modifier_type]);

  const selectedResource = useMemo(
    () => {
      if (resources.length === 0 || !formData.resource.id) {
        return null;
      }

      const groupOptionsField = ({
        [SMART_PRICING_RESOURCE_TYPE.category]: 'categories',
        [SMART_PRICING_RESOURCE_TYPE.product]: 'products',
        [SMART_PRICING_RESOURCE_TYPE.modifier]: 'modifiers',
      })[resource.type] ?? null;

      return groupOptionsField
        ? collect(
          collect(resources).first(
            (item) => collect(item[groupOptionsField]).firstWhere('id', formData.resource.id),
          )[groupOptionsField],
        ).firstWhere('id', formData.resource.id)
        : collect(resources).firstWhere('id', formData.resource.id);
    },
    [formData.resource.id, resources],
  );

  const resourceOptions = useMemo(
    () => {
      const defaultMapper = (item) => ({ label: item.name, value: item.id });

      const groupOptionsField = ({
        [SMART_PRICING_RESOURCE_TYPE.category]: 'categories',
        [SMART_PRICING_RESOURCE_TYPE.product]: 'products',
        [SMART_PRICING_RESOURCE_TYPE.modifier]: 'modifiers',
      })[resource.type];

      const optionGroupMapper = (item) => ({
        key: item.id,
        label: <span>{item.name}</span>,
        title: item.name,
        options: item[groupOptionsField].map((child) => ({
          key: `${item.id}-${child.id}`,
          label: child.name,
          value: child.id,
        })),
      });

      const mapper = [
        SMART_PRICING_RESOURCE_TYPE.category,
        SMART_PRICING_RESOURCE_TYPE.product,
        SMART_PRICING_RESOURCE_TYPE.modifier,
      ].includes(resource.type) ? optionGroupMapper : defaultMapper;

      return resources.map((item) => mapper(item));
    },
    [resources],
  );

  async function loadResources() {
    setLoadingResources(true);
    const { success, data: responseData } = await ({
      [SMART_PRICING_RESOURCE_TYPE.company]: () => ({ success: true, data: [] }),
      [SMART_PRICING_RESOURCE_TYPE.menu]: () => MenusManager.get({
        fields: [
          'menus.id',
          'menus.name',
        ],
      }),
      [SMART_PRICING_RESOURCE_TYPE.category]: () => MenusManager.get({
        include: ['categories'],
        filter: {
          'categories.default': true,
        },
        fields: [
          'menus.id',
          'menus.name',
          'categories.id',
          'categories.name',
        ],
      }),
      [SMART_PRICING_RESOURCE_TYPE.product]: () => CategoriesManager.get({
        include: ['products'],
        filter: {
          default: true,
        },
        fields: [
          'categories.id',
          'categories.name',
          'products.id',
          'products.name',
          'products.price',
        ],
      }),
      [SMART_PRICING_RESOURCE_TYPE.modifier_group]: () => ModifierGroupsManager.get({
        fields: [
          'modifier_groups.id',
          'modifier_groups.name',
        ],
      }),
      [SMART_PRICING_RESOURCE_TYPE.modifier]: () => ModifierGroupsManager.get({
        include: ['modifiers'],
        fields: [
          'modifier_groups.id',
          'modifier_groups.name',
          'modifiers.id',
          'modifiers.name',
          'modifiers.price',
        ],
      }),
    })[resource.type]();
    setLoadingResources(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    return setResources(responseData);
  }

  async function saveRule() {
    setSaving(true);
    const { success, data: responseData } = data?.id
      ? await SmartPricingRulesManager.update(data?.id, formData)
      : await SmartPricingRulesManager.create({
        ...formData,
        resource_type: formData.resource.type,
        resource_id: formData.resource.id,
      });
    setSaving(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_save_changes'));
    }

    toast.success(t('toasts.changes_got_saved'));

    return onSuccess(responseData);
  }

  const onResourceSelect = (value) => update('resource', {
    ...formData.resource,
    id: value,
  });

  return (
    <Modal
      open={open}
      centered
      footer={(
        <Button
          type="primary"
          onClick={saveRule}
          loading={saving}
        >
          {t('buttons.save')}
        </Button>
      )}
      title={t(`title.${resource.type}`, { name: resource.name ?? t('labels.new') })}
      onCancel={onCancel}
    >
      {(selectedResource && selectedResource.price !== undefined) ? (
        <Alert
          message={t('labels.base_price', {
            name: selectedResource.name,
            value: formatCurrency(selectedResource.price),
          })}
          className="tw-my-small"
          type="info"
        />
      ) : null}
      <Form layout="vertical" initialValues={formData}>
        {resource.type !== SMART_PRICING_RESOURCE_TYPE.company && (
          <Form.Item label={t(`form.resource.${resource.type}`)} required>
            <Select
              showSearch
              optionFilterProp="label"
              value={formData.resource.id}
              loading={loadingResources}
              options={resourceOptions}
              onChange={onResourceSelect}
              placeholder={t('form.resource.placeholder', { resource: t(`form.resource.${resource.type}`) })}
            />
          </Form.Item>
        )}
        <Form.Item label={t('form.order_methods.label')} required>
          <Select
            mode="multiple"
            value={formData.order_methods}
            options={Object.values(ORDER_METHODS).map((item) => ({
              value: item,
              label: t(`form.order_methods.options.${item}`),
            }))}
            onChange={(value) => update('order_methods', value)}
            placeholder={t('form.order_methods.placeholder')}
          />
        </Form.Item>
        <Form.Item
          required
          label={(
            <>
              {t('form.modifier_type.label')}
              <Popover
                title={t('form.modifier_type.tooltip.title')}
                content={(
                  <ul>
                    {Object.values(SMART_PRICING_MODIFIER_TYPE).map((item) => (
                      <li key={item}>{t(`form.modifier_type.tooltip.description.${item}`)}</li>
                    ))}
                  </ul>
                )}
              >
                <InfoCircleOutlined className="tw-text-md tw-ml-mini" />
              </Popover>
            </>
          )}
        >
          <Select
            value={formData.modifier_type}
            options={Object.values(SMART_PRICING_MODIFIER_TYPE).map((item) => ({
              value: item,
              label: t(`form.modifier_type.options.${item}`),
            }))}
            onChange={(value) => update('modifier_type', value)}
            placeholder={t('form.modifier_type.placeholder')}
          />
        </Form.Item>
        <Form.Item label={t('form.modifier_value.label')} required>
          {formData.modifier_type === SMART_PRICING_MODIFIER_TYPE.percentage ? (
            <PercentageInput
              value={formData.modifier_value}
              onChange={(value) => update('modifier_value', value)}
              allowNegative
            />
          ) : (
            <CurrencyInput
              value={formData.modifier_value}
              onChange={(value) => update('modifier_value', value)}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Flex>
            <Switch
              checked={formData.round}
              onChange={(checked) => update('round', checked)}
              id="round"
            />
            <label htmlFor="round" className="tw-ml-small tw-my-auto">
              {t('form.round.label')}
            </label>
            <Tooltip title={t('form.round.tooltip')} className="tw-ml-mini tw-my-auto">
              <InfoCircleOutlined />
            </Tooltip>
          </Flex>
        </Form.Item>
        <Form.Item>
          <Flex>
            <Switch
              checked={formData.is_visible}
              onChange={(checked) => update('is_visible', checked)}
              id="is_visible"
            />
            <label htmlFor="is_visible" className="tw-ml-small tw-my-auto">{t('form.is_visible.label')}</label>
          </Flex>
        </Form.Item>
        {formData.is_visible && (
          <Form.Item label={t('form.name.label')} required>
            <Input
              value={formData.name}
              onChange={(e) => update('name', e.target.value)}
              placeholder={t('form.name.placeholder')}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
