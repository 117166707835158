import React, { createContext, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { COOKIES } from '../services/exports/Constants';
import useAppCookies from '../hooks/utility/useAppCookies';
import WebViewCommunicator from '../services/helpers/WebViewCommunicator';

export const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const { getCookie, setCookie, removeCookies } = useAppCookies([COOKIES.AUTH_TOKEN]);

  const authToken = getCookie(COOKIES.AUTH_TOKEN);
  const onBehalfOf = getCookie(COOKIES.ON_BEHALF_OF_STORE);

  const isLoggedIn = !!getCookie(COOKIES.AUTH_TOKEN);

  useEffect(() => {
    extendCookiesExpiration();
  }, []);

  function extendCookiesExpiration() {
    authToken && setCookie(COOKIES.AUTH_TOKEN, authToken, {
      expires: moment()
        .add(399, 'days')
        .toDate(),
    });

    onBehalfOf && setCookie(COOKIES.ON_BEHALF_OF_STORE, onBehalfOf, {
      expires: moment()
        .add(399, 'days')
        .toDate(),
    });
  }

  const context = useMemo(
    () => ({
      isLoggedIn,
      logIn: async (data) => {
        try {
          setCookie(COOKIES.AUTH_TOKEN, data.data.access_token, {
            expires: moment()
              .add(399, 'days')
              .toDate(),
          });
          WebViewCommunicator.forwardAuthToken(data.data.access_token);
        } catch (error) {
          console.error('Failed to log the user in: ', error);
        }
      },
      logOut: () => {
        try {
          removeCookies([
            COOKIES.AUTH_TOKEN,
            COOKIES.ON_BEHALF_OF_STORE,
          ]);
          WebViewCommunicator.forwardAuthToken(null);
        } catch (error) {
          console.error('Failed to log the user out: ', error);
        }
      },
    }),
    [isLoggedIn],
  );

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
}
