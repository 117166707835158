import ApiResourceManager from '../ApiResourceManager';
import Query from '../../query-builder';
import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';

export default class BusinessProfilesManager extends ApiResourceManager {
  get = (data, sort = '-business_profiles.id') => this.request({
    method: 'GET',
    url: new Query()
      .for('business-profiles')
      .where('business_profiles.display_name', data.display_name ?? null)
      .whereIn('except', data.except ?? [])
      .when(
        data.id,
        (builder) => builder.where('id', data.id),
      )
      .page(data.page ?? 1)
      .sort(sort)
      .get(),
  });

  update = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/business-profile',
      data,
    });

    const { data: responseData, success } = response;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setBusinessProfile(responseData));
    }

    return response;
  };

  uploadDocument = async (data) => {
    const request = await this.request({
      method: 'POST',
      url: '/business-profile/documents',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setBusinessProfile(responseData));
    }

    return request;
  };
}
