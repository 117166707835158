import React from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, Col, Flex, Form, Input, Row, Select,
} from 'antd';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import useForm from '../../hooks/utility/useForm';
import useBusinessProfile from '../../hooks/context/useBusinessProfile';
import AddressFields from '../form/AddressFields';

export default function ShippingInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:ShippingInfoStep' });
  const { moveBackwards, moveForward, buttonText } = props;

  const { business_profile } = useSelector((store) => store.currentCompany.company);

  const { updating, pushUpdates } = useBusinessProfile();
  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const { data, update } = useForm({
    shipping_company_name: business_profile.shipping_company_name ?? business_profile.display_name,
    shipping_phone_number: business_profile.shipping_phone_number ?? business_profile.owner_phone_number,
    shipping_salutation: business_profile.shipping_salutation ?? t('form.shipping_salutation.options.mr'),
    shipping_first_name: business_profile.shipping_first_name ?? business_profile.owner_first_name,
    shipping_last_name: business_profile.shipping_last_name ?? business_profile.owner_last_name,
    shipping_address: business_profile.shipping_address ?? business_profile.legal_address,
    shipping_city: business_profile.shipping_city ?? business_profile.legal_city,
    shipping_state: business_profile.shipping_state ?? business_profile.legal_state,
    shipping_country: business_profile.shipping_country ?? business_profile.legal_country,
    shipping_zip_code: business_profile.shipping_zip_code ?? business_profile.legal_zip_code,
  });

  const save = () => pushUpdates({
    data,
    onSuccess: () => moveForward && moveForward(),
    onError: ({ data }) => setErrorFeedback(data),
  });

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item label={t('form.shipping_company_name.label')} required>
          <Input
            placeholder={t('form.shipping_company_name.placeholder')}
            value={data.shipping_company_name ?? ''}
            onChange={(e) => update('shipping_company_name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.shipping_phone_number.label')} required>
          <Input
            placeholder={t('form.shipping_phone_number.placeholder')}
            value={data.shipping_phone_number ?? ''}
            onChange={(e) => update('shipping_phone_number', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.shipping_salutation.label')} required>
          <Select
            placeholder={t('form.shipping_salutation.placeholder')}
            value={data.shipping_salutation ?? ''}
            onChange={(value) => update('shipping_salutation', value)}
            options={[
              { label: t('form.shipping_salutation.options.mr'), value: t('form.shipping_salutation.options.mr') },
              { label: t('form.shipping_salutation.options.mrs'), value: t('form.shipping_salutation.options.mrs') },
            ]}
          />
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label={t('form.shipping_first_name.label')} required>
              <Input
                placeholder={t('form.shipping_first_name.placeholder')}
                value={data.shipping_first_name ?? ''}
                onChange={(e) => update('shipping_first_name', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('form.shipping_last_name.label')} required>
              <Input
                placeholder={t('form.shipping_last_name.placeholder')}
                value={data.shipping_last_name ?? ''}
                onChange={(e) => update('shipping_last_name', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <AddressFields
          prefix="shipping"
          useGrid
          data={data}
          onChange={update}
        />
      </Form>
      <Flex justify="end">
        {moveBackwards && (
          <Button
            onClick={moveBackwards}
            className="tw-mr-small"
          >
            {t('buttons.back')}
          </Button>
        )}
        <Button
          type="primary"
          onClick={save}
          loading={updating}
        >
          {buttonText ?? t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
