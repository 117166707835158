export default {
  form: {
    shipping_company_name: {
      label: 'Firma',
      placeholder: 'Firmenname eingeben',
    },
    shipping_phone_number: {
      label: 'Telefonnummer',
      placeholder: 'Telefonnummer eingeben',
    },
    shipping_salutation: {
      label: 'Anrede',
      options: {
        mr: 'Herr',
        mrs: 'Frau',
      },
    },
    shipping_first_name: {
      label: 'Vorname',
      placeholder: 'Vornamen eingeben',
    },
    shipping_last_name: {
      label: 'Nachname',
      placeholder: 'Nachname eingeben',
    },
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
  },
};
