import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Checkbox } from 'antd';
import useHelpers from '../../hooks/context/useHelpers';

export default function Agreement({
  subscription,
  trialDays,
  agreementAccepted,
  setAgreementAccepted,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Billing:Agreement' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((state) => state.currentCompany);

  const { formatCurrency, formatDateTime } = useHelpers();

  const isLive = company.live_from !== null;

  const renderAgreement = () => (
    <div className="tw-mt-small">
      <p>{t('sign_agreement')}</p>
      <h6 className="tw-mt-small">{t('restaurant')}</h6>
      <div className="tw-mt-small">
        <Checkbox
          checked={agreementAccepted}
          onChange={(e) => setAgreementAccepted(e.target.checked)}
        >
          <Trans
            t={t}
            values={{
              amount: formatCurrency(subscription.taxed_amount),
              terms_url: serviceProvider.participation_agreement_url,
              provider: serviceProvider.name,
            }}
            components={{ a: <a /> }}
          >
            agreement
          </Trans>
        </Checkbox>
      </div>
    </div>
  );

  return (
    <div className={className}>
      <h5>{t('title')}</h5>
      <p className="tw-mt-small">{t('description', { amount: formatCurrency(subscription.taxed_amount) })}</p>
      {trialDays === 0 && !isLive && (
        <p>{t('bill_at_launch_date')}</p>
      )}
      {trialDays > 0 && (
        <p className="tw-mt-small">
          {isLive
            ? t('bill_date', { date: formatDateTime(moment().add(trialDays, 'days'), { timeFormat: '' }) })
            : t('bill_date_after_launch', { days: trialDays })}
        </p>
      )}
      {renderAgreement()}
    </div>
  );
}
