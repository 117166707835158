import React, { useState } from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import {
  Button, Flex, Form, Select, Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useFeedbackLabel from '../../hooks/ui/useFeedbackLabel';
import { Utils } from '../../services/helpers/Utils';
import { StripeDocumentType } from '../../services/exports/Constants';
import useApiClient from '../../hooks/api/useApiClient';
import useForm from '../../hooks/utility/useForm';

export default function BusinessDocumentStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BusinessDocumentStep' });
  const { moveBackwards, moveForward } = props;

  const { BusinessProfilesManager } = useApiClient();

  const { data, update } = useForm({
    document_type: null,
    front: null,
    back: null,
  });

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { responseData, success } = await BusinessProfilesManager.uploadDocument(
      Utils.createFormData({
        type: StripeDocumentType.BusinessDocument,
        front: data.front,
        back: data.back ?? null,
      }),
    );
    setLoading(false);

    if (!success) {
      return setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });
    }

    return moveForward();
  }

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item label={t('form.document_type.label')} required>
          <Select
            placeholder={t('form.document_type.placeholder')}
            value={data.document_type}
            onChange={(value) => update('document_type', value)}
            options={[
              { label: t('form.document_type.options.vat_registration'), value: t('form.document_type.options.vat_registration') },
              { label: t('form.document_type.options.trade_registration'), value: t('form.document_type.options.trade_registration') },
              { label: t('form.document_type.options.trade_license'), value: t('form.document_type.options.trade_license') },
              { label: t('form.document_type.options.commercial_register_extract'), value: t('form.document_type.options.commercial_register_extract') },
              { label: t('form.document_type.options.financial_statements'), value: t('form.document_type.options.financial_statements') },
              { label: t('form.document_type.options.other'), value: t('form.document_type.options.other') },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('form.front.label')} required>
          <Upload
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/png,image/jpeg,application/pdf"
            beforeUpload={(file) => { update('front', file); return false; }}
            onRemove={() => update('front', null)}
          >
            <Button icon={<UploadOutlined />}>{t('buttons.select_file')}</Button>
          </Upload>
        </Form.Item>
        <Form.Item label={t('form.back.label')}>
          <Upload
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/png,image/jpeg,application/pdf"
            beforeUpload={(file) => { update('back', file); return false; }}
            onRemove={() => update('back', null)}
          >
            <Button icon={<UploadOutlined />}>{t('buttons.select_file')}</Button>
          </Upload>
        </Form.Item>
      </Form>
      <Flex justify="end">
        <Button
          onClick={moveBackwards}
          className="tw-mr-small"
        >
          {t('buttons.back')}
        </Button>
        <Button
          type="primary"
          onClick={save}
          loading={loading}
        >
          {t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
