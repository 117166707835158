import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { Table } from 'antd';
import Metric from '../stats/Metric';
import HelperMethods from '../../services/exports/HelperMethods';
import LoadingScreen from '../loading/LoadingScreen';
import useApiClient from '../../hooks/api/useApiClient';
import usePermissions from '../../hooks/auth/usePermissions';
import { ROLES } from '../../services/exports/Constants';
import useHelpers from '../../hooks/context/useHelpers';
import MetricsContainer from '../stats/MetricsContainer';

const requiredMetrics = [
  'gmv',
  'revenue',
  'revenue_subscription',
  'revenue_service_fee',
  'revenue_service_charges',
  'processing_fees',
  'processing_fees_stripe',
  'processing_fees_paypal',
  'rejected_orders_amount',
  'rejected_orders_count',
  'rejected_orders_rate',
  'auto_rejected_orders_rate',
  'manually_rejected_orders_rate',
  'avg_order_value',
  'tips',
  'orders_count',
  'pickup_orders_rate',
  'delivery_orders_rate',
  'other_orders_rate',
  'customers',
  'guest_customers',
  'signed_up_customers',
  'new_customers',
  'returning_customers',
  'google_reviews',
  'loyalty_sign_ups',
  'companies',
];

const requiredReservationMetrics = [
  'total_reservations',
  'website_reservations',
  'google_reservations',
];

export default function GeneralTab({ dateRangeFilter }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Dashboard:Dashboard' });

  const { StatsManager } = useApiClient();
  const { ReservationsManager } = useApiClient({
    baseUrl: import.meta.env.VITE_RESERVATION_API_BASE_URL,
    autoLogOut: false,
  });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { hasAnyRole, isSalesManager } = usePermissions();
  const { formatCurrency } = useHelpers();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    requestStatistics();
  }, [serviceProvider?.id, dateRangeFilter]);

  async function requestStatistics() {
    setLoading(true);

    const { data } = await StatsManager.getStats({
      metrics: collect(requiredMetrics).when(
        isSalesManager && !hasAnyRole([ROLES.admin, ROLES.reseller]),
        (collection) => collection.push('sales_breakdown'),
      ).toArray(),
      ...dateRangeFilter,
    });

    const { data: reservationsData } = await ReservationsManager.getStats({
      metrics: requiredReservationMetrics,
      ...dateRangeFilter,
    });

    setLoading(false);

    setData(collect(data).mergeRecursive(reservationsData).all());
  }

  const moneyFormatter = (value) => formatCurrency(value);
  const percentageFormatter = (value) => HelperMethods.formatPercentage(value);

  const metrics = data ? [
    <Metric
      key={t('metrics.gmv')}
      name={t('metrics.gmv')}
      items={data.gmv}
      formatter={moneyFormatter}
    />,
    <Metric
      key={t('metrics.revenue')}
      name={t('metrics.revenue')}
      items={[
        {
          name: t('metrics.revenue'),
          ...data.revenue,
        },
        {
          name: t('metrics.revenue_subscription'),
          ...data.revenue_subscription,
        },
        {
          name: t('metrics.revenue_service_fee'),
          ...data.revenue_service_fee,
        },
        {
          name: t('metrics.revenue_service_charges'),
          ...data.revenue_service_charges,
        },
      ]}
      formatter={moneyFormatter}
    />,
    <Metric
      key={t('metrics.processing_fees')}
      name={t('metrics.processing_fees')}
      items={[
        {
          name: t('metrics.processing_fees'),
          ...data.processing_fees,
        },
        {
          name: t('metrics.processing_fees_stripe'),
          ...data.processing_fees_stripe,
        },
        {
          name: t('metrics.processing_fees_paypal'),
          ...data.processing_fees_paypal,
        },
      ]}
      formatter={moneyFormatter}
    />,
    <Metric
      key={t('metrics.rejected_orders')}
      name={t('metrics.rejected_orders')}
      items={[
        {
          name: t('metrics.rejected_orders_amount'),
          formatter: moneyFormatter,
          ...data.rejected_orders_amount,
        },
        {
          name: t('metrics.rejected_orders_count'),
          ...data.rejected_orders_count,
        },
        {
          name: t('metrics.rejected_orders_rate'),
          formatter: percentageFormatter,
          ...data.rejected_orders_rate,
        },
        {
          name: t('metrics.rejected_orders_rate_breakdown'),
          formatter: percentageFormatter,
          value: [
            data.auto_rejected_orders_rate?.value,
            data.manually_rejected_orders_rate?.value,
          ],
          previous: [
            data.auto_rejected_orders_rate?.previous,
            data.manually_rejected_orders_rate?.previous,
          ],
          change: [
            data.auto_rejected_orders_rate?.change,
            data.manually_rejected_orders_rate?.change,
          ],
        },
      ]}
    />,
    <Metric
      key={t('metrics.average_order_value')}
      name={t('metrics.average_order_value')}
      items={data.avg_order_value}
      formatter={moneyFormatter}
    />,
    <Metric
      key={t('metrics.tips')}
      name={t('metrics.tips')}
      items={data.tips}
      formatter={moneyFormatter}
    />,
    <Metric
      key={t('metrics.orders')}
      name={t('metrics.orders')}
      items={[
        {
          name: t('metrics.total_orders'),
          ...data.orders_count,
        },
        {
          name: t('metrics.pickup_orders_rate'),
          formatter: percentageFormatter,
          ...data.pickup_orders_rate,
        },
        {
          name: t('metrics.delivery_orders_rate'),
          formatter: percentageFormatter,
          ...data.delivery_orders_rate,
        },
        {
          name: t('metrics.other_orders_rate'),
          formatter: percentageFormatter,
          ...data.other_orders_rate,
        },
      ]}
    />,
    <Metric
      key={t('metrics.customers')}
      name={t('metrics.customers')}
      items={[
        {
          name: t('metrics.total_customers'),
          ...data.customers,
        },
        {
          name: t('metrics.customers_auth_state'),
          value: [
            data.guest_customers?.value,
            data.signed_up_customers?.value,
          ],
          previous: [
            data.guest_customers?.previous,
            data.signed_up_customers?.previous,
          ],
          change: [
            data.guest_customers?.change,
            data.signed_up_customers?.change,
          ],
        },
        {
          name: t('metrics.new_customers'),
          ...data.new_customers,
        },
        {
          name: t('metrics.returning_customers'),
          ...data.returning_customers,
        },
      ]}
    />,
    <Metric
      key={t('metrics.reservations')}
      name={t('metrics.reservations')}
      items={[
        {
          name: t('metrics.total_reservations'),
          ...data.total_reservations,
        },
        {
          name: t('metrics.website_reservations'),
          ...data.website_reservations,
        },
        {
          name: t('metrics.google_reservations'),
          ...data.google_reservations,
        },
      ]}
    />,
    <Metric
      key={t('metrics.google_reviews')}
      name={t('metrics.google_reviews')}
      items={data.google_reviews}
    />,
    <Metric
      key={t('metrics.loyalty_sign_ups')}
      name={t('metrics.loyalty_sign_ups')}
      items={data.loyalty_sign_ups}
    />,
    <Metric
      key={t('metrics.companies')}
      name={t('metrics.companies')}
      items={data.companies}
    />,
  ] : [];

  return (
    <>
      {hasAnyRole([ROLES.admin, ROLES.reseller]) && (
        <>
          {loading ? <LoadingScreen /> : (
            <MetricsContainer metrics={metrics} />
          )}
        </>
      )}
      {data.sales_breakdown && (
        <Table
          loading={loading}
          className="tw-mt-small"
          dataSource={data.sales_breakdown}
          columns={[
            { title: t('metrics.sales_breakdown.name'), dataIndex: 'name' },
            { title: t('metrics.sales_breakdown.sales'), render: (item) => formatCurrency(item.sales) },
          ]}
        />
      )}
    </>
  );
}
