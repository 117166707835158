import React, {
  useState, useEffect, useMemo, createContext,
} from 'react';
import Theme from '../styles/Theme';
import { SCREEN_TYPES } from '../services/exports/Constants';
import useWindowSize from '../hooks/utility/useWindowSize';

export const ScreenTypeContext = createContext({
  isDesktop: true,
  isMobile: false,
  isTablet: false,
});

function ScreenTypeProvider(props) {
  const { children } = props;

  const windowSize = useWindowSize();
  const width = windowSize.width ?? 0;

  const [screenType, setScreenType] = useState(SCREEN_TYPES.DESKTOP);

  const handleResize = () => {
    if (width >= Theme.dimension.breakPoints.lg) {
      return setScreenType(SCREEN_TYPES.DESKTOP);
    }

    if (width >= Theme.dimension.breakPoints.md) {
      return setScreenType(SCREEN_TYPES.TABLET);
    }

    return setScreenType(SCREEN_TYPES.MOBILE);
  };

  useEffect(() => {
    handleResize();
  }, [width]);

  const context = useMemo(
    () => ({
      isDesktop: screenType === SCREEN_TYPES.DESKTOP,
      isTablet: screenType === SCREEN_TYPES.TABLET,
      isMobile: screenType === SCREEN_TYPES.MOBILE,
    }),
    [screenType, windowSize],
  );

  return (
    <ScreenTypeContext.Provider value={context}>
      {children}
    </ScreenTypeContext.Provider>
  );
}

export default ScreenTypeProvider;
