export default {
  title: 'Google business',
  sections: {
    google_business_info: 'Google Business Info',
    google_reviews: 'Google reviews',
    settings: 'Settings',
  },
  form: {
    reviews_autopilot_enabled: {
      label: 'AI auto replies',
    },
    reviews_autopilot_daily_limit: {
      label: 'AI auto replies daily limit (min 1, max 15)',
    },
    reviews_autopilot_delay_hours: {
      label: 'Amount of hours should pass after write review',
    },
    unanswered_checkbox: {
      show_all_reviews: 'Show all reviews',
      show_only_unanswered: 'Show only unanswered',
    },
    rating_filter: {
      label: 'Rating filter',
      stars_one: '{{count}} star',
      stars_other: '{{count}} stars',
    },
    sorting: {
      newest_to_oldest: 'Sort by newest to oldest',
      oldest_to_newest: 'Sort by oldest to newest',
      rating_highest_to_lowest: 'Sort by highest to lowest rating',
      rating_lowest_to_highest: 'Sort by lowest to highest rating',
    },
  },
  table: {
    pagination: {
      total_one: 'Total {{count}} review',
      total_other: 'Total {{count}} reviews',
    },
  },
  modal_publish: {
    title: 'Are you sure you want to publish to Google?',
    buttons: {
      yes: 'Yes',
      cancel: 'Cancel',
    },
  },
  popconfirm: {
    title: 'Are you sure?',
    description: 'All local changes will be overwritten',
  },
  buttons: {
    login_with_google: 'Login with Google',
    sync_from_google: 'Get Fresh Version',
    publish_to_google: 'Publish to Google',
    previous: 'Previous',
    next: 'Next',
    save_settings: 'Save Settings',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    failed_to_fetch_reviews: 'Failed to fetch reviews',
    failed_to_fetch_settings: 'Failed to fetch settings',
    failed_to_load_google_business: 'Failed to load google business',
    failed_to_publish_to_google_business: 'Failed to publish to google business',
    failed_to_fetch_info: 'Failed to get google business fresh version',
    success_publish_to_google_business: 'Successfully published to Google Business',
    success_sync_from_google: 'Successfully synced from Google Business',
    success_save_settings: 'Successfully saved settings',
  },
};
